<template>
  <div class="auth_forms_wrapper">
    <transition name="fade" mode="out-in">
      <router-view :key="$router.path" />
    </transition>
  </div>
</template>

<script>
export default {
  name: "Authentication",
};
</script>
