import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";

// START:: IMPORTING VUE ROUTER & VUEX
import router from "./router";
import store from "./store";
// END:: IMPORTING VUE ROUTER & VUEX

// START:: IMPORTING I18N
import i18n from "./i18n";
// END:: IMPORTING I18N

// START:: IMPORTING VUE META
import VueMeta from "vue-meta";
// END:: IMPORTING VUE META

// START:: IMPORTING AXIOS
import axios from "axios";
// ======== START:: AXIOS DEFAULTS ======== //
// axios.defaults.baseURL = "http://localhost/lime/public/api/provider/";
axios.defaults.baseURL = "https://develobug.com/lime/public/api/provider/";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("lime_token");
axios.defaults.headers.common["lang"] =
  localStorage.getItem("landing_language");
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

// ======== END:: AXIOS DEFAULTS ======== //
// END:: IMPORTING AXIOS

// ======== language
// store.dispatch("lang_module/handleLang"); // ==> Store (Vuex) <==

// START:: IMPORTING VUE-SELECT
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
// END:: IMPORTING VUE-SELECT

// START:: IMPORTING VUE GOOGLE MAPS
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDRymdCLWxCwLHFnwv36iieKAMjiwk8sdc",
    libraries: "places",
  },
});
// END:: IMPORTING VUE GOOGLE MAPS

// START:: IMPORTING VUE SPLIDE SLIDER
import VueSplide from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
// END:: IMPORTING VUE SPLIDE SLIDER

// START:: IMPORTING VUE SLICK SLIDER
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
// START:: IMPORTING VUE SLICK SLIDER

// START:: IMPORTING MAIN SASS STYLES FILE
import "./assets/sass/main.scss";
// END:: IMPORTING MAIN SASS STYLES FILE

// START:: IMPORTING BOOTSTRAP
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
// END:: IMPORTING BOOTSTRAP

// START:: IMPORTING BOOTSTRAP VUE
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
// END:: IMPORTING BOOTSTRAP VUE

// START:: IMPORTING VUETIFY
import vuetify from "./plugins/vuetify";
// START:: IMPORTING VUETIFY

// START:: IMPORTING ANT DESIGN
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
// END:: IMPORTING ANT DESIGN

// START:: IMPORTING FONT AWESOME
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
// END:: IMPORTING FONT AWESOME

// START:: IMPORTING IZI TOAST
import iziToast from "izitoast";
import "./assets/css/iziToast.min.css";
// END:: IMPORTING IZI TOAST

// START:: IMPORTING AOS
import AOS from "aos";
import "aos/dist/aos.css";
// END:: IMPORTING AOS

// START:: IMPORTING VUE-PARTICLES
import VueParticles from "vue-particles";
// END:: IMPORTING VUE-PARTICLES

// START:: IMPORTING VUE-ANIMATE
import VAnimateCss from "v-animate-css";
// END:: IMPORTING VUE-ANIMATE

// START:: IMPORTING VUE APEX CHARTS
import VueApexCharts from "vue-apexcharts";
Vue.component("apexchart", VueApexCharts);
// END:: IMPORTING VUE APEX CHARTS

// ============ Import & Register Global Components
import CustomAccordion from "./components/Global/CustomAccordion.vue";
Vue.component("custom-accordion", CustomAccordion);

import SideMenu from "./components/UI/SideMenu.vue";
Vue.component("side-menu", SideMenu);

import SectionSlider from "@/components/Sliders/SectionSlider.vue";
Vue.component("section-slider", SectionSlider);

import Raters from "vue-rate-it";
Vue.component("fa-rating", Raters.FaRating);
Vue.component("image-rating", Raters.ImageRating);

import VueChatScroll from "vue-chat-scroll";
Vue.use(VueChatScroll);

AOS.init();
Vue.use(VueMeta);
Vue.use(Antd);
Vue.component("multiselect", Multiselect);
Vue.use(VueSplide);
Vue.component("vue-slick", VueSlickCarousel);
Vue.use(IconsPlugin);
Vue.use(BootstrapVue);
Vue.use(iziToast);
Vue.use(VueParticles);
Vue.use(VAnimateCss);
Vue.use(VueApexCharts);
Vue.prototype.$iziToast = iziToast;
Vue.prototype.$vuetify = vuetify;

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
