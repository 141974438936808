<template>
    <div class="allCategories">
        <div class="row">
            <div class="header_wraper">
                <h2>Categories</h2>
                <span class="title_backdrop"> Categories </span>
            </div>

            <div
                class="col-lg-2 col-md-3 col-4"
                v-for="category in categories_to_show"
                :key="category.id"
            >
                <category :category="category"></category>
            </div>
        </div>
    </div>
</template>

<script>
import Category from "./Category.vue";

export default {
    components: { Category },

    data() {
        return {
            categories: [
                {
                    id: 1,
                    img: "https://via.placeholder.com/300/0d6efd",
                    name: "Test_Desc",
                    class: "0d6efd",
                },
                {
                    id: 2,
                    img: "https://via.placeholder.com/250/dc3545",
                    name: "Test_Desc",
                    class: "dc3545",
                },
                {
                    id: 3,
                    img: "https://via.placeholder.com/250/0d6efd",
                    name: "Test_Desc",
                    class: "0d6efd",
                },
                {
                    id: 4,
                    img: "https://via.placeholder.com/250/dc3545",
                    name: "Test_Desc",
                    class: "dc3545",
                },
                {
                    id: 5,
                    img: "https://via.placeholder.com/250/198754",
                    name: "Test_Desc",
                    class: "198754",
                },
                {
                    id: 6,
                    img: "https://via.placeholder.com/250/343a40",
                    name: "Test_Desc",
                    class: "343a40",
                },
                {
                    id: 7,
                    img: "https://via.placeholder.com/250/198754",
                    name: "Test_Desc",
                    class: "198754",
                },
                {
                    id: 8,
                    img: "https://via.placeholder.com/300/343a40",
                    name: "Test_Desc",
                    class: "343a40",
                },
                {
                    id: 9,
                    img: "https://via.placeholder.com/250/0d6efd",
                    name: "Test_Desc",
                    class: "0d6efd",
                },
                {
                    id: 10,
                    img: "https://via.placeholder.com/250/dc3545",
                    name: "Test_Desc",
                    class: "dc3545",
                },
                {
                    id: 11,
                    img: "https://via.placeholder.com/250/198754",
                    name: "Test_Desc",
                    class: "198754",
                },
                {
                    id: 12,
                    img: "https://via.placeholder.com/250/343a40",
                    name: "Test_Desc",
                    class: "343a40",
                },
                {
                    id: 13,
                    img: "https://via.placeholder.com/250/198754",
                    name: "Test_Desc",
                    class: "198754",
                },
                {
                    id: 14,
                    img: "https://via.placeholder.com/300/343a40",
                    name: "Test_Desc",
                    class: "343a40",
                },
            ],

            categories_to_show: [],
        };
    },

    created() {
        this.categories_to_show = [...this.categories];
    },
};
</script>

<style lang="scss" scoped>
.allCategories {
    padding: 0 10px;
    margin-top: 100px;

    .header_wraper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 25px;

        h2 {
            color: #313131;
            z-index: 2;
            font-weight: bold;
            font-size: 45px;
        }

        .title_backdrop {
            position: absolute;
            top: -95%;
            font-size: 80px;
            font-weight: bold;
            color: rgba(225, 225, 225, 0.2);
            text-shadow: 0 0 6px rgb(225, 225, 225, 0.9);
            z-index: 1;
        }

        @media (max-width: 767px) {
            .title_backdrop {
                font-size: 60px;
                top: -70%;
            }
        }
    }
}
</style>
