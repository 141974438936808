<template>
  <div class="landing_hero" :style="background(main_image)">
    <!-- START:: CONTENT -->
    <div class="hero_content">
      <div class="hero_overlay">
        <div class="row w-100 justify-content-between align-items-center">
          <div
            class="col-lg-5"
            v-animate-css="
              localeGetter == 'en' ? fadeInLeftBig : fadeInRightBig
            "
          >
            <!-- START:: TITLE -->
            <div class="hero_title">
              <h2 v-if="home_msg" v-html="home_msg"></h2>
              <h2 v-else>Welcome To Lime</h2>

              <h1 v-if="title" v-html="title"></h1>
              <h1 v-else>Food Is Art</h1>
              <h3 v-if="title_desc" v-html="title_desc"></h3>
              <h3 v-else>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem
                adipisci repudiandae ullam reprehenderit vel.
              </h3>
            </div>
            <!-- END:: TITLE -->
          </div>
        </div>

        <!-- <div class="col-lg-7 p-0"> -->
        <!-- START:: HERO SECTION -->
        <!-- <div class="hero_img">
            <img
              src="../../assets/media/imgs/hero_img.png"
              alt="Hero Section Image"
              v-if="localeGetter == 'en'"
            />
            <img
              src="../../assets/media/imgs/hero_img_rtl.png"
              alt="Hero Section Image"
              v-else
            />
          </div> -->
        <!-- END:: HERO SECTION -->
        <!-- </div> -->
      </div>
    </div>
    <!-- END:: CONTENT -->

    <!-- START:: HERO SECTION BOTTOM SHAPE -->
    <div class="bottom_shape"></div>
    <!-- END:: HERO SECTION BOTTOM SHAPE -->
  </div>
</template>

<script>
// START:: IMPORT VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORT VUEX HELPERS

export default {
  name: "LandingHero",
  data() {
    return {
      // START:: ANIMATIONS DATA
      fadeInRightBig: {
        classes: "fadeInRightBig",
        duration: 2000,
      },
      fadeInLeftBig: {
        classes: "fadeInLeftBig",
        duration: 2000,
      },
      // END:: ANIMATIONS DATA
    };
  },
  props: ["main_image", "home_msg", "title", "title_desc"],
  computed: {
    ...mapGetters(["localeGetter"]),
  },
  mounted() {},
  methods: {
    background(image) {
      if (image) {
        return {
          "background-image": `url(${image})`,
        };
      } else {
        return "";
      }
    },
  },
};
</script>
