<template>
  <div id="landing_about" class="landing_about">
    <div class="container">
      <div class="row justify-content-around align-items-center">
        <div class="col-lg-4">
          <!-- START:: ABOUT IMAGE -->
          <div class="about_image_wrapper">
            <img v-if="about_image" :src="about_image" alt="About Image" />
            <img v-else src="@/assets/media/imgs/plate.png" alt="About Image" />
          </div>
          <!-- END:: ABOUT IMAGE -->
        </div>

        <div class="col-lg-5">
          <!-- START:: ABOUT TEXT -->
          <div class="about_text">
            <h4>{{ $t("navbar.about") }}</h4>
            <h2 v-if="about_title" v-html="about_title"></h2>
            <h2 v-else>{{ $t("titles.about_title") }}</h2>
            <div
              class="text_wrapper"
              v-if="about_desc"
              v-html="about_desc"
            ></div>
            <div class="text_wrapper" v-else>
              {{ $t("titles.about_desc") }}
            </div>
          </div>
          <!-- END:: ABOUT TEXT -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "landingAbout",
  props: ["about_title", "about_desc", "about_image"],
  methods: {
    imageSrc(image) {
      if (image) {
        return image;
      } else {
        return "./../../assets/media/imgs/plate.png";
      }
    },
  },
};
</script>
