<template>
    <div class="category">
        <div class="image">
            <img :src="category.img" />
        </div>

        <div class="name">
            <p>{{ category.name }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: ["category"],

    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.category {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .image {
        overflow: hidden;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        box-shadow: 0px 4px 10px #7f7f7f;

        img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            cursor: pointer;
            transition: all 0.2s linear;

            &:hover {
                transform: scale(1.2);
            }
        }
    }

    .name {
        margin: 10px 0;
    }

    @media (max-width: 768px) {
        .image {
            width: 90px;
            height: 90px;

            img {
                &:hover {
                    transform: unset;
                }
            }
        }
    }
}
</style>
