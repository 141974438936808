<template>
  <div class="add_cat_modal_wrapper">
    <BaseModal :show="modalAppearanceData" @click="controlModalAppearance">
      <template #modal>
        <div class="add_cat_card">
          <div class="form_wrapper">
            <form @submit.prevent="validateAddMenuCat">
              <div class="row">
                <!-- START:: IMAGE INPUT -->
                <div class="col-12 my-3">
                  <div class="input_group avatar_input_wrapper">
                    <label for="avatar">
                      <img
                        src="../../assets/media/icons/camera.svg"
                        alt="icon"
                        width="120"
                        height="120"
                        v-if="!addItemMenuCat.image.path"
                      />
                      <img
                        :src="addItemMenuCat.image.path"
                        alt="avatar"
                        width="120"
                        height="120"
                        v-else
                      />
                    </label>
                    <input
                      id="avatar"
                      class="form-control custom_input"
                      type="file"
                      @change="selectUploadedImage"
                    />
                    <span> {{ $t("placeholders.category_image") }} </span>
                  </div>
                </div>
                <!-- END:: IMAGE INPUT -->

                <!-- START:: ENGLISH CATEGORY NAME -->
                <div class="col-lg-12 my-3">
                  <div class="input_group">
                    <input
                      class="form-control custom_input"
                      type="text"
                      :placeholder="$t('placeholders.cat_english_name')"
                      v-model.trim="addItemMenuCat.catEnglishName"
                    />
                  </div>
                </div>
                <!-- END:: ENGLISH CATEGORY NAME -->

                <!-- START:: ARABIC CATEGORY NAME -->
                <div class="col-lg-12 my-3">
                  <div class="input_group">
                    <input
                      class="form-control custom_input"
                      type="text"
                      :placeholder="$t('placeholders.cat_arabic_name')"
                      v-model.trim="addItemMenuCat.catArabicName"
                    />
                  </div>
                </div>
                <!-- END:: ARABIC CATEGORY NAME -->

                <!-- START:: SUBMIT BTN WRAPPER -->
                <div class="btn_wrapper">
                  <button class="main_btn">
                    {{ $t("buttons.save") }}
                    <span class="btn_loader" v-if="isWaitingRequest"></span>
                  </button>
                </div>
                <!-- END:: SUBMIT BTN WRAPPER -->
              </div>
            </form>
          </div>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
// START:: IMPORTING BASE MODAL
import BaseModal from "../UI/BaseModal.vue";
// END:: IMPORTING BASE MODAL

export default {
  name: "AddMenuCategoryModal",
  data() {
    return {
      // START:: BUTTON LOADER HANDLING DATA
      isWaitingRequest: false,
      // END:: BUTTON LOADER HANDLING DATA

      // START:: ADD ACCOUNT DATA
      addItemMenuCat: {
        image: {
          path: null,
          file: null,
        },
        catArabicName: null,
        catEnglishName: null,
      },
      // END:: ADD ACCOUNT DATA
    };
  },

  components: {
    BaseModal,
  },
  props: ["modalAppearanceData"],

  emits: ["controlModalAppearance"],

  methods: {
    // START:: CONTROL MODAL APPEARANCE
    controlModalAppearance() {
      this.$emit("controlModalAppearance");
    },
    // END:: CONTROL MODAL APPEARANCE

    // START:: SELECT UPLOADED IMAGE
    selectUploadedImage(e) {
      console.log("test select");
      this.addItemMenuCat.image.path = URL.createObjectURL(e.target.files[0]);
      this.addItemMenuCat.image.file = e.target.files[0];
    },
    // END:: SELECT UPLOADED IMAGE

    validateAddMenuCat() {
      this.submitAddMenuCatForm();
    },

    submitAddMenuCatForm() {
      this.controlModalAppearance();
    },
  },
};
</script>
