<template>
  <div class="accordion" role="tablist">
    <div @click="rotateIsTrue = !rotateIsTrue" class="accordion__header">
      <span v-b-toggle="'accordion-' + randomKey" class="_title">{{
        accordionData.title
      }}</span>
      <span :class="{ rotate: rotateIsTrue }" class="icon"
        ><i class="far fa-chevron-down"></i
      ></span>
    </div>

    <b-collapse
      :id="'accordion-' + randomKey"
      :visible="rotateIsTrue"
      accordion="my-accordion"
      role="tabpanel"
    >
      <b-card-body>
        <div class="accordion__body">
          <slot></slot>
        </div>
      </b-card-body>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: ["accordionData"],

  data() {
    return {
      rotateIsTrue: false,
      randomKey: (Math.random() + 1).toString(36).substring(7),
    };
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  .accordion__header {
    position: relative;

    span.icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      font-size: 18px;
      transition: all 0.2s linear;

      &.rotate {
        transform: rotate(180deg);
      }
    }
  }

  .accordion__body {
    margin-top: 10px;
    padding: 10px 15px;
    border-bottom: 1px solid rgb(189, 189, 189);
  }
}

body.rtl {
  .accordion {
    .accordion__header {
      span.icon {
        right: unset !important;
        left: 20px;
      }
    }
  }
}
</style>
