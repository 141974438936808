<template>
  <div class="wraper d-flex justify-content-center align-items-center">
    <!-- START:: PRODUCT CARD -->
    <div class="product_card_wraper">
      <div class="product_image_wraper">
        <div class="badges_wraper">
          <span class="badge" v-for="badge in productData.badges" :key="badge">
            {{ badge }}
          </span>
          <span
            class="badge bg-danger"
            v-if="productData.status == 'out of stock'"
          >
            Out Of Stock
          </span>
        </div>

        <div class="overlay">
          <div class="actions_btns_wraper">
            <router-link
              :to="{
                name: 'ProductDetails',
                params: { product_id: productData.id },
              }"
              v-b-tooltip.hover
              title="Details"
            >
              <ArrowRightIcon size="1.2x" class="arrow_icon" />
            </router-link>

            <button
              class="add_to_wish added"
              v-b-tooltip.hover
              title="Remove From Wishlist"
              v-if="productData.inWishlist"
            >
              <HeartIcon size="1.2x" class="heart_icon" />
            </button>

            <button
              class="add_to_wish"
              v-b-tooltip.hover
              title="Add To Wishlist"
              v-else
            >
              <HeartIcon size="1.2x" class="heart_icon" />
            </button>

            <button
              v-b-tooltip.hover
              title="Add To Cart"
              v-if="productData.status != 'out of stock'"
            >
              <ShoppingCartIcon size="1.2x" class="cart_icon" />
            </button>
          </div>

          <div class="bottom_button">
            <b-button v-b-modal="'modal-' + type + '-' + productData.id">
              quick_view
            </b-button>
          </div>
        </div>

        <img
          :src="productData.images[0].img"
          alt="Product Image"
          width="100"
          height="100"
        />
        <img
          :src="productData.images[1].img"
          alt="Product Image"
          width="100"
          height="100"
        />
      </div>

      <div class="product_card_body">
        <p class="category">{{ productData.categoryName }}</p>
        <h5 class="product_name">
          {{
            productData.productName.length > 33
              ? productData.productName.substring(0, 30) + ".."
              : productData.productName
          }}
        </h5>
        <h4 class="product_price" v-if="productData.discount.length != 0">
          <span class="current_price">
            <span>{{ productData.discount }}</span>
            <span>EGP</span>
          </span>

          <span class="previous_price">
            <span>{{ productData.price }}</span>
            <span>EGP</span>
          </span>
        </h4>

        <h4 class="product_price" v-else>
          <span class="current_price">
            <span>{{ productData.price }}</span>
            <span>EGP</span>
          </span>
        </h4>
        <div class="rating">
          <RatingStars :rate="productData.rate" />
          <div class="reviews">( {{ productData.reviews }} Reviews )</div>
        </div>
      </div>
    </div>
    <!-- END:: PRODUCT CARD -->
  </div>
</template>

<script>
// END:: IMPORTING SLICK CAOUSIL
import RatingStars from "../Global/RatingStars.vue";

import { ArrowRightIcon, HeartIcon, ShoppingCartIcon } from "vue-feather-icons";

export default {
  components: {
    RatingStars,
    ArrowRightIcon,
    HeartIcon,
    ShoppingCartIcon,
  },

  props: ["productData", "type"],
};
</script>
