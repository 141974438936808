import Vue from "vue";
import VueRouter from "vue-router";
//middleware
import auth from "../middleware/auth";
import log from "../middleware/log";
// START:: IMPORTING ROUTER COMPONENTS

//  =================== LANDING COMPONENTS =================== //
import TheLanding from "../pages/Landing/TheLanding.vue";
//  =================== LANDING COMPONENTS =================== //

import HomePage from "../pages/HomePage.vue";
import Authentication from "../pages/Auth.vue";
import CreateAccount from "../components/authentication/mainAccount/CreateAccount.vue";
import LoginAccount from "../components/authentication/mainAccount/LoginAccount.vue";
import UserAccount from "../pages/UserAccount.vue";
import BranchProfile from "../pages/BranchProfile.vue";
import RestaurantSetup from "../components/authentication/restaurant/RestaurantSetup.vue";
import NameAndAddress from "../components/authentication/restaurant/NameAndAddress.vue";
import ContactInfo from "../components/authentication/restaurant/ContactInfo.vue";
import CategoryAndPrice from "../components/authentication/restaurant/CategoryAndPrice.vue";
import RestaurantCuisines from "../components/authentication/restaurant/RestaurantCuisines.vue";
import FeaturesAndOccasion from "../components/authentication/restaurant/FeaturesAndOccasion.vue";
import BookingEngineSystem from "../components/authentication/restaurant/BookingEngineSystem.vue";
import DashboardSettings from "../components/authentication/restaurant/DashboardSettings.vue";
import PhoneAndEmailNotifications from "../components/authentication/restaurant/PhoneAndEmailNotifications.vue";
import DashboardPreferences from "../components/authentication/restaurant/DashboardPreferences.vue";
import BusinessHours from "../components/authentication/restaurant/BusinessHours.vue";

import ProductDetails from "../pages/Products/ProductDetails.vue";
// END:: IMPORTING ROUTER COMPONENTS

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    component: TheLanding,
    name: "TheLanding",
    meta: {
      middleware: [log],
    },
  },
  {
    path: "/home",
    name: "HomePage",
    component: HomePage,
    children: [],
  },
  {
    path: "/register",
    name: "Authentication",
    component: Authentication,
    children: [
      {
        path: "/register",
        name: "CreateAccount",
        component: CreateAccount,
      },
      {
        path: "/restaurant-setup",
        name: "RestaurantSetup",
        component: RestaurantSetup,
      },
      {
        path: "/restaurant-name-and-address",
        name: "NameAndAddress",
        component: NameAndAddress,
      },
      {
        path: "/restaurant-contact-info",
        name: "ContactInfo",
        component: ContactInfo,
      },
      {
        path: "/restaurant-category-and-price",
        name: "CategoryAndPrice",
        component: CategoryAndPrice,
      },
      {
        path: "/cuisines",
        name: "RestaurantCuisines",
        component: RestaurantCuisines,
      },
      {
        path: "/features-and-occasion",
        name: "FeaturesAndOccasion",
        component: FeaturesAndOccasion,
      },
      {
        path: "/booking-system-engine",
        name: "BookingEngineSystem",
        component: BookingEngineSystem,
      },
      {
        path: "/dashboard-settings",
        name: "DashboardSettings",
        component: DashboardSettings,
      },
      {
        path: "/phone-and-email-notifications",
        name: "PhoneAndEmailNotifications",
        component: PhoneAndEmailNotifications,
      },
      {
        path: "/dashboard-preferences",
        name: "DashboardPreferences",
        component: DashboardPreferences,
      },
      {
        path: "/business-hours",
        name: "BusinessHours",
        component: BusinessHours,
      },
      {
        path: "/productDetails",
        name: "ProductDetails",
        component: ProductDetails,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: LoginAccount,
  },
  {
    path: "/user-account",
    name: "UserAccount",
    component: UserAccount,
  },
  {
    path: "/branch-profile/:id",
    name: "BranchProfile",
    component: BranchProfile,
    meta: {
      middleware: [log, auth],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
  routes,
});
// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Than run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});
export default router;
