<template>
  <transition name="fadeIn">
    <div @click="closeMenu" v-if="show" class="menu_container">
      <!-- <transition name="slide"> -->
      <main @click.stop class="main_body sideMenu">
        <span @click="closeMenu" class="close"
          ><i class="fas fa-times"></i
        ></span>
        <slot></slot>
      </main>
      <!-- </transition> -->
    </div>
  </transition>
</template>

<script>
export default {
  name: "Model",

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    chatIcon: {
      type: Boolean,
      required: false,
    },
  },

  emits: ["closeMenu"],

  methods: {
    closeMenu() {
      this.$emit("closeMenu");
    },
  },
};
</script>
