<template>
  <div class="restaurant_setup_form_wrapper">
    <!-- START:: LOGO WRAPPER -->
    <router-link to="/" class="logo_wrapper">
      <img
        src="../../../assets/media/logo/logo.png"
        alt="Logo"
        width="120"
        height="80"
      />
    </router-link>
    <!-- END:: LOGO WRAPPER -->

    <!-- START:: TITLE -->
    <div class="title_wrapper">
      <h3>{{ $t("titles.new_restaurant_register") }}</h3>
      <h5>{{ $t("titles.new_restaurant_setup") }}</h5>
    </div>
    <!-- END:: TITLE -->

    <!-- START:: FORM -->
    <div class="form_wrapper" style="min-width: 50vw; max-width: 50vw">
      <form @submit.prevent="validateRestaurantSetupForm">
        <div class="row">
          <!-- START:: AVATAR INPUT -->
          <div class="input_group avatar_input_wrapper col-6">
            <h5>{{ $t("titles.branch_icon") }}</h5>
            <label for="avatar">
              <img
                src="../../../assets/media/icons/camera.svg"
                alt="icon"
                width="120"
                height="120"
                v-if="!restaurantSetupData.icon.bath"
              />
              <img
                :src="restaurantSetupData.icon.bath"
                alt="avatar"
                width="120"
                height="120"
                v-else
              />
            </label>
            <input
              id="avatar"
              class="form-control custom_input"
              type="file"
              @change="selectUploadedIcon"
            />
          </div>
          <!-- END:: AVATAR INPUT -->

          <!-- START:: Image INPUT -->
          <div class="input_group avatar_input_wrapper col-6">
            <h5>{{ $t("titles.branch_main_image") }}</h5>
            <label for="image">
              <img
                src="../../../assets/media/icons/camera.svg"
                alt="icon"
                width="120"
                height="120"
                v-if="!restaurantSetupData.image.bath"
              />
              <img
                :src="restaurantSetupData.image.bath"
                alt="image"
                width="120"
                height="120"
                v-else
              />
            </label>
            <input
              id="image"
              class="form-control custom_input"
              type="file"
              @change="selectUploadedImage"
            />
          </div>
          <!-- END:: AVATAR INPUT -->
        </div>
        <div class="row">
          <!-- START:: RESTAURANT NAME EN INPUT -->
          <div class="input_group col-5">
            <label for="restaurant_name_en">
              <img
                src="../../../assets/media/icons/pen.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <input
              id="restaurant_name_en"
              class="form-control custom_input"
              type="text"
              :placeholder="$t('placeholders.restaurant_name_en')"
              v-model.trim="restaurantSetupData.restaurantNameEn"
            />
          </div>
          <!-- END:: RESTAURANT NAME EN INPUT -->
          <div class="col-2"></div>
          <!-- START:: RESTAURANT NAME AR INPUT -->
          <div class="input_group col-5">
            <label for="restaurant_name_ar">
              <img
                src="../../../assets/media/icons/pen.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <input
              id="restaurant_name_ar"
              class="form-control custom_input"
              type="text"
              :placeholder="$t('placeholders.restaurant_name_ar')"
              v-model.trim="restaurantSetupData.restaurantNameAr"
            />
          </div>
          <!-- END:: RESTAURANT NAME AR INPUT -->
        </div>
        <div class="row">
          <!-- START:: RESTAURANT Desc EN INPUT -->
          <div class="input_group col-5">
            <label for="restaurant_desc_en">
              <img
                src="../../../assets/media/icons/pen.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <textarea
              id="restaurant_desc_en"
              class="form-control custom_input"
              type="text"
              :placeholder="$t('placeholders.restaurant_desc_en')"
              v-model.trim="restaurantSetupData.restaurantDescEn"
            />
          </div>
          <!-- END:: RESTAURANT Desc EN INPUT -->
          <div class="col-2"></div>

          <!-- START:: RESTAURANT Desc AR INPUT -->
          <div class="input_group col-5">
            <label for="restaurant_desc_ar">
              <img
                src="../../../assets/media/icons/pen.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <textarea
              id="restaurant_desc_ar"
              class="form-control custom_input"
              type="text"
              :placeholder="$t('placeholders.restaurant_desc_ar')"
              v-model.trim="restaurantSetupData.restaurantDescAr"
            />
          </div>
          <!-- END:: RESTAURANT Desc AR INPUT -->
        </div>
        <div class="row">
          <!-- START:: COUNTRY INPUT -->
          <div class="input_group col-5">
            <label for="countries_select">
              <img
                src="../../../assets/media/icons/flag.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <multiselect
              id="countries_select"
              v-model="restaurantSetupData.country"
              track-by="name"
              label="name"
              :placeholder="$t('placeholders.select_country')"
              :options="countriesList"
              :searchable="true"
              :allow-empty="true"
              :show-labels="false"
              @input="getCitiesByCountry"
            >
            </multiselect>
          </div>
          <!-- END:: COUNTRY INPUT -->
          <div class="col-2"></div>
          <!-- START:: CITY INPUT -->
          <div class="input_group col-5">
            <label for="city_select">
              <img
                src="../../../assets/media/icons/location.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <multiselect
              id="city_select"
              v-model="restaurantSetupData.city"
              track-by="name"
              label="name"
              :placeholder="$t('placeholders.city')"
              :options="citiesList"
              :searchable="true"
              :allow-empty="true"
              :show-labels="false"
            >
            </multiselect>
          </div>
          <!-- END:: CITY INPUT -->
        </div>
        <div class="row">
          <!-- START:: CUISINES INPUT -->
          <div class="input_group col-5">
            <label for="categories_select">
              <img
                src="../../../assets/media/icons/forkAndKnife.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <multiselect
              id="categories_select"
              v-model="restaurantSetupData.categories"
              track-by="name"
              label="name"
              :placeholder="$t('placeholders.business_category')"
              :options="categoriesList"
              :searchable="true"
              :allow-empty="true"
              :show-labels="false"
              :multiple="true"
            >
            </multiselect>
          </div>
          <!-- END:: meal_types INPUT -->
          <div class="col-2"></div>
          <!-- START:: mealtypes INPUT -->
          <div class="input_group col-5">
            <label for="meal_types_select">
              <img
                src="../../../assets/media/icons/forkAndKnife.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <multiselect
              id="meal_types_select"
              v-model="restaurantSetupData.meal_types"
              track-by="name"
              label="name"
              :placeholder="$t('titles.meal_types')"
              :options="mealTypesList"
              :searchable="true"
              :allow-empty="true"
              :show-labels="false"
              :multiple="true"
            >
            </multiselect>
          </div>
          <!-- END:: meal_types INPUT -->
        </div>
        <div class="row">
          <!-- START:: CUISINES INPUT -->
          <div class="input_group col-5">
            <label for="features_select">
              <img
                src="../../../assets/media/icons/forkAndKnife.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <multiselect
              id="cuisines_select"
              v-model="restaurantSetupData.features"
              track-by="name"
              label="name"
              :placeholder="$t('titles.features')"
              :options="featuresList"
              :searchable="true"
              :allow-empty="true"
              :show-labels="false"
              :multiple="true"
            >
            </multiselect>
          </div>
          <!-- END:: meal_types INPUT -->
          <div class="col-2"></div>
          <!-- START:: CUISINES INPUT -->
          <div class="input_group col-5">
            <label for="cuisines_select">
              <img
                src="../../../assets/media/icons/forkAndKnife.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <multiselect
              id="cuisines_select"
              v-model="restaurantSetupData.cuisines"
              track-by="name"
              label="name"
              :placeholder="$t('placeholders.cuisine')"
              :options="cuisinesList"
              :searchable="true"
              :allow-empty="true"
              :show-labels="false"
              :multiple="true"
            >
            </multiselect>
          </div>
          <!-- END:: meal_types INPUT -->
        </div>
        <div class="row">
          <!-- START:: RESTAURANT EMAIL INPUT -->
          <div class="input_group col-5">
            <label for="restaurant_email">
              <img
                src="../../../assets/media/icons/at.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <input
              id="restaurant_email"
              class="form-control custom_input"
              type="email"
              :placeholder="$t('placeholders.email')"
              v-model.trim="restaurantSetupData.email"
            />
          </div>
          <!-- END:: RESTAURANT EMAIL INPUT -->
          <div class="col-2"></div>
          <!-- START:: RESTAURANT PHONE INPUT -->
          <div class="input_group col-5">
            <label for="business_phone">
              <img
                src="../../../assets/media/icons/phone.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <input
              id="business_phone"
              class="form-control custom_input"
              type="tel"
              :placeholder="$t('placeholders.business_phone')"
              v-model.trim="restaurantSetupData.phone"
            />
          </div>
          <!-- END:: RESTAURANT PHONE INPUT -->
        </div>
        <div class="row">
          <!-- START:: RESTAURANT instagram INPUT -->
          <div class="input_group col-5">
            <label for="restaurant_instagram">
              <img
                src="../../../assets/media/icons/instagram.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <input
              id="restaurant_instagram"
              class="form-control custom_input"
              type="text"
              :placeholder="$t('placeholders.instagram')"
              v-model.trim="restaurantSetupData.instagram"
            />
          </div>
          <!-- END:: RESTAURANT instagram INPUT -->
          <div class="col-2"></div>
          <!-- END:: meal_types INPUT -->
          <!-- START:: RESTAURANT WEBSITE INPUT -->
          <div class="input_group col-5">
            <label for="restaurant_website">
              <img
                src="../../../assets/media/icons/chrome.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <input
              id="restaurant_website"
              class="form-control custom_input"
              type="text"
              :placeholder="$t('placeholders.website')"
              v-model.trim="restaurantSetupData.website"
            />
          </div>
          <!-- END:: RESTAURANT WEBSITE INPUT -->
          <!-- END:: RESTAURANT instagram INPUT -->
        </div>
        <div class="row">
          <!-- START:: CUISINES INPUT -->
          <div class="input_group col-5">
            <label for="is_smoked_select">
              <img
                src="../../../assets/media/icons/kids-area.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <multiselect
              id="is_smoked_select"
              v-model="restaurantSetupData.has_kids_area"
              track-by="name"
              label="name"
              :placeholder="$t('titles.has_kids_area')"
              :options="kidsAreaList"
              :show-labels="false"
              :searchable="true"
              :allow-empty="true"
            >
            </multiselect>
          </div>
          <!-- END:: meal_types INPUT -->
          <div class="col-2"></div>
          <!-- START:: CUISINES INPUT -->
          <div class="input_group col-5">
            <label for="is_smoked_select">
              <img
                src="../../../assets/media/icons/smoking.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <multiselect
              id="is_smoked_select"
              v-model="restaurantSetupData.is_smoked"
              track-by="name"
              label="name"
              :placeholder="$t('titles.has_smoking')"
              :options="smokedList"
              :show-labels="false"
              :searchable="true"
              :allow-empty="true"
            >
            </multiselect>
          </div>
          <!-- END:: RESTAURANT instagram INPUT -->
        </div>
        <div>
          <gmap-autocomplete
            :placeholder="$t('placeholders.address')"
            @place_changed="setPlace"
            class="form-control"
          >
          </gmap-autocomplete>
          <GmapMap
            :center="center"
            :zoom="12"
            map-type-id="terrain"
            style="width: 100%; height: 300px"
            @click="updatePosition"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="true"
              @click="center = m.position"
              @dragend="updatePosition"
            />
          </GmapMap>
        </div>
        <!-- START:: SUBMIT BTN WRAPPER -->
        <div class="btn_wrapper">
          <button class="main_btn">
            {{ $t("buttons.continue") }}
            <span class="btn_loader" v-if="isWaitingRequest"></span>
          </button>
        </div>
        <!-- END:: SUBMIT BTN WRAPPER -->
      </form>
    </div>
    <!-- END:: FORM -->
  </div>
</template>

<script>
// START:: IMPORT VUEX HELPERS
import { mapGetters } from "vuex";
import { gmapApi } from "vue2-google-maps";
import axios from "axios";
// END:: IMPORT VUEX HELPERS

export default {
  name: "RestaurantSetup",

  data() {
    return {
      isWaitingRequest: false,
      markers: [
        {
          position: {
            lat: 51.093048,
            lng: 6.84212,
          },
        },
      ],
      address: "",
      center: { lat: 10, lng: 10 },
      restaurantSetupData: {
        language: "ar",
        timeZone: "ar",
        role: "1",
        icon: {
          bath: null,
          file: null,
        },
        image: {
          bath: null,
          file: null,
        },
        restaurantNameEn: null,
        restaurantNameAr: null,
        restaurantDescEn: null,
        restaurantDescAr: null,
        country: null,
        city: null,
        categories: [],
        cuisines: [],
        meal_types: [],
        features: [],
        website: "",
        instagram: "",
        phone: "",
        email: "",
        is_smoked: null,
        has_kids_area: null,
        lat: null,
        lng: null,
        location: null,
      },
    };
  },

  computed: {
    ...mapGetters("apisGetsModule", [
      "languagesList",
      "countriesList",
      "timeZonesList",
      "rolesList",
      "citiesList",
      "categoriesList",
      "cuisinesList",
      "mealTypesList",
      "featuresList",
    ]),
    smokedList() {
      return [
        { id: null, name: this.$t("titles.please_choose_smoked_option") },
        { id: "enable", name: this.$t("titles.available") },
        { id: "disable", name: this.$t("titles.not_available") },
      ];
    },
    kidsAreaList() {
      return [
        { id: null, name: this.$t("titles.please_choose_kids_area_option") },
        { id: "enable", name: this.$t("titles.available") },
        { id: "disable", name: this.$t("titles.not_available") },
      ];
    },
  },

  methods: {
    // START:: SELECT UPLOADED IMAGE
    selectUploadedIcon(e) {
      console.log(e.target);
      this.restaurantSetupData.icon.bath = URL.createObjectURL(
        e.target.files[0]
      );
      this.restaurantSetupData.icon.file = e.target.files[0];
    },
    // END:: SELECT UPLOADED IMAGE
    // START:: SELECT UPLOADED IMAGE
    selectUploadedImage(e) {
      console.log(e.target);
      this.restaurantSetupData.image.bath = URL.createObjectURL(
        e.target.files[0]
      );
      this.restaurantSetupData.image.file = e.target.files[0];
    },
    // END:: SELECT UPLOADED IMAGE
    // START:: VALIDATE FIRST STEP FORM
    validateRestaurantSetupForm() {
      this.isWaitingRequest = true;

      if (!this.restaurantSetupData.language) {
        this.$message.error(this.$t("validation.select_language_validation"));
        this.isWaitingRequest = false;
        return;
      } else if (!this.restaurantSetupData.country) {
        this.$message.error(this.$t("validation.select_country_validation"));
        this.isWaitingRequest = false;
        return;
      } else if (!this.restaurantSetupData.timeZone) {
        this.$message.error(this.$t("validation.select_time_zone_validation"));
        this.isWaitingRequest = false;
        return;
      } else if (!this.restaurantSetupData.role) {
        this.$message.error(this.$t("validation.select_role_validation"));
        this.isWaitingRequest = false;
        return;
      } else {
        this.submitRestaurantSetupForm();
      }
    },
    // END:: VALIDATE FIRST STEP FORM

    // START:: SUBMIT FIRST STEP FORM
    async submitRestaurantSetupForm() {
      try {
        let categories = [
          ...this.restaurantSetupData.categories.map(function (category) {
            return category.id;
          }),
        ];
        let cuisines = [
          ...this.restaurantSetupData.cuisines.map(function (cuisine) {
            return cuisine.id;
          }),
        ];
        let meal_types = [
          ...this.restaurantSetupData.meal_types.map(function (meal_type) {
            return meal_type.id;
          }),
        ];
        let features = [
          ...this.restaurantSetupData.features.map(function (feature) {
            return feature.id;
          }),
        ];

        console.log(categories);
        const data = new FormData();
        data.append("icon", this.restaurantSetupData.icon.file);
        data.append("image", this.restaurantSetupData.image.file);
        data.append("ar[name]", this.restaurantSetupData.restaurantNameAr);
        data.append("ar[desc]", this.restaurantSetupData.restaurantDescAr);
        data.append("en[name]", this.restaurantSetupData.restaurantNameEn);
        data.append("en[desc]", this.restaurantSetupData.restaurantDescEn);
        data.append(
          "country_id",
          this.restaurantSetupData.country
            ? this.restaurantSetupData.country.id
            : null
        );
        data.append(
          "city_id",
          this.restaurantSetupData.city
            ? this.restaurantSetupData.city.id
            : null
        );
        categories.forEach((category, i) =>
          data.append(`categories[${i}]`, category)
        );
        cuisines.forEach((cuisine, i) =>
          data.append(`cuisines[${i}]`, cuisine)
        );
        meal_types.forEach((meal_type, i) =>
          data.append(`meal_types[${i}]`, meal_type)
        );
        features.forEach((feature, i) =>
          data.append(`features[${i}]`, feature)
        );
        data.append("website", this.restaurantSetupData.website);
        data.append("instagram", this.restaurantSetupData.instagram);
        data.append("phone", this.restaurantSetupData.phone);
        data.append("email", this.restaurantSetupData.email);
        data.append(
          "is_smoked",
          this.restaurantSetupData.is_smoked
            ? this.restaurantSetupData.is_smoked.id
            : null
        );
        data.append(
          "kids_area",
          this.restaurantSetupData.has_kids_area
            ? this.restaurantSetupData.has_kids_area.id
            : null
        );
        data.append("location", this.address);
        data.append("lat", this.markers[0].position.lat);
        data.append("lng", this.markers[0].position.lng);
        const response = await axios.post("branch", data);
        if (response.status == 200) {
          this.$router.push("user-account");
        }
      } catch (error) {
        console.log(error.response);
      }

      setTimeout(() => {
        this.isWaitingRequest = false;
        // this.$router.push("/restaurant-name-and-address");
      }, 2000);
    },
    // END:: SUBMIT FIRST STEP FORM
    async geoLocate() {
      navigator.geolocation.getCurrentPosition(async (position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.markers[0].position.lat = position.coords.latitude;
        this.markers[0].position.lng = position.coords.longitude;

        // console.log(gmapApi());
        if (gmapApi()) {
          // console.log(await gmapApi().maps.Geocoder.prototype.geocode({location :{lat:position.coords.latitude,lng: position.coords.longitude}}));
        }
      });
    },
    async getAddressFrom(lat, lng) {
      try {
        let response = await axios.get(
          "https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            lat +
            "," +
            lng +
            "&key=AIzaSyDRymdCLWxCwLHFnwv36iieKAMjiwk8sdc"
        );
        this.filterAddressNameData(response.data.results);
      } catch (error) {
        console.log(error.response);
      }
    },
    async updatePosition(e) {
      // console.log(e.latLng);
      console.log(
        await gmapApi().maps.Geocoder.prototype.geocode({ location: e.latLng })
      );
      this.center = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };
      this.markers[0].position.lat = e.latLng.lat();
      this.markers[0].position.lng = e.latLng.lng();
      let response = await gmapApi().maps.Geocoder.prototype.geocode({
        location: e.latLng,
      });
      this.filterAddressNameData(response.results);
    },
    filterAddressNameData(results) {
      let result = results.filter(function (data) {
        return data.types.includes("administrative_area_level_1");
      });
      if (result.length > 0) {
        this.address = result[0].formatted_address;
      }
    },
    setPlace(e) {
      console.log(e.formatted_address);
      this.center = {
        lat: e.geometry.location.lat(),
        lng: e.geometry.location.lng(),
      };
      this.markers[0].position.lat = e.geometry.location.lat();
      this.markers[0].position.lng = e.geometry.location.lng();
      this.address = e.formatted_address;
    },
    async getFilterData() {
      try {
        const response = await axios.get("filter_data");
        this.$store.dispatch("apisGetsModule/filterData", response);
      } catch (error) {
        console.log(error.response);
      }
    },
    async getCitiesByCountry() {
      try {
        let id = this.restaurantSetupData.country
          ? this.restaurantSetupData.country.id
          : null;
        const response = await axios.get("cities", {
          params: {
            country_id: id,
          },
        });
        this.$store.dispatch("apisGetsModule/cities", response);
      } catch (error) {
        console.log(error.response);
      }
    },
  },
  created() {
    this.geoLocate();
    this.getFilterData();
  },
};
</script>
