<template>
  <nav class="landing_nav">
    <div class="container">
      <div class="wrapper">
        <!-- START:: LOGO WRAPPER -->
        <div class="logo_wrapper">
          <img v-if="logo" :src="logo" alt="Logo" width="80" height="80" />
          <img
            v-else
            src="../../../assets/media/logo/logo.png"
            alt="Logo"
            width="80"
            height="80"
          />
        </div>
        <!-- END:: LOGO WRAPPER -->

        <div class="group">
          <!-- START:: NAVBAR WRAPPER -->
          <ul class="navbar_wrapper">
            <li class="navbar_item">
              <router-link to="/"> {{ $t("navbar.home") }} </router-link>
            </li>
            <li class="navbar_item">
              <a href="#landing_about"> {{ $t("navbar.about") }} </a>
            </li>
            <li class="navbar_item">
              <a href="#landing_app_screens">
                {{ $t("navbar.app_screens") }}
              </a>
            </li>
            <li class="navbar_item">
              <a href="#landing_app_screens">
                {{ $t("navbar.testimonials") }}
              </a>
            </li>

            <template v-if="!user">
              <li class="navbar_item">
                <router-link to="/register" class="provider_register_route">
                  {{ $t("navbar.create_account") }}
                </router-link>
              </li>

              <li class="navbar_item">
                <router-link to="/login" class="provider_register_route">
                  {{ $t("navbar.login") }}
                </router-link>
              </li>
            </template>
            <template v-if="user">
              <li class="navbar_item">
                <router-link to="/user-account" class="">
                  <img
                    :src="user.image"
                    alt="lang_ar"
                    style="max-width: 40px; border-radius: 50%"
                  />
                </router-link>
              </li>
            </template>
          </ul>
          <!-- END:: NAVBAR WRAPPER -->

          <!-- START:: NAVBAR BTNS WRAPPER -->
          <div class="nav_btns_wrapper">
            <button class="change_lang_btn" @click="changeAppLanguage">
              <img
                v-if="localeGetter == 'ar'"
                src="../../../assets/media/icons/langs/ar.png"
                alt="lang_ar"
              />
              <img
                v-else
                src="../../../assets/media/icons/langs/en.png"
                alt="lang_en"
              />
            </button>

            <button
              class="small_screens_nav_menu_toggeler"
              @click="toggleSmallScreensNavMenu"
            >
              <i class="fas fa-bars fa-2x"></i>
            </button>
          </div>
          <!-- END:: NAVBAR BTNS WRAPPER -->
        </div>

        <!-- START:: SMALL SCREENS NAV MENU -->
        <div
          class="small_screens_nav_menu_wrapper"
          :class="{ active: smallScreensMenuIsOpen }"
        >
          <button
            class="small_screens_nav_menu_toggeler"
            @click="toggleSmallScreensNavMenu"
          >
            <i class="fas fa-times fa-2x"></i>
          </button>
        </div>
        <!-- END:: SMALL SCREENS NAV MENU -->
      </div>
    </div>
  </nav>
</template>

<script>
// START:: IMPORT VUEX HELPERS
import { mapGetters, mapActions } from "vuex";
// END:: IMPORT VUEX HELPERS

export default {
  name: "LandingNav",

  data() {
    return {
      // START:: SMALL SCREENS MENU HANDLING DATA
      smallScreensMenuIsOpen: false,
      // END:: SMALL SCREENS MENU HANDLING DATA
    };
  },
  props: ["logo"],
  computed: {
    ...mapGetters({ user: "auth/user" }),
    ...mapGetters(["localeGetter"]),
  },

  methods: {
    ...mapActions(["changeAppLanguage"]),

    // START:: TOGGLE SMALL SCREENS NAV MENU
    toggleSmallScreensNavMenu() {
      this.smallScreensMenuIsOpen = !this.smallScreensMenuIsOpen;
    },
    // END:: TOGGLE SMALL SCREENS NAV MENU
  },
};
</script>
