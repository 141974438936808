<template>
  <footer class="landing_footer">
    <!-- START:: TOP SHAPE -->
    <div class="footer_top_shape"></div>
    <!-- END:: TOP SHAPE -->

    <div class="container">
      <!-- START:: LOGO WRAPPER -->
      <div class="logo_wrapper" v-if="logo">
        <img :src="logo" alt="Logo" width="100" height="100" />
      </div>
      <div class="logo_wrapper" v-else>
        <img
          src="../../../assets/media/logo/logo.png"
          alt="Logo"
          width="100"
          height="100"
        />
      </div>

      <!-- END:: LOGO WRAPPER -->

      <!-- START:: FOLLOW LINKS -->
      <div class="follow_links_wrapper">
        <h4>{{ $t("titles.follow_us") }}</h4>
        <ul class="follow_links_list">
          <li class="list_item">
            <a :href="facebook" target="_blanck">
              <i class="fab fa-facebook-f"></i>
            </a>
          </li>

          <li class="list_item">
            <a :href="twitter" target="_blanck">
              <i class="fab fa-twitter"></i>
            </a>
          </li>

          <li class="list_item">
            <a :href="instagram" target="_blanck">
              <i class="fab fa-instagram"></i>
            </a>
          </li>

          <li class="list_item">
            <a :href="youtube" target="_blanck">
              <i class="fas fa-play"></i>
            </a>
          </li>
        </ul>
      </div>
      <!-- END:: FOLLOW LINKS -->

      <!-- START:: SUBSCRIBE FORM -->
      <!-- <div class="start_subscribe_form_wrapper">
        <form>
          <div class="input_group">
            <input
              type="email"
              class="form-control"
              placeholder="Your Email Address"
            />
            <button>Subscribe</button>
          </div>
        </form>
      </div> -->
      <!-- END:: SUBSCRIBE FORM -->

      <!-- START:: COPY RIGHTS -->
      <div class="copy_rights_wrapper">
        <h4>Copyright © {{ currentYear }}.All Rights Reserved By Lime</h4>
      </div>
      <!-- END:: COPY RIGHTS -->
    </div>
  </footer>
</template>

<script>
export default {
  name: "LandingFooter",
  props: ["facebook", "instagram", "twitter", "youtube", "logo"],
  data() {
    return {
      // START:: CURRENT YEAR DATA
      currentYear: new Date().getFullYear(),
      // END:: CURRENT YEAR DATA
    };
  },
};
</script>
