<template>
  <div id="landing_app_screens" class="landing_app_screens_wrapper">
    <!-- START:: SECTION TITLE -->
    <div class="title_wrapper">
      <h2>{{ $t("titles.app_screens") }}</h2>
    </div>
    <!-- END:: SECTION TITLE -->

    <!-- START:: SCREENS SLIDER SECTION -->
    <div class="screens_slider_wrapper">
      <div class="container">
        <!-- START:: MOCKUP -->
        <!-- <div class="mobile_mockup"></div> -->
        <!-- END:: MOCKUP -->

        <!-- START:: SCREENS CAROUSEL -->
        <ScreensSlider
          dir="ltr"
          :nav="false"
          :dots="false"
          :items="1"
          :loop="true"
          :autoplay="true"
          :center="true"
        >
          <!-- <img
            v-for="screen in appScreens"
            :key="screen.id"
            :src="screen.image"
          /> -->
          <template v-if="screens.length > 0">
            <div
              class="slide_content_wrapper"
              v-for="item in screens"
              :key="item"
            >
              <!-- ********** Start:: App Screen ********** -->
              <div class="screen_wrapper">
                <img
                  :src="item.image"
                  alt="App Screen"
                  width="250"
                  height="400"
                />
              </div>
              <!-- ********** End:: App Screen ********** -->

              <!-- ********** Start:: App Screen ********** -->
              <div class="screen_desc_wrapper">
                <div class="title_wrapper">
                  <h3 v-text="item.name"></h3>
                </div>

                <div class="desc" v-html="item.desc"></div>
              </div>
              <!-- ********** End:: App Screen ********** -->
            </div>
          </template>
          <template v-else>
            <div class="slide_content_wrapper" v-for="item in 5" :key="item">
              <!-- ********** Start:: App Screen ********** -->
              <div class="screen_wrapper">
                <img
                  src="../../assets/media/imgs/framedScreenApp.png"
                  alt="App Screen"
                  width="250"
                  height="400"
                />
              </div>
              <!-- ********** End:: App Screen ********** -->

              <!-- ********** Start:: App Screen ********** -->
              <div class="screen_desc_wrapper">
                <div class="title_wrapper">
                  <h3>{{ $t("titles.Screen") }}</h3>
                </div>

                <div class="desc">
                  {{ $t("titles.lorem_desc") }}
                </div>
              </div>
              <!-- ********** End:: App Screen ********** -->
            </div>
          </template>
        </ScreensSlider>
        <!-- END:: SCREENS CAROUSEL -->
      </div>
    </div>
    <!-- END:: SCREENS SLIDER SECTION -->
  </div>
</template>

<script>
// START:: IMPORTING OWL CAROUSEL
import ScreensSlider from "vue-owl-carousel";
// END:: IMPORTING OWL CAROUSEL

import screen1 from "../../assets/media/imgs/screen1.png";
import screen2 from "../../assets/media/imgs/screen2.png";
import screen3 from "../../assets/media/imgs/screen3.png";

export default {
  name: "LandingAppScreens",
  props: ["screens"],
  components: { ScreensSlider },

  data() {
    return {
      // START:: APP SCREENS DATA
      appScreens: [
        {
          id: "screen_1",
          image: screen1,
        },
        {
          id: "screen_2",
          image: screen2,
        },
        {
          id: "screen_3",
          image: screen3,
        },
      ],
      // END:: APP SCREENS DATA
    };
  },

  methods: {
    handleCarouselChange() {
      // let carouselItems =  document.querySelectorAll(".owl-item.active");
      // carouselItems.forEach((element, index) => {
      //   if( index == 2 ) {
      //     element.classList.add("current");
      //   }
      //     element.classList.remove("current");
      // });
      // console.log(carouselItems);
    },
  },
};
</script>
