import mutations from "./mutations.js";
import * as actions from "./actions";
import getters from "./getters.js";

export default {
  namespaced: true,
  state: {
    branches: [],
    details: null,
    images: [],
  },
  mutations: mutations,
  actions: {
    ...actions,
  },
  getters: getters,
};
