export const allBranches = ({ commit }, payload) => {
  commit("allBranches", payload);
};

export const branchDetail = ({ commit }, payload) => {
  commit("branchDetail", payload);
};

export const branchImages = ({ commit }, payload) => {
  commit("branchImages", payload);
};
