<template>
  <div class="rating mb-3">
    <!-- START:: ZERO STARS -->
    <span class="d-flex justify-content-center" v-if="rate == '0'">
      <i class="fal fa-star"></i>
      <i class="fal fa-star"></i>
      <i class="fal fa-star"></i>
      <i class="fal fa-star"></i>
      <i class="fal fa-star"></i>
    </span>
    <!-- END:: ZERO STARS -->

    <!-- START:: ONE STARS -->
    <span class="d-flex justify-content-center" v-if="rate == '1'">
      <i class="fas fa-star"></i>
      <i class="fal fa-star"></i>
      <i class="fal fa-star"></i>
      <i class="fal fa-star"></i>
      <i class="fal fa-star"></i>
    </span>
    <!-- END:: ONE STARS -->

    <!-- START:: TWO STARS -->
    <span class="d-flex justify-content-center" v-if="rate == '2'">
      <i class="fas fa-star"></i>
      <i class="fas fa-star"></i>
      <i class="fal fa-star"></i>
      <i class="fal fa-star"></i>
      <i class="fal fa-star"></i>
    </span>
    <!-- END:: TWO STARS -->

    <!-- START:: THREE STARS -->
    <span class="d-flex justify-content-center" v-if="rate == '3'">
      <i class="fas fa-star"></i>
      <i class="fas fa-star"></i>
      <i class="fas fa-star"></i>
      <i class="fal fa-star"></i>
      <i class="fal fa-star"></i>
    </span>
    <!-- END:: THREE STARS -->

    <!-- START:: FOUR STARS -->
    <span class="d-flex justify-content-center" v-if="rate == '4'">
      <i class="fas fa-star"></i>
      <i class="fas fa-star"></i>
      <i class="fas fa-star"></i>
      <i class="fas fa-star"></i>
      <i class="fal fa-star"></i>
    </span>
    <!-- END:: FOUR STARS -->

    <!-- START:: FIVE STARS -->
    <span class="d-flex justify-content-center" v-if="rate == '5'">
      <i class="fas fa-star"></i>
      <i class="fas fa-star"></i>
      <i class="fas fa-star"></i>
      <i class="fas fa-star"></i>
      <i class="fas fa-star"></i>
    </span>
    <!-- END:: FIVE STARS -->
  </div>
</template>

<script>
export default {
  props: ["rate"],
};
</script>

<style lang="scss" scoped>
.rating {
  svg,
  i {
    margin-inline: 3px;
    color: #ffbe0f;
  }
}
</style>
