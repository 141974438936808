import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

// START:: IMPORT ROOT FILES
import rootMutations from "./rootMutations.js";
import rootActions from "./rootActions.js";
import rootGetters from "./rootGetters.js";
// END:: IMPORT ROOT FILES

// START:: IMPORTING STORE MODULES
import apisGetsModule from "./modules/apisGets/index";
import auth from "./modules/auth/index";
import branch from "./modules/branch/index";
// END:: IMPORTING STORE MODULES

export default new Vuex.Store({
  modules: {
    apisGetsModule,
    auth,
    branch,
  },

  state: {
    // START:: LANGUAGE DATA
    landing_language: localStorage.getItem("landing_language"),
    message: "",
    // END:: LANGUAGE DATA
  },

  mutations: rootMutations,
  actions: rootActions,
  getters: rootGetters,
});
