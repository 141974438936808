<template>
  <div class="reviews_cards_wrapper">
    <div class="row">
      <!-- START:: REVIEW CARD -->
      <div v-for="review in reviews" :key="review.id" class="col-lg-6 my-3">
        <div class="review_card">
          <div class="img_wraper">
            <img :src="review.image" width="100" height="100" />
          </div>

          <div class="review_body">
            <h5>{{ review.name }}</h5>
            <h6><RatingStars :rate="review.rate" /></h6>
            <div class="text">{{ review.comment }}</div>
          </div>
        </div>
      </div>
      <!-- END:: REVIEW CARD -->
    </div>
  </div>
</template>

<script>
// START:: IMPORTING COMPONENTS
import RatingStars from "../UI/RatingStars.vue";
// END:: IMPORTING COMPONENTS

export default {
  name: "ReviewsTab",

  components: {
    RatingStars,
  },

  data() {
    return {
      // START:: REVIEWS DATA
      reviews: [
        {
          id: 1,
          image:
            "https://d-themes.com/vue/riode/demo-1/images/blog/comments/1.jpg",
          name: "John Doe",
          rate: 4,
          comment:
            " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iusto nostrum eum alias ipsam ipsum error perferendis culpa. Consequatur dolorum sapiente quisquam ratione fuga vero numquam voluptatum error, exercitationem odit expedita.",
        },
        {
          id: 2,
          image:
            "https://d-themes.com/vue/riode/demo-1/images/blog/comments/1.jpg",
          name: "John Doe",
          rate: 1,
          comment:
            " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iusto nostrum eum alias ipsam ipsum error perferendis culpa. Consequatur dolorum sapiente quisquam ratione fuga vero numquam voluptatum error, exercitationem odit expedita.",
        },
        {
          id: 3,
          image:
            "https://d-themes.com/vue/riode/demo-1/images/blog/comments/1.jpg",
          name: "John Doe",
          rate: 5,
          comment:
            " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iusto nostrum eum alias ipsam ipsum error perferendis culpa. Consequatur dolorum sapiente quisquam ratione fuga vero numquam voluptatum error, exercitationem odit expedita.",
        },
        {
          id: 4,
          image:
            "https://d-themes.com/vue/riode/demo-1/images/blog/comments/1.jpg",
          name: "John Doe",
          rate: 2,
          comment:
            " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iusto nostrum eum alias ipsam ipsum error perferendis culpa. Consequatur dolorum sapiente quisquam ratione fuga vero numquam voluptatum error, exercitationem odit expedita.",
        },
      ],
      // END:: REVIEWS DATA
    };
  },
};
</script>
