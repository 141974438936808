var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"our_customers"},[_c('div',{staticClass:"title_wrapper"},[_c('h2',[_vm._v(_vm._s(_vm.$t("titles.our_customers")))])]),_c('div',{staticClass:"customers_carousel_wrapper",attrs:{"dir":_vm.getAppLocale == 'ar' ? 'rtl' : 'ltr'}},[_c('div',{staticClass:"container"},[_c('Carousel',{attrs:{"dir":"ltr","nav":false,"dots":false,"items":4,"margin":15,"loop":true,"autoplay":true,"autoplayTimeout":6000,"dragEndSpeed":2000,"smartSpeed":2000,"responsive":{
            0: {
              items: 2,
            },
            750: {
              items: 2,
            },
            900: {
              items: 4,
            },
          }}},[(_vm.customers.length > 0)?_vm._l((_vm.customers),function(customer){return _c('div',{key:customer.id,staticClass:"customer_logo_wrapper"},[_c('img',{attrs:{"src":customer.image,"alt":"Customer Name","width":"120","height":"120"}})])}):[_c('div',{staticClass:"customer_logo_wrapper"},[_c('img',{attrs:{"src":require("@/assets/media/imgs/logo1.png"),"alt":"Customer Name","width":"120","height":"120"}})]),_c('div',{staticClass:"customer_logo_wrapper"},[_c('img',{attrs:{"src":require("@/assets/media/imgs/logo2.png"),"alt":"Customer Name","width":"120","height":"120"}})]),_c('div',{staticClass:"customer_logo_wrapper"},[_c('img',{attrs:{"src":require("@/assets/media/imgs/logo3.png"),"alt":"Customer Name","width":"120","height":"120"}})]),_c('div',{staticClass:"customer_logo_wrapper"},[_c('img',{attrs:{"src":require("@/assets/media/imgs/logo4.png"),"alt":"Customer Name","width":"120","height":"120"}})])]],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }