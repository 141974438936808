<template>
  <div class="bestSellers my-5 container">
    <!-- Main Header -->
    <div class="header_wraper">
      <h2>{{ sliderData.title }}</h2>
      <span class="title_backdrop">{{ sliderData.title }}</span>
    </div>

    <!-- Products Slider -->
    <vue-slick v-bind="sliderData.settings || defaultSettings">
      <!-- Single Product -->
      <product-card
        :type="'best'"
        v-for="product in sliderData.slides"
        :key="product.id"
        :productData="product"
      />
    </vue-slick>
  </div>
</template>

<script>
import ProductCard from "../Products/ProductCard.vue";

export default {
  components: { ProductCard },

  props: ["sliderData"],

  data() {
    return {
      defaultSettings: {
        dots: true,
        arrows: false,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,

        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              dots: false,
              arrows: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
};
</script>
