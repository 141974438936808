<template>
    <div class="restaurant_cuisines_form_wrapper">
        <!-- START:: LOGO WRAPPER -->
        <router-link to="/" class="logo_wrapper">
            <img
                src="../../../assets/media/logo/logo.png"
                alt="Logo"
                width="120"
                height="80"
            />
        </router-link>
        <!-- END:: LOGO WRAPPER -->

        <!-- START:: TITLE -->
        <div class="title_wrapper">
            <h3>{{ $t("titles.new_restaurant_register") }}</h3>
            <h5>{{ $t("titles.cuisines") }}</h5>
        </div>
        <!-- END:: TITLE -->

        <!-- START:: TITLE NOTE -->
        <div class="title_note_wrapper">
            <h5>Note: If You Don't Serve Food, Please Select NO FOOD.</h5>
        </div>
        <!-- END:: TITLE NOTE -->

        <!-- START:: FORM -->
        <div class="form_wrapper">
            <form @submit.prevent="validateCuisineForm">
                <!-- START:: CUISINES INPUT -->
                <div class="input_group">
                    <label for="cuisines_select">
                        <img
                            src="../../../assets/media/icons/forkAndKnife.svg"
                            alt="icon"
                            width="25"
                            height="25"
                        />
                    </label>
                    <multiselect
                        id="cuisines_select"
                        v-model="restaurantCuisinesFormData.cuisines"
                        track-by="name"
                        label="name"
                        :placeholder="$t('placeholders.cuisine')"
                        :options="cuisinesList"
                        :searchable="true"
                        :allow-empty="true"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <!-- END:: CUISINES INPUT -->

                <!-- START:: TITLE -->
                <div class="title_wrapper my-5 py-5">
                    <h3>{{ $t("titles.additional_cuisines") }}</h3>
                    <h5>{{ $t("titles.additional_cuisines_subtitles") }}</h5>
                </div>
                <!-- END:: TITLE -->

                <!-- START:: CHECK BOXES WRAPPER -->
                <div class="row my-5">
                    <!-- START:: CHECKBOX INPUT GROUP -->
                    <div
                        class="col-6 col-lg-4 my-3"
                        v-for="additionalCuisine in additionalCuisinesList"
                        :key="additionalCuisine.id"
                    >
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                :value="additionalCuisine.id"
                                :id="'cuisine' + additionalCuisine.id"
                                v-model="
                                    restaurantCuisinesFormData.additionalCuisines
                                "
                                :disabled="
                                    restaurantCuisinesFormData
                                        .additionalCuisines.length >= 5
                                "
                            />
                            <label
                                class="form-check-label"
                                :for="'cuisine' + additionalCuisine.id"
                            >
                                {{ additionalCuisine.name }}
                            </label>
                        </div>
                    </div>
                    <!-- END:: CHECKBOX INPUT GROUP -->
                </div>
                <!-- END:: CHECK BOXES WRAPPER -->

                <!-- START:: SUBMIT BTN WRAPPER -->
                <div class="btn_wrapper">
                    <button class="main_btn">
                        {{ $t("buttons.continue") }}
                        <span class="btn_loader" v-if="isWaitingRequest"></span>
                    </button>
                </div>
                <!-- END:: SUBMIT BTN WRAPPER -->
            </form>
        </div>
        <!-- END:: FORM -->
    </div>
</template>

<script>
// START:: IMPORT VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORT VUEX HELPERS

export default {
    name: "RestaurantCuisines",

    data() {
        return {
            isWaitingRequest: false,

            restaurantCuisinesFormData: {
                cuisines: null,
                additionalCuisines: [],
            },
        };
    },

    computed: {
        ...mapGetters("apisGetsModule", [
            "cuisinesList",
            "additionalCuisinesList",
        ]),
    },

    methods: {
        // START:: VALIDATE SECOND STEP FORM
        validateCuisineForm() {
            this.isWaitingRequest = true;

            if (!this.restaurantCuisinesFormData.cuisines) {
                this.$message.error(
                    this.$t("validation.cuisines_select_validation")
                );
                this.isWaitingRequest = false;
                return;
            } else {
                this.submitCuisineForm();
            }
        },
        // END:: VALIDATE SECOND STEP FORM

        // START:: SUBMIT SECOND STEP FORM
        submitCuisineForm() {
            setTimeout(() => {
                this.isWaitingRequest = false;
                this.$router.push("/features-and-occasion");
            }, 2000);
        },
        // END:: SUBMIT SECOND STEP FORM
    },
};
</script>
