<template>
    <div class="phone_and_email_notifications_form_wrapper">
        <!-- START:: LOGO WRAPPER -->
        <router-link to="/" class="logo_wrapper">
            <img
                src="../../../assets/media/logo/logo.png"
                alt="Logo"
                width="120"
                height="80"
            />
        </router-link>
        <!-- END:: LOGO WRAPPER -->

        <!-- START:: TITLE -->
        <div class="title_wrapper">
            <h3>{{ $t("titles.new_restaurant_register") }}</h3>
            <h5>{{ $t("titles.phone_and_email_notifications") }}</h5>
        </div>
        <!-- END:: TITLE -->

        <!-- START:: TITLE NOTE -->
        <div class="title_note_wrapper">
            <p>
                When A New Online Booking Is Received, The System Could Send You
                An Email, Or A Text Message Notification. You Can Provide Two
                Emails And Two Mobile Numbers For New Reservations Alert.
            </p>
        </div>
        <!-- END:: TITLE NOTE -->

        <!-- START:: FORM -->
        <div class="form_wrapper">
            <form @submit.prevent="validatePhoneAndEmailNotificationsForm">
                <!-- START:: EMAIL NOTIFICATIONS INPUT -->
                <div class="input_group">
                    <label for="email_notifications">
                        <img
                            src="../../../assets/media/icons/at.svg"
                            alt="icon"
                            width="25"
                            height="25"
                        />
                    </label>
                    <multiselect
                        id="email_notifications"
                        v-model="wantEmailNotifications"
                        track-by="option"
                        label="option"
                        :placeholder="$t('placeholders.email_notifications')"
                        :options="emailNotificationsOptionsList"
                        :searchable="true"
                        :allow-empty="true"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>

                <transition mode="in-out" name="fade">
                    <div
                        class="wrapper mb-5"
                        v-if="
                            wantEmailNotifications.id ==
                            'activeEmailNotifications'
                        "
                    >
                        <div class="row">
                            <!-- START:: EMAIL INPUT -->
                            <div class="col-lg-8 mb-4">
                                <div class="input_group">
                                    <label
                                        class="options_label"
                                        for="first_email"
                                    >
                                        <span>
                                            {{ $t("placeholders.first_email") }}
                                        </span>
                                    </label>
                                    <input
                                        id="first_email"
                                        class="form-control custom_input"
                                        type="email"
                                        :placeholder="$t('placeholders.email')"
                                        v-model.trim="firstNotificationEmail"
                                    />
                                </div>
                            </div>
                            <!-- END:: EMAIL INPUT -->

                            <!-- START:: EMAIL INPUT -->
                            <div class="col-lg-8 mb-4">
                                <div class="input_group">
                                    <label
                                        class="options_label"
                                        for="second_email"
                                    >
                                        <span>
                                            {{
                                                $t("placeholders.second_email")
                                            }}
                                        </span>
                                    </label>
                                    <input
                                        id="second_email"
                                        class="form-control custom_input"
                                        type="email"
                                        :placeholder="$t('placeholders.email')"
                                        v-model.trim="secondNotificationEmail"
                                    />
                                </div>
                            </div>
                            <!-- END:: EMAIL INPUT -->
                        </div>
                    </div>
                </transition>
                <!-- END:: EMAIL NOTIFICATIONS INPUT -->

                <!-- START:: PHONE NOTIFICATIONS INPUT -->
                <div class="input_group">
                    <label for="phone_notifications">
                        <img
                            src="../../../assets/media/icons/chat.svg"
                            alt="icon"
                            width="25"
                            height="25"
                        />
                    </label>
                    <multiselect
                        id="phone_notifications"
                        v-model="wantPhoneNotifications"
                        track-by="option"
                        label="option"
                        :placeholder="$t('placeholders.phone_notifications')"
                        :options="phoneNotificationsOptionsList"
                        :searchable="true"
                        :allow-empty="true"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>

                <transition mode="in-out" name="fade">
                    <div
                        class="wrapper"
                        v-if="
                            wantPhoneNotifications.id ==
                            'activePhoneNotifications'
                        "
                    >
                        <div class="row">
                            <!-- START:: PHONE INPUT -->
                            <div class="col-lg-8 mb-4">
                                <div class="input_group">
                                    <label
                                        class="options_label"
                                        for="first_phone"
                                    >
                                        <span>
                                            {{ $t("placeholders.first_phone") }}
                                        </span>
                                    </label>
                                    <input
                                        id="first_phone"
                                        class="form-control custom_input"
                                        type="tel"
                                        :placeholder="$t('placeholders.phone')"
                                        v-model.trim="firstNotificationPhone"
                                    />
                                </div>
                            </div>
                            <!-- END:: PHONE INPUT -->

                            <!-- START:: PHONE INPUT -->
                            <div class="col-lg-8 mb-4">
                                <div class="input_group">
                                    <label
                                        class="options_label"
                                        for="second_phone"
                                    >
                                        <span>
                                            {{
                                                $t("placeholders.second_phone")
                                            }}
                                        </span>
                                    </label>
                                    <input
                                        id="second_phone"
                                        class="form-control custom_input"
                                        type="tel"
                                        :placeholder="$t('placeholders.phone')"
                                        v-model.trim="secondNotificationPhone"
                                    />
                                </div>
                            </div>
                            <!-- END:: PHONE INPUT -->
                        </div>
                    </div>
                </transition>
                <!-- END:: PHONE NOTIFICATIONS INPUT -->

                <!-- START:: SUBMIT BTN WRAPPER -->
                <div class="btn_wrapper mt-5">
                    <button class="main_btn">
                        {{ $t("buttons.continue") }}
                        <span class="btn_loader" v-if="isWaitingRequest"></span>
                    </button>
                </div>
                <!-- END:: SUBMIT BTN WRAPPER -->
            </form>
        </div>
        <!-- END:: FORM -->
    </div>
</template>

<script>
export default {
    name: "PhoneAndEmailNotifications",

    data() {
        return {
            isWaitingRequest: false,
            // START:: EMAIL NOTIFICATIONS DATA
            wantEmailNotifications: {
                id: this.$t("placeholders.email_notifications"),
                option: this.$t("placeholders.email_notifications"),
            },
            emailNotificationsOptionsList: [
                {
                    id: "disableEmailNotifications",
                    option: "No",
                },
                {
                    id: "activeEmailNotifications",
                    option: "Yes",
                },
            ],
            firstNotificationEmail: null,
            secondNotificationEmail: null,
            // END:: EMAIL NOTIFICATIONS DATA

            // START:: PHONE NOTIFICATIONS DATA
            wantPhoneNotifications: {
                id: this.$t("placeholders.phone_notifications"),
                option: this.$t("placeholders.phone_notifications"),
            },
            phoneNotificationsOptionsList: [
                {
                    id: "disablePhoneNotifications",
                    option: "No",
                },
                {
                    id: "activePhoneNotifications",
                    option: "Yes",
                },
            ],
            firstNotificationPhone: null,
            secondNotificationPhone: null,
            // END:: PHONE NOTIFICATIONS DATA
        };
    },

    methods: {
        // START:: VALIDATE PHONE AND EMAIL NOTIFICATIONS FORM
        validatePhoneAndEmailNotificationsForm() {
            this.isWaitingRequest = true;
            this.submitPhoneAndEmailNotificationsForm();
        },
        // END:: VALIDATE PHONE AND EMAIL NOTIFICATIONS FORM

        // START:: SUBMIT PHONE AND EMAIL NOTIFICATIONS FORM
        submitPhoneAndEmailNotificationsForm() {
            setTimeout(() => {
                this.isWaitingRequest = false;
                this.$router.push("/dashboard-preferences");
            }, 2000);
        },
        // END:: SUBMIT PHONE AND EMAIL NOTIFICATIONS FORM
    },
};
</script>
