<template>
  <div class="add_cat_modal_wrapper">
    <BaseModal :show="modalAppearanceData" @click="controlModalAppearance">
      <template #modal>
        <div class="add_cat_card">
          <div class="form_wrapper">
            <form @submit.prevent="validateAddMenuCat">
              <div class="row">
                <!-- START:: IMAGE INPUT -->
                <div class="col-12 my-3">
                  <div class="input_group avatar_input_wrapper">
                    <label for="avatar">
                      <img
                        src="../../assets/media/icons/camera.svg"
                        alt="icon"
                        width="120"
                        height="120"
                        v-if="!addItemImage.image.path"
                      />
                      <img
                        :src="addItemImage.image.path"
                        alt="avatar"
                        width="120"
                        height="120"
                        v-else
                      />
                    </label>
                    <input
                      id="avatar"
                      class="form-control custom_input"
                      type="file"
                      @change="selectUploadedImage"
                    />
                    <span> {{ $t("placeholders.image") }} </span>
                  </div>
                </div>
                <!-- END:: IMAGE INPUT -->

                <!-- START:: SUBMIT BTN WRAPPER -->
                <div class="btn_wrapper">
                  <button class="main_btn">
                    {{ $t("buttons.save") }}
                    <span class="btn_loader" v-if="isWaitingRequest"></span>
                  </button>
                </div>
                <!-- END:: SUBMIT BTN WRAPPER -->
              </div>
            </form>
          </div>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
// START:: IMPORTING BASE MODAL
import BaseModal from "../UI/BaseModal.vue";
// END:: IMPORTING BASE MODAL
import axios from "axios";

export default {
  name: "BranchImage",
  data() {
    return {
      // START:: BUTTON LOADER HANDLING DATA
      isWaitingRequest: false,
      // END:: BUTTON LOADER HANDLING DATA

      // START:: ADD ACCOUNT DATA
      addItemImage: {
        image: {
          path: null,
          file: null,
        },
        catArabicName: null,
        catEnglishName: null,
      },
      // END:: ADD ACCOUNT DATA
    };
  },

  components: {
    BaseModal,
  },
  props: ["modalAppearanceData"],

  emits: ["controlModalAppearance"],

  methods: {
    // START:: CONTROL MODAL APPEARANCE
    controlModalAppearance() {
      this.$emit("controlModalAppearance");
    },
    // END:: CONTROL MODAL APPEARANCE

    // START:: SELECT UPLOADED IMAGE
    selectUploadedImage(e) {
      this.addItemImage.image.path = URL.createObjectURL(e.target.files[0]);
      this.addItemImage.image.file = e.target.files[0];
    },
    // END:: SELECT UPLOADED IMAGE

    validateAddMenuCat() {
      this.submitAddMenuCatForm();
      if (!this.addItemImage.image.file) {
        this.$message.error(this.$t("validation.avatar_validation"));
        this.isWaitingRequest = false;
        return;
      }
    },

    async submitAddMenuCatForm() {
      try {
        this.isWaitingRequest = true;
        this.controlModalAppearance();
        let data = new FormData();
        data.append("file", this.addItemImage.image.file);
        data.append("place_id", this.$route.params.id);
        const response = await axios.post("image", data);
        location.reload();
        console.log(response);
        this.isWaitingRequest = false;
      } catch (error) {
        console.log(error, "------");
        this.isWaitingRequest = false;
        this.$message.error(error.response.data.message);
      }
    },
  },
};
</script>
