import router from "../../../router";
import * as types from "./types";
router;
export default {
  [types.LOGIN](state, payload) {
    localStorage.setItem("lime_token", payload.data.data.token);
    localStorage.setItem("lime_user", JSON.stringify(payload.data.data));
    state.user = payload.data.data;
    state.token = payload.data.data.token;
    router.push("/user-account");
    location.reload();
  },
  [types.UPDATE_USER](state, payload) {
    localStorage.setItem("lime_user", JSON.stringify(payload.data.data));
    state.user = payload.data.data;
  },
  [types.LOGOUT](state, payload) {
    localStorage.removeItem("lime_token");
    localStorage.removeItem("lime_user");
    state.user = null;
    state.token = null;
    state.message = payload.message;
    router.push("/");
    location.reload();
  },
  [types.UNAUTHORIZED](state, payload) {
    localStorage.removeItem("lime_token");
    localStorage.removeItem("lime_user");
    state.user = null;
    state.token = null;
    state.UserId = null;
    state.message = payload.message;
  },
};
