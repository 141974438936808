<template>
  <div class="branch_menu_tab_wrapper">
    <!-- START:: TITLE GROUP -->
    <div class="tab_title_group">
      <!-- START:: TITLE -->
      <h3>{{ $t("titles.menu") }}</h3>
      <!-- END:: TITLE -->

      <!-- START:: ACTIONS GROUP -->
      <div class="actions_group">
        <!-- START:: ADD MENU CATEGORY  BUTTON -->
        <div class="btn_wrapper">
          <button @click="toggleAddMenuCatModal">
            {{ $t("buttons.add_category") }}
          </button>
        </div>
        <!-- END:: ADD MENU CATEGORY  BUTTON -->

        <!-- START:: SWITCH GROUP -->
        <div class="switch_group">
          <label class="form-check-label" for="menu_view">Book View</label>

          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              id="menu_view"
              value="true"
              v-model="menuView"
            />
          </div>

          <label class="form-check-label" for="menu_view">Cards View</label>
        </div>
        <!-- END:: SWITCH GROUP -->
      </div>
      <!-- END:: ACTIONS GROUP -->
    </div>
    <!-- END:: TITLE GROUP -->

    <!-- START:: MENU CARDS VIEW -->
    <div class="view_wrapper" v-if="menuView == true">
      <ImageTabs :tabsContent="menuCategoriesTabsContent">
        <template
          v-for="category in menuCategoriesTabsContent"
          v-slot:[category.key_name]
        >
          <div :key="category.id">
            <h3>{{ category.name }}</h3>

            <div class="row">
              <!-- START:: ADD ITE CARD -->
              <div class="col-lg-6 my-3">
                <button
                  class="add_menu_item_card"
                  @click="toggleAddMenuItemSideDrawer"
                >
                  <i class="fal fa-plus"></i>
                </button>
              </div>
              <!-- END:: ADD ITE CARD -->

              <!-- START:: MENU ITEM CARD -->
              <div
                v-for="item in category.items"
                :key="item.id"
                class="col-lg-6 my-3"
              >
                <div class="menu_item_card">
                  <div class="item_details_wrapper">
                    <div class="img_wrapper">
                      <img
                        :src="item.image"
                        :alt="item.name"
                        width="170"
                        height="170"
                      />
                    </div>

                    <div class="wrapper">
                      <h4 class="item_name">{{ item.name }}</h4>
                      <div class="item_desc">
                        {{ item.desc }}
                      </div>

                      <div class="item_price">80 R.S</div>

                      <div class="item_spicy_scale">
                        <SpicyScale
                          :scale="item.spicy_scale"
                          v-if="item.mode == 'spicy'"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="item_extra_details_wrapper">
                    <div class="row">
                      <!-- START:: EXTRA DETAILS  GROUP -->
                      <div class="col-lg-6 my-2">
                        <div class="extra_details_group">
                          <h4 class="group_title">Mode</h4>
                          <div class="extra_options_select_wrapper">
                            <!-- START:: OPTION -->
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                :name="`mode-${item.id}`"
                                :id="`spicy-${item.id}`"
                                value="spicy"
                                v-model="item.mode"
                                disabled
                              />
                              <label
                                class="form-check-label"
                                :for="`spicy-${item.id}`"
                              >
                                <i
                                  class="fas fa-pepper-hot"
                                  :class="item.mode == 'spicy' ? 'spicy' : ''"
                                ></i>
                              </label>
                            </div>
                            <!-- END:: OPTION -->

                            <!-- START:: OPTION -->
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                :name="`mode-${item.id}`"
                                :id="`cool-${item.id}`"
                                value="cool"
                                v-model="item.mode"
                                disabled
                              />
                              <label
                                class="form-check-label"
                                :for="`cool-${item.id}`"
                              >
                                <i
                                  class="fas fa-fan"
                                  :class="item.mode == 'cool' ? 'cool' : ''"
                                ></i>
                              </label>
                            </div>
                            <!-- END:: OPTION -->
                          </div>
                        </div>
                      </div>
                      <!-- END:: EXTRA DETAILS  GROUP -->

                      <!-- START:: EXTRA DETAILS  GROUP -->
                      <div class="col-lg-6 my-2">
                        <div class="extra_details_group">
                          <h4 class="group_title">Sizes</h4>
                          <div class="extra_options_select_wrapper">
                            <!-- START:: OPTION -->
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                :name="`sizes-${item.id}`"
                                :id="`small-${item.id}`"
                              />
                              <label
                                class="form-check-label"
                                :for="`small-${item.id}`"
                              >
                                S
                              </label>
                            </div>
                            <!-- END:: OPTION -->

                            <!-- START:: OPTION -->
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                :name="`sizes-${item.id}`"
                                :id="`medium-${item.id}`"
                              />
                              <label
                                class="form-check-label"
                                :for="`medium-${item.id}`"
                              >
                                M
                              </label>
                            </div>
                            <!-- END:: OPTION -->

                            <!-- START:: OPTION -->
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                :name="`sizes-${item.id}`"
                                :id="`Large-${item.id}`"
                              />
                              <label
                                class="form-check-label"
                                :for="`Large-${item.id}`"
                              >
                                L
                              </label>
                            </div>
                            <!-- END:: OPTION -->
                          </div>
                        </div>
                      </div>
                      <!-- END:: EXTRA DETAILS  GROUP -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- END:: MENU ITEM CARD -->
            </div>
          </div>
        </template>
      </ImageTabs>
    </div>
    <!-- END:: MENU CARDS VIEW -->

    <!-- START:: MENU BOOK VIEW -->
    <div
      class="view_wrapper d-flex justify-content-center align-items-center"
      v-else
    >
      <div class="book">
        <!-- START:: CHECKBOXES -->
        <input type="checkbox" id="c_1" />
        <input type="checkbox" id="c_2" />
        <input type="checkbox" id="c_3" />
        <input type="checkbox" id="c_4" />
        <!-- END:: CHECKBOXES -->

        <!-- START:: BOOK COVER -->
        <div id="cover">
          <img src="../../assets/media/imgs/menu.jpg" />
        </div>
        <!-- END:: BOOK COVER -->

        <div class="flip_book">
          <!-- START:: BOOK PAGE -->
          <div id="p_1" class="flip">
            <div class="front">
              <div class="menu_page_item_wrapper">
                <h4>Content 1</h4>
                <ul class="menu_items_list">
                  <li class="menu_item">
                    <span class="item_name"> Item Name 1 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 2 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 3 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 4 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 5 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 6 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 7 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 8 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 9 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 10 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 11 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                </ul>
              </div>
              <label for="c_1" class="next_btn"> Next </label>
            </div>

            <div class="back">
              <div class="menu_page_item_wrapper">
                <h4>Content 2</h4>
                <ul class="menu_items_list">
                  <li class="menu_item">
                    <span class="item_name"> Item Name 1 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 2 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 3 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 4 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 5 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 6 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 7 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 8 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 9 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 10 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 11 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                </ul>
              </div>
              <label for="c_1" class="back_btn"> Back </label>
            </div>
          </div>
          <!-- END:: BOOK PAGE -->

          <!-- START:: BOOK PAGE -->
          <div id="p_2" class="flip">
            <div class="front">
              <div class="menu_page_item_wrapper">
                <h4>Content 3</h4>
                <ul class="menu_items_list">
                  <li class="menu_item">
                    <span class="item_name"> Item Name 1 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 2 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 3 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 4 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 5 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 6 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 7 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 8 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 9 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 10 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 11 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                </ul>
              </div>
              <label for="c_2" class="next_btn"> Next </label>
            </div>

            <div class="back">
              <div class="menu_page_item_wrapper">
                <h4>Content 4</h4>
                <ul class="menu_items_list">
                  <li class="menu_item">
                    <span class="item_name"> Item Name 1 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 2 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 3 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 4 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 5 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 6 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 7 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 8 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 9 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 10 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 11 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                </ul>
              </div>
              <label for="c_2" class="back_btn"> Back </label>
            </div>
          </div>
          <!-- END:: BOOK PAGE -->

          <!-- START:: BOOK PAGE -->
          <div id="p_3" class="flip">
            <div class="front">
              <div class="menu_page_item_wrapper">
                <h4>Content 5</h4>
                <ul class="menu_items_list">
                  <li class="menu_item">
                    <span class="item_name"> Item Name 1 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 2 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 3 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 4 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 5 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 6 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 7 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 8 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 9 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 10 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 11 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                </ul>
              </div>
              <label for="c_3" class="next_btn"> Next </label>
            </div>

            <div class="back">
              <div class="menu_page_item_wrapper">
                <h4>Content 6</h4>
                <ul class="menu_items_list">
                  <li class="menu_item">
                    <span class="item_name"> Item Name 1 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 2 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 3 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 4 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 5 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 6 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 7 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 8 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 9 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 10 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 11 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                </ul>
              </div>
              <label for="c_3" class="back_btn"> Back </label>
            </div>
          </div>
          <!-- END:: BOOK PAGE -->

          <!-- START:: BOOK PAGE -->
          <div id="p_4" class="flip">
            <div class="front">
              <div class="menu_page_item_wrapper">
                <h4>Content 7</h4>
                <ul class="menu_items_list">
                  <li class="menu_item">
                    <span class="item_name"> Item Name 1 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 2 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 3 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 4 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 5 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 6 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 7 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 8 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 9 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 10 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                  <li class="menu_item">
                    <span class="item_name"> Item Name 11 </span>
                    <span class="item_price"> 15 R.S </span>
                  </li>
                </ul>
              </div>
              <label for="c_4" class="next_btn"> Next </label>
            </div>

            <div class="back">
              <img src="../../assets/media/imgs/menu.jpg" />
              <label for="c_4" class="back_btn"> Back </label>
            </div>
          </div>
          <!-- END:: BOOK PAGE -->
        </div>
      </div>
    </div>
    <!-- END:: MENU BOOK VIEW -->

    <!-- START:: ADD MENU ITEM SIDE DRAWER -->
    <div
      class="add_menu_item_side_drawer_wrapper"
      :class="{ active: addMenuItemSideDrawerIsOpen }"
    >
      <!-- START:: CLOSE BTN -->
      <div class="btn_wrapper">
        <button class="close_drawer_btn" @click="toggleAddMenuItemSideDrawer">
          <i class="fal fa-times"></i>
        </button>
      </div>
      <!-- END:: CLOSE BTN -->

      <!-- START:: TITLE -->
      <h3 class="drawer_title">{{ $t("titles.add_new_item") }}</h3>
      <!-- END:: TITLE -->

      <!-- START:: ADD ITEM FORM -->
      <div class="form_wrapper">
        <form>
          <div class="container">
            <div class="row">
              <!-- START:: AVATAR INPUT -->
              <div class="col-12 my-3">
                <div class="input_group avatar_input_wrapper">
                  <label for="avatar">
                    <img
                      src="../../assets/media/icons/camera.svg"
                      alt="icon"
                      width="120"
                      height="120"
                      v-if="!addItemData.image.path"
                    />
                    <img
                      :src="addItemData.image.path"
                      alt="avatar"
                      width="120"
                      height="120"
                      v-else
                    />
                  </label>
                  <input
                    id="avatar"
                    class="form-control custom_input"
                    type="file"
                    @change="selectUploadedImage"
                  />
                  <span> {{ $t("placeholders.item_image") }} </span>
                </div>
              </div>
              <!-- END:: AVATAR INPUT -->

              <!-- START:: ENGLISH ITEM NAME -->
              <div class="col-lg-6 my-3">
                <div class="input_group">
                  <input
                    class="form-control custom_input"
                    type="text"
                    :placeholder="$t('placeholders.item_english_name')"
                    v-model.trim="addItemData.itemEnglishName"
                  />
                </div>
              </div>
              <!-- END:: ENGLISH ITEM NAME -->

              <!-- START:: ARABIC ITEM NAME -->
              <div class="col-lg-6 my-3">
                <div class="input_group">
                  <input
                    class="form-control custom_input"
                    type="text"
                    :placeholder="$t('placeholders.item_arabic_name')"
                    v-model.trim="addItemData.itemArabicName"
                  />
                </div>
              </div>
              <!-- END:: ARABIC ITEM NAME -->

              <!-- START:: INGREDIENTS -->
              <div class="wrapper">
                <span class="form_category_title">
                  {{ $t("titles.ingredients") }}
                </span>
                <!-- START:: INGREDIENTS ROW-->
                <div
                  v-for="ingredient in addItemData.ingredients"
                  :key="ingredient.id"
                  class="row my-3 fadeIn"
                >
                  <!-- START:: INGREDIENT ENGLISH NAME -->
                  <div class="col-lg-5">
                    <div class="input_group">
                      <input
                        class="form-control custom_input"
                        type="text"
                        :placeholder="
                          $t('placeholders.ingredient_english_name')
                        "
                        v-model.trim="ingredient.englishName"
                      />
                    </div>
                  </div>
                  <!-- END:: INGREDIENT ENGLISH NAME -->

                  <!-- START:: INGREDIENT ARABIC NAME -->
                  <div class="col-lg-5">
                    <div class="input_group">
                      <input
                        class="form-control custom_input"
                        type="text"
                        :placeholder="$t('placeholders.ingredient_arabic_name')"
                        v-model.trim="ingredient.arabicName"
                      />
                    </div>
                  </div>
                  <!-- END:: INGREDIENT ARABIC NAME -->

                  <!-- START:: DELETE INGREDIENT ROW BUTTON -->
                  <div class="col-1 text-center">
                    <button
                      type="button"
                      class="delete_ingredient_btn"
                      @click="deleteIngredient(ingredient.id)"
                    >
                      <i class="fal fa-trash-alt fa-lg"></i>
                    </button>
                  </div>
                  <!-- END:: DELETE INGREDIENT ROW BUTTON -->
                </div>
                <!-- END:: INGREDIENTS ROW-->

                <!-- START:: ADD INGREDIENT BUTTON -->
                <div class="btn_wrapper">
                  <button type="button" @click="addIngredient">
                    <i class="fal fa-plus mx-2"></i>
                    {{ $t("buttons.add_ingredient") }}
                  </button>
                </div>
                <!-- END:: ADD INGREDIENT BUTTON -->
              </div>
              <!-- END:: INGREDIENTS -->

              <!-- START:: SIZES -->
              <div class="wrapper">
                <span class="form_category_title">
                  {{ $t("titles.sizes") }}
                </span>
                <!-- START:: SIZES ROW-->
                <div
                  v-for="size in addItemData.sizes"
                  :key="size.id"
                  class="row my-3 fadeIn"
                >
                  <!-- START:: SIZE ENGLISH NAME -->
                  <div class="col-lg-4">
                    <div class="input_group">
                      <input
                        class="form-control custom_input"
                        type="text"
                        :placeholder="$t('placeholders.size_in_english')"
                        v-model.trim="size.sizeInEnglish"
                      />
                    </div>
                  </div>
                  <!-- END:: SIZE ENGLISH NAME -->

                  <!-- START:: SIZE ARABIC NAME -->
                  <div class="col-lg-4">
                    <div class="input_group">
                      <input
                        class="form-control custom_input"
                        type="text"
                        :placeholder="$t('placeholders.size_in_arabic')"
                        v-model.trim="size.sizeInArabic"
                      />
                    </div>
                  </div>
                  <!-- END:: SIZE ARABIC NAME -->

                  <!-- START:: SIZE PRICE -->
                  <div class="col-lg-3">
                    <div class="input_group">
                      <input
                        class="form-control custom_input"
                        type="number"
                        :placeholder="$t('placeholders.price')"
                        v-model.trim="size.price"
                      />
                    </div>
                  </div>
                  <!-- END:: SIZE PRICE -->

                  <!-- START:: DELETE SIZE ROW BUTTON -->
                  <div class="col-1 text-center">
                    <button
                      type="button"
                      class="delete_size_btn"
                      @click="deleteSize(size.id)"
                    >
                      <i class="fal fa-trash-alt fa-lg"></i>
                    </button>
                  </div>
                  <!-- END:: DELETE INGREDIENT ROW BUTTON -->
                </div>
                <!-- END:: SIZES ROW-->

                <!-- START:: ADD SIZE BUTTON -->
                <div class="btn_wrapper">
                  <button type="button" @click="addSize">
                    <i class="fal fa-plus mx-2"></i>
                    {{ $t("buttons.add_size") }}
                  </button>
                </div>
                <!-- END:: ADD SIZE BUTTON -->
              </div>
              <!-- END:: SIZES -->

              <!-- START:: ADDITIONS -->
              <div class="wrapper mb-4">
                <span class="form_category_title">
                  {{ $t("titles.additions") }}
                </span>
                <!-- START:: ADDITIONS ROW-->
                <div
                  v-for="addition in addItemData.additions"
                  :key="addition.id"
                  class="row my-3 fadeIn"
                >
                  <!-- START:: ADDITION ENGLISH NAME -->
                  <div class="col-lg-4">
                    <div class="input_group">
                      <input
                        class="form-control custom_input"
                        type="text"
                        :placeholder="$t('placeholders.addition_in_english')"
                        v-model.trim="addition.additionInEnglish"
                      />
                    </div>
                  </div>
                  <!-- END:: ADDITION ENGLISH NAME -->

                  <!-- START:: ADDITION ARABIC NAME -->
                  <div class="col-lg-4">
                    <div class="input_group">
                      <input
                        class="form-control custom_input"
                        type="text"
                        :placeholder="$t('placeholders.addition_in_arabic')"
                        v-model.trim="addition.additionInArabic"
                      />
                    </div>
                  </div>
                  <!-- END:: ADDITION ARABIC NAME -->

                  <!-- START:: ADDITION PRICE -->
                  <div class="col-lg-3">
                    <div class="input_group">
                      <input
                        class="form-control custom_input"
                        type="number"
                        :placeholder="$t('placeholders.price')"
                        v-model.trim="addition.price"
                      />
                    </div>
                  </div>
                  <!-- END:: ADDITION PRICE -->

                  <!-- START:: DELETE ADDITION ROW BUTTON -->
                  <div class="col-1 text-center">
                    <button
                      type="button"
                      class="delete_size_btn"
                      @click="deleteAddition(addition.id)"
                    >
                      <i class="fal fa-trash-alt fa-lg"></i>
                    </button>
                  </div>
                  <!-- END:: DELETE ADDITION ROW BUTTON -->
                </div>
                <!-- END:: ADDITIONS ROW-->

                <!-- START:: ADD ADDITION BUTTON -->
                <div class="btn_wrapper">
                  <button type="button" @click="addAddition">
                    <i class="fal fa-plus mx-2"></i>
                    {{ $t("buttons.add_addition") }}
                  </button>
                </div>
                <!-- END:: ADD ADDITIONS BUTTON -->
              </div>
              <!-- END:: ADDITIONS -->

              <!-- START:: CALORIES INPUT -->
              <div class="col-lg-6 my-3">
                <div class="input_group">
                  <input
                    class="form-control custom_input"
                    type="number"
                    :placeholder="$t('placeholders.item_calories')"
                    v-model.trim="addItemData.calories"
                  />
                </div>
              </div>
              <!-- END:: CALORIES INPUT -->

              <!-- START:: SUITABLE FOR VEGETARIANS INPUT -->
              <div class="col-6 my-3">
                <div class="input_group">
                  <multiselect
                    v-model="addItemData.isSuitableForVegetarians"
                    track-by="option"
                    label="option"
                    :placeholder="
                      $t('placeholders.is_suitable_for_vegetarians')
                    "
                    :options="isSuitableForVegetariansOptions"
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- END:: SUITABLE FOR VEGETARIANS INPUT -->

              <!-- START:: IS GLUTEN FREE INPUT-->
              <div class="col-6 my-3">
                <div class="input_group">
                  <multiselect
                    v-model="addItemData.isGlutenFree"
                    track-by="option"
                    label="option"
                    :placeholder="$t('placeholders.is_gluten_free')"
                    :options="isGlutenFreeOptions"
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- END:: IS GLUTEN FREE INPUT-->

              <!-- START:: AVAILABLE ALL TIMES INPUT-->
              <div class="col-6 my-3">
                <div class="input_group">
                  <multiselect
                    v-model="addItemData.isAvailableAllTimes"
                    track-by="option"
                    label="option"
                    :placeholder="$t('placeholders.is_available_all_times')"
                    :options="isAvailableAllTimesOptions"
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- END:: AVAILABLE ALL TIMES INPUT-->

              <!-- START:: ENGLISH ITEM DESCRIPTION -->
              <div class="col-12">
                <image-rating
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7L0KI6Dz5-LTwFMO3SgMzHPe3I6E8oEFGsw&usqp=CAU"
                  v-model="addItemData.spicyRate"
                ></image-rating>
              </div>
              <!-- END:: ENGLISH ITEM DESCRIPTION -->

              <!-- START:: ENGLISH ITEM DESCRIPTION -->
              <div class="col-6 my-3">
                <div class="input_group">
                  <textarea
                    class="form-control"
                    :placeholder="$t('placeholders.english_desc')"
                    rows="7"
                    v-model="addItemData.englishDesc"
                  ></textarea>
                </div>
              </div>
              <!-- END:: ENGLISH ITEM DESCRIPTION -->

              <!-- START:: ARABIC ITEM DESCRIPTION -->
              <div class="col-6 my-3">
                <div class="input_group">
                  <textarea
                    class="form-control"
                    :placeholder="$t('placeholders.arabic_desc')"
                    rows="7"
                    v-model="addItemData.arabicDesc"
                  ></textarea>
                </div>
              </div>
              <!-- END:: ARABIC ITEM DESCRIPTION -->

              <!-- START:: SUBMIT BTN WRAPPER -->
              <div class="btn_wrapper">
                <button class="main_btn">
                  {{ $t("buttons.save") }}
                  <span class="btn_loader" v-if="isWaitingRequest"></span>
                </button>
              </div>
              <!-- END:: SUBMIT BTN WRAPPER -->
            </div>
          </div>
        </form>
      </div>
      <!-- END:: ADD ITEM FORM -->
    </div>
    <!-- END:: ADD MENU ITEM SIDE DRAWER -->

    <!-- START:: ADD CAT MODAL -->
    <AddMenuCatModal
      :modalAppearanceData="addMenuCatModalIsOpen"
      @controlModalAppearance="toggleAddMenuCatModal"
    />
    <!-- END:: ADD CAT MODAL -->
  </div>
</template>

<script>
// START:: IMPORTING CUSTOM COMPS
import ImageTabs from "../Global/Tabs.vue";
// END:: IMPORTING TABS COMP

// START:: IMPORTING SPICY SCALE
import SpicyScale from "../UI/SpicyScale.vue";
// END:: IMPORTING SPICY SCALE

// START:: IMPORTING ADD CAT MODAL
import AddMenuCatModal from "../modals/AddMenuCategoryModal.vue";
// END:: IMPORTING ADD CAT MODAL

import cat1 from "../../assets/media/imgs/menu/cat1.png";
import cat2 from "../../assets/media/imgs/menu/cat2.png";
import cat3 from "../../assets/media/imgs/menu/cat3.png";
import cat4 from "../../assets/media/imgs/menu/cat4.png";
import cat5 from "../../assets/media/imgs/menu/cat5.png";

export default {
  name: "MenuTab",

  components: {
    ImageTabs,
    SpicyScale,
    AddMenuCatModal,
  },

  data() {
    return {
      // START:: BUTTON LOADER HANDLING DATA
      isWaitingRequest: false,
      // END:: BUTTON LOADER HANDLING DATA

      // START:: MENU VIEW HANDLING DATA
      menuView: true,
      // END:: MENU VIEW HANDLING DATA

      // START:: ADD MENU ITEM DRAWER VISIBILITY
      addMenuItemSideDrawerIsOpen: false,
      // END:: ADD MENU ITEM DRAWER VISIBILITY

      // START:: MODALS HANDLING DATA
      addMenuCatModalIsOpen: false,
      // END:: MODALS HANDLING DATA

      menuCategoriesTabsContent: [
        {
          id: 1,
          tabImage: cat1,
          name: "Pasta Menu",
          key_name: "Pasta",
          items: [
            {
              id: "pasta-1",
              image: cat1,
              name: "Pasta Item 1",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "spicy",
              spicy_scale: 3,
            },
            {
              id: "pasta-2",
              image: cat1,
              name: "Pasta Item 2",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "spicy",
              spicy_scale: 5,
            },
            {
              id: "pasta-3",
              image: cat1,
              name: "Pasta Item 3",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "cool",
              spicy_scale: 0,
            },
            {
              id: "pasta-4",
              image: cat1,
              name: "Pasta Item 4",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "spicy",
              spicy_scale: 2,
            },
          ],
        },
        {
          id: 2,
          tabImage: cat2,
          name: "Donuts Menu",
          key_name: "Donuts",
          items: [
            {
              id: 1,
              image: cat2,
              name: "Donuts Item 1",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "spicy",
              spicy_scale: 3,
            },
            {
              id: 2,
              image: cat2,
              name: "Donuts Item 2",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "spicy",
              spicy_scale: 5,
            },
            {
              id: 3,
              image: cat2,
              name: "Donuts Item 3",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "cool",
              spicy_scale: 0,
            },
            {
              id: 4,
              image: cat2,
              name: "Donuts Item 4",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "spicy",
              spicy_scale: 2,
            },
          ],
        },
        {
          id: 3,
          tabImage: cat3,
          name: "Salads Menu",
          key_name: "Salads",
          items: [
            {
              id: 1,
              image: cat3,
              name: "Salad Item 1",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "spicy",
              spicy_scale: 3,
            },
            {
              id: 2,
              image: cat3,
              name: "Salad Item 2",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "spicy",
              spicy_scale: 5,
            },
            {
              id: 3,
              image: cat3,
              name: "Salad Item 3",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "cool",
              spicy_scale: 0,
            },
            {
              id: 4,
              image: cat3,
              name: "Salad Item 4",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "spicy",
              spicy_scale: 2,
            },
          ],
        },
        {
          id: 4,
          tabImage: cat4,
          name: "Burger Menu",
          key_name: "Burger",
          items: [
            {
              id: 1,
              image: cat4,
              name: "Burger Item 1",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "spicy",
              spicy_scale: 3,
            },
            {
              id: 2,
              image: cat4,
              name: "Burger Item 2",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "spicy",
              spicy_scale: 5,
            },
            {
              id: 3,
              image: cat4,
              name: "Burger Item 3",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "cool",
              spicy_scale: 0,
            },
            {
              id: 4,
              image: cat4,
              name: "Burger Item 4",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "spicy",
              spicy_scale: 2,
            },
          ],
        },
        {
          id: 5,
          tabImage: cat5,
          name: "Coffee Menu",
          key_name: "Coffee",
          items: [
            {
              id: 1,
              image: cat5,
              name: "Coffee Item 1",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "spicy",
              spicy_scale: 3,
            },
            {
              id: 2,
              image: cat5,
              name: "Coffee Item 2",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "spicy",
              spicy_scale: 5,
            },
            {
              id: 3,
              image: cat5,
              name: "Coffee Item 3",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "cool",
              spicy_scale: 0,
            },
            {
              id: 4,
              image: cat5,
              name: "Coffee Item 4",
              desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente earum.",
              sizes: [
                {
                  id: 1,
                  size: "S",
                  price: 6,
                },
                {
                  id: 2,
                  size: "M",
                  price: 8,
                },
                {
                  id: 3,
                  size: "L",
                  price: 10,
                },
              ],
              mode: "spicy",
              spicy_scale: 2,
            },
          ],
        },
      ],

      // START:: SELECT INPUTS OPTIONS DATA
      isAvailableAllTimesOptions: [
        {
          id: 1,
          option: "No",
        },
        {
          id: 2,
          option: "Yes",
        },
      ],
      isGlutenFreeOptions: [
        {
          id: 1,
          option: "No",
        },
        {
          id: 2,
          option: "Yes",
        },
      ],
      isSuitableForVegetariansOptions: [
        {
          id: 1,
          option: "No",
        },
        {
          id: 2,
          option: "Yes",
        },
      ],
      // END:: SELECT INPUTS OPTIONS DATA

      // START:: ADD MENU ITEM
      addItemData: {
        image: {
          path: null,
          file: null,
        },
        itemArabicName: null,
        itemEnglishName: null,
        ingredients: [],
        sizes: [],
        additions: [],
        calories: null,
        isSuitableForVegetarians: null,
        isGlutenFree: null,
        isAvailableAllTimes: null,
        spicyRate: 0,
        arabicDesc: null,
        englishDesc: null,
      },
      // END:: ADD MENU ITEM
    };
  },

  methods: {
    // START:: TOGGLE ADD MENU ITEM DRAWER
    toggleAddMenuItemSideDrawer() {
      this.addMenuItemSideDrawerIsOpen = !this.addMenuItemSideDrawerIsOpen;
    },
    // END:: TOGGLE ADD MENU ITEM DRAWER

    // START:: TOGGLE SUCCESS MODAL METHOD
    toggleAddMenuCatModal() {
      this.addMenuCatModalIsOpen = !this.addMenuCatModalIsOpen;
    },
    // END:: TOGGLE SUCCESS MODAL METHOD

    // START:: SELECT UPLOADED IMAGE
    selectUploadedImage(e) {
      this.addItemData.image.path = URL.createObjectURL(e.target.files[0]);
      this.addItemData.image.file = e.target.files[0];
    },
    // END:: SELECT UPLOADED IMAGE

    // START:: ADD INGREDIENT
    addIngredient() {
      this.addItemData.ingredients.push({
        id: Date.now(),
        arabicName: null,
        englishName: null,
      });
    },
    // END:: ADD INGREDIENT

    // START:: DELETE INGREDIENT
    deleteIngredient(id) {
      let targetElement = this.addItemData.ingredients.find(
        (element) => element.id == id
      );
      let targetElementIndex =
        this.addItemData.ingredients.indexOf(targetElement);
      this.addItemData.ingredients.splice(targetElementIndex, 1);
    },
    // END:: DELETE INGREDIENT

    // START:: ADD SIZE
    addSize() {
      this.addItemData.sizes.push({
        id: Date.now(),
        sizeInArabic: null,
        sizeInEnglish: null,
        price: null,
      });
    },
    // END:: ADD SIZE

    // START:: DELETE SIZE
    deleteSize(id) {
      let targetElement = this.addItemData.sizes.find(
        (element) => element.id == id
      );
      let targetElementIndex = this.addItemData.sizes.indexOf(targetElement);
      this.addItemData.sizes.splice(targetElementIndex, 1);
    },
    // END:: DELETE SIZE

    // START:: ADD ADDITION
    addAddition() {
      this.addItemData.additions.push({
        id: Date.now(),
        additionInArabic: null,
        additionInEnglish: null,
        price: null,
      });
    },
    // END:: ADD ADDITION

    // START:: DELETE ADDITION
    deleteAddition(id) {
      let targetElement = this.addItemData.additions.find(
        (element) => element.id == id
      );
      let targetElementIndex =
        this.addItemData.additions.indexOf(targetElement);
      this.addItemData.additions.splice(targetElementIndex, 1);
    },
    // END:: DELETE ADDITION
  },

  created() {},
};
</script>
