<template>
  <div class="booking_system_form_wrapper">
    <!-- START:: TITLE -->
    <div class="title_wrapper mb-3">
      <h3>{{ $t("titles.dashboard_settings") }}</h3>
    </div>
    <!-- END:: TITLE -->

    <!-- START:: FORM -->
    <div class="form_wrapper">
      <form @submit.prevent="validateDashboardSettingsForm">
        <!-- START:: DASHBOARD VIEW INPUT -->
        <div class="inner_wrapper">
          <div class="input_group">
            <label for="dashboard_view_select">
              <img
                src="../../assets/media/icons/dashboard.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <multiselect
              id="dashboard_view_select"
              v-model="dashboardSettingsFormData.dashboardView"
              track-by="name"
              label="name"
              :placeholder="$t('placeholders.dashboard_view')"
              :options="dashboardViewsList"
              :searchable="true"
              :allow-empty="true"
              :show-labels="false"
            >
            </multiselect>
          </div>

          <div class="input_caption">
            You Can Switch Between Dashboard Views Anytime!
          </div>
        </div>
        <!-- END:: DASHBOARD VIEW INPUT -->

        <!-- START:: TIME FORMAT INPUT -->
        <div class="inner_wrapper">
          <div class="input_group">
            <label for="time_format_select">
              <img
                src="../../assets/media/icons/clock.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <multiselect
              id="time_format_select"
              v-model="dashboardSettingsFormData.timeFormat"
              track-by="format"
              label="format"
              :placeholder="$t('placeholders.time_format')"
              :options="timeFormatList"
              :searchable="true"
              :allow-empty="true"
              :show-labels="false"
            >
            </multiselect>
          </div>

          <div class="input_caption">
            Time Format: AM/PM = 6:00PM, 24H = 18:00
          </div>
        </div>
        <!-- END:: TIME FORMAT INPUT -->

        <!-- START:: SHOW END TIME INPUT -->
        <div class="inner_wrapper">
          <div class="input_group">
            <label for="show_end_time">
              <img
                src="../../assets/media/icons/clock.svg"
                alt="icon"
                width="25"
                height="25"
              />
            </label>
            <multiselect
              id="show_end_time"
              v-model="dashboardSettingsFormData.showEndTime"
              track-by="option"
              label="option"
              :placeholder="$t('placeholders.show_end_time')"
              :options="showEndTimeOptionsList"
              :searchable="true"
              :allow-empty="true"
              :show-labels="false"
            >
            </multiselect>
          </div>

          <div class="input_caption">
            Reservation End Time Will Be Displayed In Dashboard Next To The
            Reservation Time. Example: 6PM (18:00) Reservation Time With 90
            Minutes (1:30) EDT Will Be Displayed As 6Pm - 7:30PM (18:00 -
            19:30).
          </div>
        </div>
        <!-- END:: SHOW END TIME INPUT -->

        <!-- START:: SUBMIT BTN WRAPPER -->
        <div class="btn_wrapper">
          <button class="main_btn">
            {{ $t("buttons.edit") }}
            <span class="btn_loader" v-if="isWaitingRequest"></span>
          </button>
        </div>
        <!-- END:: SUBMIT BTN WRAPPER -->
      </form>
    </div>
    <!-- END:: FORM -->
  </div>
</template>

<script>
// START:: IMPORT VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORT VUEX HELPERS

export default {
  name: "UpdateDashboardSettingsForm",

  data() {
    return {
      isWaitingRequest: false,

      // START:: TIME FORMAT DATA
      timeFormatList: [
        {
          id: 1,
          format: "AM/PM",
        },
        {
          id: 2,
          format: "24H",
        },
      ],
      // END:: TIME FORMAT DATA

      // START:: TIME FORMAT DATA
      showEndTimeOptionsList: [
        {
          id: 1,
          option: "No",
        },
        {
          id: 2,
          option: "Yes",
        },
      ],
      // END:: TIME FORMAT DATA

      dashboardSettingsFormData: {
        dashboardView: [
          {
            id: 1,
            name: "Reservation Book Mode (Default)",
          },
        ],
        timeFormat: {
          id: 1,
          format: "AM/PM",
        },
        showEndTime: {
          id: 1,
          option: "No",
        },
      },
    };
  },

  computed: {
    ...mapGetters("apisGetsModule", ["dashboardViewsList"]),
  },

  methods: {
    // START:: VALIDATE SECOND STEP FORM
    validateDashboardSettingsForm() {
      this.isWaitingRequest = true;

      if (
        !this.dashboardSettingsFormData.dashboardView ||
        this.dashboardSettingsFormData.dashboardView.length == 0
      ) {
        this.$message.error(this.$t("validation.dashboard_view_validation"));
        this.isWaitingRequest = false;
        return;
      } else if (
        !this.dashboardSettingsFormData.timeFormat ||
        this.dashboardSettingsFormData.timeFormat.length == 0
      ) {
        this.$message.error(this.$t("validation.time_format_validation"));
        this.isWaitingRequest = false;
        return;
      } else if (
        !this.dashboardSettingsFormData.showEndTime ||
        this.dashboardSettingsFormData.showEndTime.length == 0
      ) {
        this.$message.error(this.$t("validation.show_end_time_validation"));
        this.isWaitingRequest = false;
        return;
      } else {
        this.submitDashboardSettingsForm();
      }
    },
    // END:: VALIDATE SECOND STEP FORM

    // START:: SUBMIT SECOND STEP FORM
    submitDashboardSettingsForm() {
      setTimeout(() => {
        this.isWaitingRequest = false;
      }, 2000);
    },
    // END:: SUBMIT SECOND STEP FORM
  },
};
</script>
