export default {
  FilterData(state, payload) {
    state.categories = payload.data.data.categories;
    state.cities = payload.data.data.cities;
    state.countries = payload.data.data.countries;
    state.cuisines = payload.data.data.cuisines;
    state.features = payload.data.data.features;
    state.mealTypes = payload.data.data.meal_types;
  },

  cities(state, payload) {
    state.cities = payload.data.data.cities;
  },
};
