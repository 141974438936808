<template>
    <div class="booking_system_form_wrapper">
        <!-- START:: LOGO WRAPPER -->
        <router-link to="/" class="logo_wrapper">
            <img
                src="../../../assets/media/logo/logo.png"
                alt="Logo"
                width="120"
                height="80"
            />
        </router-link>
        <!-- END:: LOGO WRAPPER -->

        <!-- START:: TITLE -->
        <div class="title_wrapper">
            <h3>{{ $t("titles.new_restaurant_register") }}</h3>
            <h5>{{ $t("titles.booking_system_engine") }}</h5>
        </div>
        <!-- END:: TITLE -->

        <!-- START:: FORM -->
        <div class="form_wrapper">
            <form @submit.prevent="validateBookingEngineForm">
                <!-- START:: ADVANCES DAYS INPUT -->
                <div class="inner_wrapper">
                    <div class="input_group">
                        <label for="advances_days_select">
                            <img
                                src="../../../assets/media/icons/calendar.svg"
                                alt="icon"
                                width="25"
                                height="25"
                            />
                        </label>
                        <multiselect
                            id="advances_days_select"
                            v-model="bookingSystemFormData.advancesDays"
                            track-by="name"
                            label="name"
                            :placeholder="$t('placeholders.advances_days')"
                            :options="advancesDaysList"
                            :searchable="true"
                            :allow-empty="true"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>

                    <div class="input_caption">
                        Select How Many Days In Advance Can An Online
                        Reservation Be Booked. Default Is 7 Days
                    </div>
                </div>
                <!-- END:: ADVANCES DAYS INPUT -->

                <!-- START:: ADVANCES TIMES INPUT -->
                <div class="inner_wrapper">
                    <div class="input_group">
                        <label for="advances_times_select">
                            <img
                                src="../../../assets/media/icons/clock.svg"
                                alt="icon"
                                width="25"
                                height="25"
                            />
                        </label>
                        <multiselect
                            id="advances_times_select"
                            v-model="bookingSystemFormData.advancesTimes"
                            track-by="name"
                            label="name"
                            :placeholder="$t('placeholders.advances_times')"
                            :options="advancesTimesList"
                            :searchable="true"
                            :allow-empty="true"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>

                    <div class="input_caption">
                        By Default, Guest Can Make An Online Reservation At
                        Least 20 Minutes In Advance. Unless You Have Specific
                        Reason, It Is Not Recommending Increasing The Minimum
                        Advances Time As You May Be Losing Business.
                    </div>
                </div>
                <!-- END:: ADVANCES TIMES INPUT -->

                <!-- START:: MINIMUM GUESTS INPUT -->
                <div class="inner_wrapper">
                    <div class="input_group">
                        <label for="minimum_guests_number">
                            <img
                                src="../../../assets/media/icons/users.svg"
                                alt="icon"
                                width="25"
                                height="25"
                            />
                        </label>
                        <multiselect
                            id="minimum_guests_number"
                            v-model="bookingSystemFormData.minimumGuestsNumber"
                            track-by="name"
                            label="name"
                            :placeholder="
                                $t('placeholders.minimum_guests_number')
                            "
                            :options="minimumGuestsList"
                            :searchable="true"
                            :allow-empty="true"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>

                    <div class="input_caption">
                        If You Only Allow Reservations For Larger Parties,
                        Please Select The Party Size. Default Is 1 Person.
                    </div>
                </div>
                <!-- END:: MINIMUM GUESTS INPUT -->

                <!-- START:: MAXIMUM GUESTS INPUT -->
                <div class="inner_wrapper">
                    <div class="input_group">
                        <label for="maximum_guests_number">
                            <img
                                src="../../../assets/media/icons/users.svg"
                                alt="icon"
                                width="25"
                                height="25"
                            />
                        </label>
                        <multiselect
                            id="maximum_guests_number"
                            v-model="bookingSystemFormData.maximumGuestsNumber"
                            track-by="name"
                            label="name"
                            :placeholder="
                                $t('placeholders.maximum_guests_number')
                            "
                            :options="maximumGuestsList"
                            :searchable="true"
                            :allow-empty="true"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>

                    <div class="input_caption">
                        You Can Set The Maximum Party Size For Online
                        Reservations. If You Are Planing On Using The Table
                        Availability Dashboard Mode, The Maximum Party Size
                        Should Be Equal Your Largest Table Or Table Group. Your
                        Timeslot Limit Is <strong>15</strong> The Maximum
                        Reservation Size Can't Be Larger Than Your Timeslot
                        Limit. (You Can Adjust Your min/max Party Size And
                        Timeslot limits For Each Day Later).
                    </div>
                </div>
                <!-- END:: MAXIMUM GUESTS INPUT -->

                <!-- START:: SUBMIT BTN WRAPPER -->
                <div class="btn_wrapper">
                    <button class="main_btn">
                        {{ $t("buttons.continue") }}
                        <span class="btn_loader" v-if="isWaitingRequest"></span>
                    </button>
                </div>
                <!-- END:: SUBMIT BTN WRAPPER -->
            </form>
        </div>
        <!-- END:: FORM -->
    </div>
</template>

<script>
// START:: IMPORT VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORT VUEX HELPERS

export default {
    name: "BookingEngineSystem",

    data() {
        return {
            isWaitingRequest: false,

            bookingSystemFormData: {
                advancesDays: [
                    {
                        id: 2,
                        name: "7 Days (Default)",
                    },
                ],
                advancesTimes: [
                    {
                        id: 1,
                        name: "20 Minutes (Default)",
                    },
                ],
                minimumGuestsNumber: [
                    {
                        id: 1,
                        name: "1",
                    },
                ],
                maximumGuestsNumber: null,
            },
        };
    },

    computed: {
        ...mapGetters("apisGetsModule", [
            "advancesDaysList",
            "advancesTimesList",
            "minimumGuestsList",
            "maximumGuestsList",
        ]),
    },

    methods: {
        // START:: VALIDATE SECOND STEP FORM
        validateBookingEngineForm() {
            this.isWaitingRequest = true;

            if (
                !this.bookingSystemFormData.advancesDays ||
                this.bookingSystemFormData.advancesDays.length == 0
            ) {
                this.$message.error(
                    this.$t("validation.advances_days_validation")
                );
                this.isWaitingRequest = false;
                return;
            } else if (
                !this.bookingSystemFormData.advancesTimes ||
                this.bookingSystemFormData.advancesTimes.length == 0
            ) {
                this.$message.error(
                    this.$t("validation.advances_times_validation")
                );
                this.isWaitingRequest = false;
                return;
            } else if (
                !this.bookingSystemFormData.minimumGuestsNumber ||
                this.bookingSystemFormData.minimumGuestsNumber.length == 0
            ) {
                this.$message.error(
                    this.$t("validation.min_guest_size_validation")
                );
                this.isWaitingRequest = false;
                return;
            } else if (
                !this.bookingSystemFormData.maximumGuestsNumber ||
                this.bookingSystemFormData.maximumGuestsNumber.length == 0
            ) {
                this.$message.error(
                    this.$t("validation.max_guest_size_validation")
                );
                this.isWaitingRequest = false;
                return;
            } else {
                this.submitBookingEngineForm();
            }
        },
        // END:: VALIDATE SECOND STEP FORM

        // START:: SUBMIT SECOND STEP FORM
        submitBookingEngineForm() {
            setTimeout(() => {
                this.isWaitingRequest = false;
                this.$router.push("/dashboard-settings");
            }, 2000);
        },
        // END:: SUBMIT SECOND STEP FORM
    },
};
</script>
