<template>
  <div class="dashboard_preferences_form_wrapper">
    <!-- START:: TITLE -->
    <div class="title_wrapper mb-3">
      <h3>{{ $t("titles.dashboard_preferences") }}</h3>
    </div>
    <!-- END:: TITLE -->

    <!-- START:: FORM -->
    <div class="form_wrapper">
      <form @submit.prevent="validateDashboardPreferencesForm">
        <!-- START:: CHECK BOXES WRAPPER -->
        <div class="row justify-content-center my-5">
          <!-- START:: CHECKBOX INPUT GROUP -->
          <div
            class="col-3 col-lg-1 my-3"
            v-for="mainPreference in mainPreferencesList"
            :key="mainPreference.id"
          >
            <div class="form-check icons_form_check">
              <input
                class="form-check-input"
                type="checkbox"
                :value="mainPreference.id"
                :id="'mainPreference' + mainPreference.id"
                v-model="dashboardPreferencesFormData.mainPreferences"
              />
              <label
                class="form-check-label"
                :for="'mainPreference' + mainPreference.id"
              >
                <img
                  src="../../assets/media/icons/forkAndKnife.svg"
                  width="35"
                  height="35"
                  alt="icon"
                />
              </label>
            </div>
          </div>
          <!-- END:: CHECKBOX INPUT GROUP -->
        </div>
        <!-- END:: CHECK BOXES WRAPPER -->

        <!-- START:: TITLE -->
        <div class="title_wrapper py-5">
          <h5>
            Letter, You Can Also Create Custom Preferences For Any Occasion, Not
            On This List!
          </h5>
        </div>
        <!-- END:: TITLE -->

        <!-- START:: CHECK BOXES WRAPPER -->
        <div class="row my-5">
          <!-- START:: CHECKBOX INPUT GROUP -->
          <div
            class="col-6 col-lg-4 my-3"
            v-for="preference in preferencesList"
            :key="preference.id"
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :value="preference.id"
                :id="'preference' + preference.id"
                v-model="dashboardPreferencesFormData.preferences"
              />
              <label
                class="form-check-label"
                :for="'preference' + preference.id"
              >
                {{ preference.name }}
              </label>
            </div>
          </div>
          <!-- END:: CHECKBOX INPUT GROUP -->
        </div>
        <!-- END:: CHECK BOXES WRAPPER -->

        <!-- START:: SUBMIT BTN WRAPPER -->
        <div class="btn_wrapper mt-5">
          <button class="main_btn">
            {{ $t("buttons.edit") }}
            <span class="btn_loader" v-if="isWaitingRequest"></span>
          </button>
        </div>
        <!-- END:: SUBMIT BTN WRAPPER -->
      </form>
    </div>
  </div>
</template>

<script>
// START:: IMPORT VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORT VUEX HELPERS

export default {
  name: "UpdateDashboardPreferencesForm",

  data() {
    return {
      isWaitingRequest: false,

      dashboardPreferencesFormData: {
        mainPreferences: [1, 3, 4, 7],
        preferences: [1, 5, 6, 8],
      },
    };
  },

  computed: {
    ...mapGetters("apisGetsModule", ["preferencesList", "mainPreferencesList"]),
  },

  methods: {
    // START:: VALIDATE SECOND STEP FORM
    validateDashboardPreferencesForm() {
      this.isWaitingRequest = true;

      if (this.dashboardPreferencesFormData.mainPreferences == 0) {
        this.$message.error(this.$t("validation.preferences_validation"));
        this.isWaitingRequest = false;
        return;
      } else if (this.dashboardPreferencesFormData.preferences == 0) {
        this.$message.error(this.$t("validation.preferences_validation"));
        this.isWaitingRequest = false;
        return;
      } else {
        this.submitDashboardPreferencesForm();
      }
    },
    // END:: VALIDATE SECOND STEP FORM

    // START:: SUBMIT SECOND STEP FORM
    submitDashboardPreferencesForm() {
      setTimeout(() => {
        this.isWaitingRequest = false;
      }, 2000);
    },
    // END:: SUBMIT SECOND STEP FORM
  },
};
</script>
