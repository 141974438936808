import * as types from "./types";

export const login = ({ commit }, payload) => {
  commit(types.LOGIN, payload);
};

export const update_user = ({ commit }, payload) => {
  commit(types.UPDATE_USER, payload);
};

export const logout = ({ commit }, payload) => {
  commit(types.LOGOUT, payload);
};

export const unauthorized = ({ commit }, payload) => {
  commit(types.UNAUTHORIZED, payload);
};
