<template>
  <div class="orders_data_wrapper">
    <h3 style="display: inline-block">{{ $t("titles.branch_images") }}</h3>
    <!-- START:: ADD MENU CATEGORY  BUTTON -->

    <button @click="toggleAddImageModal" class="btn_wrapper">
      {{ $t("buttons.add_image") }}
    </button>

    <!-- END:: ADD MENU CATEGORY  BUTTON -->

    <!-- START:: ORDERS TABLE -->
    <ImagesTable
      :headers="orderTableHeaders"
      :items="images"
      :showItem="showItem"
      :editItem="editItem"
      :selectDeleteItem="selectDeleteItem"
    />
    <!-- END:: ORDERS TABLE -->

    <!-- START:: ADD CAT MODAL -->
    <BranchImage
      :modalAppearanceData="addImageModalIsOpen"
      @controlModalAppearance="toggleAddImageModal"
    />

    <!-- END:: ADD CAT MODAL -->
  </div>
</template>

<script>
// START:: IMPORTING DATA TABLE
import ImagesTable from "../Global/DataTable.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import BranchImage from "../modals/BranchImage.vue";

// END:: IMPORTING DATA TABLE

export default {
  name: "BranchImageTab",

  components: {
    ImagesTable,
    BranchImage,
  },

  data() {
    return {
      // START:: ORDER TABLE DATA
      orderSearch: "",
      orderTableHeaders: [
        { text: "#", align: "center", value: "id" },
        {
          text: this.$t("placeholders.image"),
          align: "center",
          sortable: false,
          value: "image",
        },
        {
          text: this.$t("placeholders.created_date"),
          value: "created_at",
          align: "center",
        },
        {
          text: this.$t("placeholders.actions"),
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      orders: [
        {
          sort: 1,
          image: "",
          date: "15/1/2022",
        },
      ],
      addImageModalIsOpen: false,
      // END:: ORDER TABLE DATAaddImageModalIsOpen
    };
  },
  computed: {
    ...mapGetters({
      images: "branch/images",
    }),
  },
  methods: {
    async getImages() {
      try {
        const response = await axios.get("image", {
          params: {
            provider_id: this.$route.params.id,
          },
        });
        this.$store.dispatch("branch/branchImages", response);
      } catch (error) {
        console.log(error.response);
      }
    },
    // START:: TOGGLE SUCCESS MODAL METHOD
    toggleAddImageModal() {
      this.addImageModalIsOpen = !this.addImageModalIsOpen;
    },
    // END:: TOGGLE SUCCESS MODAL METHOD
    showItem(item) {
      console.log(item);
    },
    editItem(item) {
      console.log(item);
    },
    selectDeleteItem(item) {
      console.log(item);
    },
  },
  mounted() {
    this.getImages();
  },
};
</script>

<style scoped>
.btn_wrapper {
  position: relative;
  padding: 0.7em;
  margin-right: 2rem;
  font-size: 18px;
  font-weight: bolder;
  text-align: center;
  line-height: 1.2;
  text-decoration: none;
  color: var(--white_clr);
  background-color: var(--main_clr);
  width: 180px;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  transition: all 0.4s ease-in-out;
  float: left;
}
</style>
