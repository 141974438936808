var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard_preferences_form_wrapper"},[_c('router-link',{staticClass:"logo_wrapper",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../../../assets/media/logo/logo.png"),"alt":"Logo","width":"120","height":"80"}})]),_c('div',{staticClass:"title_wrapper"},[_c('h3',[_vm._v(_vm._s(_vm.$t("titles.new_restaurant_register")))]),_c('h5',[_vm._v(_vm._s(_vm.$t("titles.dashboard_preferences")))])]),_vm._m(0),_c('div',{staticClass:"form_wrapper"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateDashboardPreferencesForm.apply(null, arguments)}}},[_c('div',{staticClass:"row justify-content-center my-5"},_vm._l((_vm.mainPreferencesList),function(mainPreference){return _c('div',{key:mainPreference.id,staticClass:"col-3 col-lg-1 my-3"},[_c('div',{staticClass:"form-check icons_form_check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.dashboardPreferencesFormData.mainPreferences
                                ),expression:"\n                                    dashboardPreferencesFormData.mainPreferences\n                                "}],staticClass:"form-check-input",attrs:{"type":"checkbox","id":'mainPreference' + mainPreference.id},domProps:{"value":mainPreference.id,"checked":Array.isArray(
                                    _vm.dashboardPreferencesFormData.mainPreferences
                                )?_vm._i(
                                    _vm.dashboardPreferencesFormData.mainPreferences
                                ,mainPreference.id)>-1:(
                                    _vm.dashboardPreferencesFormData.mainPreferences
                                )},on:{"change":function($event){var $$a=
                                    _vm.dashboardPreferencesFormData.mainPreferences
                                ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=mainPreference.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.dashboardPreferencesFormData, "mainPreferences", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.dashboardPreferencesFormData, "mainPreferences", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.dashboardPreferencesFormData, "mainPreferences", $$c)}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":'mainPreference' + mainPreference.id}},[_c('img',{attrs:{"src":require("../../../assets/media/icons/forkAndKnife.svg"),"width":"35","height":"35","alt":"icon"}})])])])}),0),_vm._m(1),_c('div',{staticClass:"row my-5"},_vm._l((_vm.preferencesList),function(preference){return _c('div',{key:preference.id,staticClass:"col-6 col-lg-4 my-3"},[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.dashboardPreferencesFormData.preferences
                                ),expression:"\n                                    dashboardPreferencesFormData.preferences\n                                "}],staticClass:"form-check-input",attrs:{"type":"checkbox","id":'preference' + preference.id},domProps:{"value":preference.id,"checked":Array.isArray(
                                    _vm.dashboardPreferencesFormData.preferences
                                )?_vm._i(
                                    _vm.dashboardPreferencesFormData.preferences
                                ,preference.id)>-1:(
                                    _vm.dashboardPreferencesFormData.preferences
                                )},on:{"change":function($event){var $$a=
                                    _vm.dashboardPreferencesFormData.preferences
                                ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=preference.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.dashboardPreferencesFormData, "preferences", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.dashboardPreferencesFormData, "preferences", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.dashboardPreferencesFormData, "preferences", $$c)}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":'preference' + preference.id}},[_vm._v(" "+_vm._s(preference.name)+" ")])])])}),0),_c('div',{staticClass:"btn_wrapper mt-5"},[_c('button',{staticClass:"main_btn"},[_vm._v(" "+_vm._s(_vm.$t("buttons.continue"))+" "),(_vm.isWaitingRequest)?_c('span',{staticClass:"btn_loader"}):_vm._e()])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title_note_wrapper"},[_c('p',[_vm._v(" Select All The Applies To Your Business. Your Staff Will Be Able To Mark Bookings With These Preferences As A Small Icon In Your Dashboard. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title_wrapper py-5"},[_c('h5',[_vm._v(" Letter, You Can Also Create Custom Preferences For Any Occasion, Not On This List! ")])])
}]

export { render, staticRenderFns }