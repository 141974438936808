<template>
  <div class="phone_and_email_notifications_form_wrapper">
    <!-- START:: TITLE -->
    <div class="title_wrapper mb-3">
      <h3>{{ $t("titles.phone_and_email_notifications") }}</h3>
    </div>
    <!-- END:: TITLE -->

    <!-- START:: FORM -->
    <div class="form_wrapper">
      <form @submit.prevent="validatePhoneAndEmailNotificationsForm">
        <!-- START:: EMAIL NOTIFICATIONS INPUT -->
        <div class="input_group">
          <label for="email_notifications">
            <img
              src="../../assets/media/icons/at.svg"
              alt="icon"
              width="25"
              height="25"
            />
          </label>
          <multiselect
            id="email_notifications"
            v-model="wantEmailNotifications"
            track-by="option"
            label="option"
            :placeholder="$t('placeholders.email_notifications')"
            :options="emailNotificationsOptionsList"
            :searchable="true"
            :allow-empty="true"
            :show-labels="false"
          >
          </multiselect>
        </div>

        <transition mode="in-out" name="fade">
          <div
            class="wrapper mb-5"
            v-if="wantEmailNotifications.id == 'activeEmailNotifications'"
          >
            <div class="row">
              <!-- START:: EMAIL INPUT -->
              <div class="col-lg-8 mb-4">
                <div class="input_group">
                  <label class="options_label" for="first_email">
                    <span>
                      {{ $t("placeholders.first_email") }}
                    </span>
                  </label>
                  <input
                    id="first_email"
                    class="form-control custom_input"
                    type="email"
                    :placeholder="$t('placeholders.email')"
                    v-model.trim="firstNotificationEmail"
                  />
                </div>
              </div>
              <!-- END:: EMAIL INPUT -->

              <!-- START:: EMAIL INPUT -->
              <div class="col-lg-8 mb-4">
                <div class="input_group">
                  <label class="options_label" for="second_email">
                    <span>
                      {{ $t("placeholders.second_email") }}
                    </span>
                  </label>
                  <input
                    id="second_email"
                    class="form-control custom_input"
                    type="email"
                    :placeholder="$t('placeholders.email')"
                    v-model.trim="secondNotificationEmail"
                  />
                </div>
              </div>
              <!-- END:: EMAIL INPUT -->
            </div>
          </div>
        </transition>
        <!-- END:: EMAIL NOTIFICATIONS INPUT -->

        <!-- START:: PHONE NOTIFICATIONS INPUT -->
        <div class="input_group">
          <label for="phone_notifications">
            <img
              src="../../assets/media/icons/chat.svg"
              alt="icon"
              width="25"
              height="25"
            />
          </label>
          <multiselect
            id="phone_notifications"
            v-model="wantPhoneNotifications"
            track-by="option"
            label="option"
            :placeholder="$t('placeholders.phone_notifications')"
            :options="phoneNotificationsOptionsList"
            :searchable="true"
            :allow-empty="true"
            :show-labels="false"
          >
          </multiselect>
        </div>

        <transition mode="in-out" name="fade">
          <div
            class="wrapper"
            v-if="wantPhoneNotifications.id == 'activePhoneNotifications'"
          >
            <div class="row">
              <!-- START:: PHONE INPUT -->
              <div class="col-lg-8 mb-4">
                <div class="input_group">
                  <label class="options_label" for="first_phone">
                    <span>
                      {{ $t("placeholders.first_phone") }}
                    </span>
                  </label>
                  <input
                    id="first_phone"
                    class="form-control custom_input"
                    type="tel"
                    :placeholder="$t('placeholders.phone')"
                    v-model.trim="firstNotificationPhone"
                  />
                </div>
              </div>
              <!-- END:: PHONE INPUT -->

              <!-- START:: PHONE INPUT -->
              <div class="col-lg-8 mb-4">
                <div class="input_group">
                  <label class="options_label" for="second_phone">
                    <span>
                      {{ $t("placeholders.second_phone") }}
                    </span>
                  </label>
                  <input
                    id="second_phone"
                    class="form-control custom_input"
                    type="tel"
                    :placeholder="$t('placeholders.phone')"
                    v-model.trim="secondNotificationPhone"
                  />
                </div>
              </div>
              <!-- END:: PHONE INPUT -->
            </div>
          </div>
        </transition>
        <!-- END:: PHONE NOTIFICATIONS INPUT -->

        <!-- START:: SUBMIT BTN WRAPPER -->
        <div class="btn_wrapper mt-5">
          <button class="main_btn">
            {{ $t("buttons.edit") }}
            <span class="btn_loader" v-if="isWaitingRequest"></span>
          </button>
        </div>
        <!-- END:: SUBMIT BTN WRAPPER -->
      </form>
    </div>
    <!-- END:: FORM -->
  </div>
</template>

<script>
export default {
  name: "UpdatePhoneAndEmailNotificationsForm",

  data() {
    return {
      isWaitingRequest: false,
      // START:: EMAIL NOTIFICATIONS DATA
      wantEmailNotifications: {
        id: "activeEmailNotifications",
        option: "activeEmailNotifications",
      },
      emailNotificationsOptionsList: [
        {
          id: "disableEmailNotifications",
          option: "No",
        },
        {
          id: "activeEmailNotifications",
          option: "activeEmailNotifications",
        },
      ],
      firstNotificationEmail: "firstemail@test.com",
      secondNotificationEmail: "secondemail@test.com",
      // END:: EMAIL NOTIFICATIONS DATA

      // START:: PHONE NOTIFICATIONS DATA
      wantPhoneNotifications: {
        id: this.$t("placeholders.phone_notifications"),
        option: this.$t("placeholders.phone_notifications"),
      },
      phoneNotificationsOptionsList: [
        {
          id: "disablePhoneNotifications",
          option: "No",
        },
        {
          id: "activePhoneNotifications",
          option: "Yes",
        },
      ],
      firstNotificationPhone: null,
      secondNotificationPhone: null,
      // END:: PHONE NOTIFICATIONS DATA
    };
  },

  methods: {
    // START:: VALIDATE PHONE AND EMAIL NOTIFICATIONS FORM
    validatePhoneAndEmailNotificationsForm() {
      this.isWaitingRequest = true;
      this.submitPhoneAndEmailNotificationsForm();
    },
    // END:: VALIDATE PHONE AND EMAIL NOTIFICATIONS FORM

    // START:: SUBMIT PHONE AND EMAIL NOTIFICATIONS FORM
    submitPhoneAndEmailNotificationsForm() {
      setTimeout(() => {
        this.isWaitingRequest = false;
      }, 2000);
    },
    // END:: SUBMIT PHONE AND EMAIL NOTIFICATIONS FORM
  },
};
</script>
