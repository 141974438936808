<template>
  <div class="our_customers">
    <!-- START:: SECTION TITLE -->
    <div class="title_wrapper">
      <h2>{{ $t("titles.our_customers") }}</h2>
    </div>
    <!-- END:: SECTION TITLE -->

    <!-- START:: CUSTOMERS CAROUSEL -->
    <div
      class="customers_carousel_wrapper"
      :dir="getAppLocale == 'ar' ? 'rtl' : 'ltr'"
    >
      <!-- START:: DUMMY DATA -->
      <div class="container">
        <Carousel
          dir="ltr"
          :nav="false"
          :dots="false"
          :items="4"
          :margin="15"
          :loop="true"
          :autoplay="true"
          :autoplayTimeout="6000"
          :dragEndSpeed="2000"
          :smartSpeed="2000"
          :responsive="{
            0: {
              items: 2,
            },
            750: {
              items: 2,
            },
            900: {
              items: 4,
            },
          }"
        >
          <template v-if="customers.length > 0">
            <!-- START:: SERVICE CARD -->
            <div
              class="customer_logo_wrapper"
              v-for="customer in customers"
              :key="customer.id"
            >
              <img
                :src="customer.image"
                alt="Customer Name"
                width="120"
                height="120"
              />
            </div>
            <!-- END:: SERVICE CARD -->
          </template>
          <template v-else>
            <!-- START:: SERVICE CARD -->
            <div class="customer_logo_wrapper">
              <img
                src="@/assets/media/imgs/logo1.png"
                alt="Customer Name"
                width="120"
                height="120"
              />
            </div>
            <!-- END:: SERVICE CARD -->

            <!-- START:: SERVICE CARD -->
            <div class="customer_logo_wrapper">
              <img
                src="@/assets/media/imgs/logo2.png"
                alt="Customer Name"
                width="120"
                height="120"
              />
            </div>
            <!-- END:: SERVICE CARD -->

            <!-- START:: SERVICE CARD -->
            <div class="customer_logo_wrapper">
              <img
                src="@/assets/media/imgs/logo3.png"
                alt="Customer Name"
                width="120"
                height="120"
              />
            </div>
            <!-- END:: SERVICE CARD -->

            <!-- START:: SERVICE CARD -->
            <div class="customer_logo_wrapper">
              <img
                src="@/assets/media/imgs/logo4.png"
                alt="Customer Name"
                width="120"
                height="120"
              />
            </div>
            <!-- END:: SERVICE CARD -->
          </template>
        </Carousel>
      </div>
      <!-- END:: DUMMY DATA -->
    </div>
    <!-- END:: CUSTOMERS CAROUSEL -->
  </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
// import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING OWL CAROUSEL
import Carousel from "vue-owl-carousel";
// END:: IMPORTING OWL CAROUSEL

export default {
  name: "LandingOneCustomersSection",

  components: {
    Carousel,
  },

  props: {
    customers: {
      type: Array,
      required: true,
    },
  },

  computed: {
    // START:: VUEX GET APP LANG
    // ...mapGetters("AppLangModule", ["getAppLocale"]),
    // END:: VUEX GET APP LANG
  },
};
</script>
