var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data_table_wrapper"},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"label":_vm.$t('placeholders.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.items,"itemsPerPage":5,"footerProps":{
          showFirstLastPage: false,
          prevIcon: 'fas fa-chevron-left',
          nextIcon: 'fas fa-chevron-right',
          itemsPerPageOptions: [5, 10, 20, 30, -1],
          itemsPerPageText: _vm.$t('dataTable.footer.items_per_page_text'),
          itemsPerPageAllText: _vm.$t('dataTable.footer.items_per_page_all_text'),
        }},scopedSlots:_vm._u([{key:"item.image",fn:function({ item }){return [_c('img',{attrs:{"src":item.image}})]}},{key:`item.actions`,fn:function({ item }){return [_c('div',{staticClass:"actions"},[_c('button',{staticClass:"btn_show btn btn-info",staticStyle:{"margin":"5px"},on:{"click":function($event){return _vm.showItem(item)}}},[_c('i',{staticClass:"fa fa-eye",staticStyle:{"color":"white"}})]),_c('button',{staticClass:"btn_edit btn btn-primary",staticStyle:{"margin":"5px"},on:{"click":function($event){return _vm.editItem(item)}}},[_c('i',{staticClass:"fa fa-edit",staticStyle:{"color":"white"}})]),_c('button',{staticClass:"btn_delete btn btn-danger",staticStyle:{"margin":"5px"},on:{"click":function($event){return _vm.selectDeleteItem(item)}}},[_c('i',{staticClass:"fa fa-trash-alt",staticStyle:{"color":"white"}})])])]}},{key:`footer.page-text`,fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" "),_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.$t("dataTable.footer.of")))]),_vm._v(" "+_vm._s(items.itemsLength)+" ")]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }