<template>
    <div class="restaurant_name_and_address_form_wrapper">
        <!-- START:: LOGO WRAPPER -->
        <router-link to="/" class="logo_wrapper">
            <img
                src="../../../assets/media/logo/logo.png"
                alt="Logo"
                width="120"
                height="80"
            />
        </router-link>
        <!-- END:: LOGO WRAPPER -->

        <!-- START:: TITLE -->
        <div class="title_wrapper">
            <h3>{{ $t("titles.new_restaurant_register") }}</h3>
            <h5>{{ $t("titles.name_and_address") }}</h5>
        </div>
        <!-- END:: TITLE -->

        <!-- START:: FORM -->
        <div class="form_wrapper">
            <form @submit.prevent="validateNameAndAddressForm">
                <!-- START:: RESTAURANT NAME INPUT -->
                <div class="input_group">
                    <label for="restaurant_name">
                        <img
                            src="../../../assets/media/icons/pen.svg"
                            alt="icon"
                            width="25"
                            height="25"
                        />
                    </label>
                    <input
                        id="restaurant_name"
                        class="form-control custom_input"
                        type="text"
                        :placeholder="$t('placeholders.restaurant_name')"
                        v-model.trim="nameAndAddressFormData.restaurantName"
                    />
                </div>
                <!-- END:: RESTAURANT NAME INPUT -->

                <!-- START:: RESTAURANT ADDRESS INPUT -->
                <div class="input_group">
                    <label for="restaurant_address">
                        <img
                            src="../../../assets/media/icons/location.svg"
                            alt="icon"
                            width="25"
                            height="25"
                        />
                    </label>
                    <input
                        id="restaurant_address"
                        class="form-control custom_input"
                        type="text"
                        :placeholder="$t('placeholders.address')"
                        v-model.trim="nameAndAddressFormData.address"
                    />
                </div>
                <!-- END:: RESTAURANT ADDRESS INPUT -->

                <!-- START:: CITY INPUT -->
                <div class="input_group">
                    <label for="city_select">
                        <img
                            src="../../../assets/media/icons/location.svg"
                            alt="icon"
                            width="25"
                            height="25"
                        />
                    </label>
                    <multiselect
                        id="city_select"
                        v-model="nameAndAddressFormData.city"
                        track-by="name"
                        label="name"
                        :placeholder="$t('placeholders.city')"
                        :options="citiesList"
                        :searchable="true"
                        :allow-empty="true"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <!-- END:: CITY INPUT -->

                <!-- START:: POSTAL CODE INPUT -->
                <div class="input_group">
                    <label for="postal_code">
                        <img
                            src="../../../assets/media/icons/send.svg"
                            alt="icon"
                            width="25"
                            height="25"
                        />
                    </label>
                    <input
                        id="postal_code"
                        class="form-control custom_input"
                        type="text"
                        :placeholder="$t('placeholders.postal_code')"
                        v-model.trim="nameAndAddressFormData.postalCode"
                    />
                </div>
                <!-- END:: POSTAL CODE INPUT -->

                <!-- START:: SUBMIT BTN WRAPPER -->
                <div class="btn_wrapper">
                    <button class="main_btn">
                        {{ $t("buttons.continue") }}
                        <span class="btn_loader" v-if="isWaitingRequest"></span>
                    </button>
                </div>
                <!-- END:: SUBMIT BTN WRAPPER -->
            </form>
        </div>
        <!-- END:: FORM -->
    </div>
</template>

<script>
// START:: IMPORT VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORT VUEX HELPERS

export default {
    name: "NameAndAddress",

    data() {
        return {
            isWaitingRequest: false,

            nameAndAddressFormData: {
                restaurantName: null,
                address: null,
                city: null,
                postalCode: null,
            },
        };
    },

    computed: {
        ...mapGetters("apisGetsModule", ["citiesList"]),
    },

    methods: {
        // START:: VALIDATE SECOND STEP FORM
        validateNameAndAddressForm() {
            this.isWaitingRequest = true;

            if (!this.nameAndAddressFormData.restaurantName) {
                this.$message.error(
                    this.$t("validation.restaurant_name_validation")
                );
                this.isWaitingRequest = false;
                return;
            } else if (!this.nameAndAddressFormData.address) {
                this.$message.error(this.$t("validation.address_validation"));
                this.isWaitingRequest = false;
                return;
            } else if (!this.nameAndAddressFormData.city) {
                this.$message.error(
                    this.$t("validation.select_city_validation")
                );
                this.isWaitingRequest = false;
                return;
            } else if (!this.nameAndAddressFormData.postalCode) {
                this.$message.error(
                    this.$t("validation.postal_code_validation")
                );
                this.isWaitingRequest = false;
                return;
            } else {
                this.submitNameAndAddressForm();
            }
        },
        // END:: VALIDATE SECOND STEP FORM

        // START:: SUBMIT SECOND STEP FORM
        submitNameAndAddressForm() {
            setTimeout(() => {
                this.isWaitingRequest = false;
                this.$router.push("/restaurant-contact-info");
            }, 2000);
        },
        // END:: SUBMIT SECOND STEP FORM
    },
};
</script>
