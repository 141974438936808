export default {
  allBranches(state, payload) {
    state.branches = payload.data.data;
  },

  branchDetail(state, payload) {
    state.details = payload.data.data;
  },
  branchImages(state, payload) {
    state.images = payload.data.data;
  },
};
