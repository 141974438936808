<template>
  <div class="user_account_wrapper">
    <!-- START:: PROFILE HEADER -->
    <div class="profile_header">
      <!-- START:: PARTICLES SECTION -->
      <vue-particles
        color="#348F50"
        :particleOpacity="0.7"
        :particlesNumber="400"
        shapeType="circle"
        :particleSize="4"
        linesColor="#348F50"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.3"
        :linesDistance="100"
        :moveSpeed="3"
        :hoverEffect="true"
        :clickEffect="true"
        hoverMode="repulse"
        clickMode="push"
      >
      </vue-particles>
      <!-- END:: PARTICLES SECTION -->

      <!-- START:: USER AVATAR -->
      <div class="user_avatar_wrapper">
        <img :src="user.image" alt="Avatar" width="120" height="120" />
      </div>
      <!-- END:: USER AVATAR -->

      <!-- START:: RESTAURANT REGISTRATION ROUTE -->
      <div class="create_account_route_wrapper">
        <router-link to="/restaurant-setup">
          {{ $t("buttons.register_restaurant") }}
        </router-link>
      </div>
      <!-- END:: RESTAURANT REGISTRATION ROUTE -->
    </div>
    <!-- END:: PROFILE HEADER -->

    <!-- START:: PROFILE DATA -->
    <div class="profile_data_wrapper">
      <div class="container">
        <div class="row justify-content-between">
          <!-- START:: USER INFO WRAPPER -->
          <div class="col-lg-6">
            <div class="user_info_wrapper">
              <h2>
                <img
                  src="../assets/media/icons/user.svg"
                  width="30"
                  height="30"
                  alt="icon"
                />
                <span>{{ user.name }}</span>
              </h2>
              <h4>
                <img
                  src="../assets/media/icons/phone.svg"
                  width="30"
                  height="30"
                  alt="icon"
                />
                <span>{{ user.phone }}</span>
              </h4>
              <h4>
                <img
                  src="../assets/media/icons/at.svg"
                  width="30"
                  height="30"
                  alt="icon"
                />
                <span>{{ user.email }} </span>
              </h4>
            </div>
          </div>
          <!-- END:: USER INFO WRAPPER -->
        </div>
      </div>
    </div>
    <!-- END:: PROFILE DATA -->

    <!-- START:: ACCOUNT BRANCHES WRAPPER -->
    <div class="account_branches_wrapper">
      <!-- START: TITLE -->
      <div class="title_wrapper">
        <h2>{{ $t("titles.branches") }}</h2>
      </div>
      <!-- END: TITLE -->

      <!-- START:: BRANCHES CARDS VIEW BTNS -->
      <div class="container">
        <div class="cards_views_toggler_wrapper">
          <!-- START:: GRID VIEW BTN -->
          <button class="grid_view" @click="toggleCardsViews('grid')">
            <i class="fas fa-th fa-2x"></i>
          </button>
          <!-- END:: GRID VIEW BTN -->

          <!-- START:: ROW VIEW BTN -->
          <button class="row_view" @click="toggleCardsViews('single_row')">
            <i class="fas fa-align-justify fa-2x"></i>
          </button>
          <!-- END:: ROW VIEW BTN -->
        </div>
      </div>
      <!-- END:: BRANCHES CARDS VIEW BTNS -->

      <!-- START:: BRANCHES -->
      <div class="container">
        <div class="row justify-content-center">
          <!-- START:: BRANCH CARD -->
          <div
            class="transitioned_cards my-4"
            :class="cardViews == 'grid' ? 'col-lg-4' : 'col-lg-8'"
            v-for="branch in branches"
            :key="branch.id"
          >
            <router-link :to="'/branch-profile/' + branch.id">
              <div class="branch_card_wrapper">
                <!-- START:: BRANCH LOGO WRAPPER -->
                <div class="logo_wrapper">
                  <img :src="branch.logo" width="80" height="80" />
                </div>
                <!-- END:: BRANCH LOGO WRAPPER -->

                <!-- START:: BRANCH INFO WRAPPER -->
                <div class="branch_info_wrapper">
                  <h3 class="name" v-text="branch.name"></h3>
                  <h5 class="address" v-text="branch.location"></h5>
                  <h5 class="phone" v-text="branch.phone"></h5>
                  <a
                    href="#"
                    target="_blank"
                    class="email"
                    v-text="branch.email"
                  >
                  </a>

                  <div class="financial_summary">
                    <h5>
                      <div class="feature_icon">
                        <img
                          src="../assets/media/icons/calendar.svg"
                          width="35"
                          height="35"
                          alt="icon"
                        />
                      </div>
                      <span v-text="branch.bookings_count"></span>
                    </h5>
                  </div>

                  <h4 class="subtitle">{{ $t("titles.branch_features") }}</h4>

                  <!-- START:: FEATURES WRAPPER -->
                  <div class="features_wrapper">
                    <!-- START:: FEATURE ICON -->
                    <div
                      class="feature_icon"
                      v-if="branch.is_smoked == 'enabled'"
                    >
                      <img
                        src="../assets/media/icons/smoking.svg"
                        width="35"
                        height="35"
                        alt="icon"
                      />
                    </div>
                    <div
                      class="feature_icon"
                      v-if="branch.is_smoked == 'disable'"
                    >
                      <img
                        src="../assets/media/icons/no-smoking.svg"
                        width="35"
                        height="35"
                        alt="icon"
                      />
                    </div>
                    <!-- END:: FEATURE ICON -->

                    <!-- START:: FEATURE ICON -->
                    <div
                      class="feature_icon"
                      v-if="branch.kids_area == 'enable'"
                    >
                      <img
                        src="../assets/media/icons/kids-area.svg"
                        width="35"
                        height="35"
                        alt="icon"
                      />
                    </div>

                    <div
                      class="feature_icon"
                      v-if="branch.kids_area == 'disable'"
                    >
                      <img
                        src="../assets/media/icons/no-kids-area.svg"
                        width="35"
                        height="35"
                        alt="icon"
                      />
                    </div>

                    <!-- END:: FEATURE ICON -->
                  </div>
                  <!-- END:: FEATURES WRAPPER -->
                </div>
                <!-- END:: BRANCH INFO WRAPPER -->
              </div>
            </router-link>
          </div>
          <!-- END:: BRANCH CARD -->
        </div>
      </div>
      <!-- END:: BRANCHES -->
    </div>
    <!-- END:: ACCOUNT BRANCHES WRAPPER -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "UserAccount",
  computed: {
    ...mapGetters({
      branches: "branch/all",
      branchDetails: "branch/details",
      user: "auth/user",
    }),
  },
  data() {
    return {
      // START:: CARD VIEWS DATA
      cardViews: "grid",
      branch_id: null,
      // END:: CARD VIEWS DATA
    };
  },

  methods: {
    // START:: TOGGLE CARD VIEWS METHODS
    toggleCardsViews(view_type) {
      if (view_type == "grid") {
        this.cardViews = "grid";
      } else {
        this.cardViews = "single_row";
      }
    },
    // END:: TOGGLE CARD VIEWS METHODS
    async getAllBranches() {
      try {
        const response = await axios.get("branch");
        this.$store.dispatch("branch/allBranches", response);
      } catch (error) {
        console.log(error.response);
        // if(error.response.status ==401){
        //         this.$store.dispatch("auth/logout", {
        //           message: this.$i18n.t("messages.logout"),
        //       });
        // }
      }
    },
    async getBranchDetails() {
      try {
        const response = await axios.get("branch", {
          params: {
            id: this.branch_id,
          },
        });
        this.$store.dispatch("branch/branchDetail", response);
      } catch (error) {
        console.log(error.response);
      }
    },
  },
  mounted() {
    this.getAllBranches();
  },
};
</script>
