<template>
  <div class="container">
    <div class="landing_download_app_wrapper">
      <div class="row">
        <!-- Start:: Section Content -->
        <div class="col-md-7 order-2 order-md-1 my-3">
          <div class="download_app_section_content_wrapper">
            <div class="title">
              <h2>
                {{ $t("titles.downloadApp") }}
              </h2>
            </div>

            <div class="app_stores_wrapper">
              <a :href="app_store_app" target="_blank">
                <img
                  src="@/assets/media/stores_logos/apple-store-white.svg"
                  alt="apple store"
                  width="150"
                  height="100"
                />
              </a>

              <a :href="g_play_app" target="_blank">
                <img
                  src="@/assets/media/stores_logos/google-play-white.svg"
                  alt="google play"
                  width="150"
                  height="100"
                />
              </a>
            </div>
          </div>
        </div>
        <!-- End:: Section Content -->

        <!-- Start:: Section Image -->
        <div class="col-md-5 order-1 order-md-2 my-3">
          <div class="download_app_img_wrapper">
            <img
              v-if="app_image"
              class="download_app_image"
              :src="app_image"
              alt="download app image"
              width="250"
              height="380"
            />
            <img
              v-else
              class="download_app_image"
              src="@/assets/media/imgs/framedScreenApp.png"
              alt="download app image"
              width="250"
              height="380"
            />
          </div>
        </div>
        <!-- End:: Section Image -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingDownloadApp",
  props: ["app_image", "app_store_app", "g_play_app", "downaload_title"],
};
</script>
