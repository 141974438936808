<template>
  <div class="business_hours_form_wrapper">
    <!-- START:: LOGO WRAPPER -->
    <router-link to="/" class="logo_wrapper">
      <img
        src="../../../assets/media/logo/logo.png"
        alt="Logo"
        width="120"
        height="80"
      />
    </router-link>
    <!-- END:: LOGO WRAPPER -->

    <!-- START:: TITLE -->
    <div class="title_wrapper">
      <h3>{{ $t("titles.new_restaurant_register") }}</h3>
      <h5>{{ $t("titles.business_hours") }}</h5>
    </div>
    <!-- END:: TITLE -->

    <!-- START:: TITLE NOTE -->
    <div class="title_note_wrapper">
      <p>
        The Reservation System Will Only Allow Reservations During Your Business
        Hours. Please Set Your Business Hours For Each Day Of The Week Including
        Days When You Are Closed.
      </p>

      <p class="mt-3">
        If You Have Split Hours And You Close Between Lunch And Dinner etc.,
        Only Create Your Dinner Hours. You Will Be Able To Add And Manage Your
        Lunch, Breakfast, Brunch, etc. Business Hours Through Your Account
        Later.
      </p>
    </div>
    <!-- END:: TITLE NOTE -->

    <!-- START:: FORM -->
    <div class="form_wrapper">
      <form @submit.prevent="validateBusinessHoursForm">
        <!-- START:: DAYS WRAPPER -->
        <div class="business_hours_days_wrapper">
          <button
            class="business_hours_day"
            v-for="day in weekDays"
            :key="day.id"
            @click="selectDay(day.id)"
          >
            <span class="day_name">
              {{ day.name }}
            </span>

            <div class="day_working_hours_wrapper">
              <p v-for="time in day.business_hours" :key="time.id">
                <span class="time_from"> {{ time.from }} </span> -
                <span class="time_to"> {{ time.to }} </span>
              </p>
            </div>
          </button>
        </div>
        <!-- END:: DAYS WRAPPER -->

        <!-- START:: BUSINESS HOURS ADDING INPUTS -->
        <transition name="fadeIn" mode="out-in">
          <div
            id="business_hours_adding_inputs"
            class="business_hours_adding_inputs"
            v-if="selectedDay && workingHoursFormIsOpen"
          >
            <!-- START:: CLOSE BUTTON -->
            <button
              class="close_working_hours_form_btn"
              @click="toggleWorkingHoursForm"
            >
              <i class="fas fa-times"></i>
            </button>
            <!-- END:: CLOSE BUTTON -->

            <!-- START:: FORM TITLE -->
            <h4 class="title">Add {{ selectedDay.name }} Working Hours</h4>
            <!-- END:: FORM TITLE -->

            <!-- START:: WORKING HOURS INPUTS -->
            <div class="inputs">
              <div
                class="row justify-content-center my-4"
                v-for="time in selectedDay.business_hours"
                :key="time.id"
              >
                <!-- START:: FROM TIME INPUT -->
                <div class="col-lg-5">
                  <div class="input_group">
                    <label>
                      <img
                        src="../../../assets/media/icons/clock.svg"
                        alt="icon"
                        width="25"
                        height="25"
                      />
                    </label>
                    <a-time-picker
                      class="w-100"
                      use12-hours
                      format="h:mm a"
                      :placeholder="$t('placeholders.from')"
                      valueFormat="hh:mm a"
                      v-model="time.from"
                    />
                    <!-- <a-time-picker v-model="testTime1" /> -->
                  </div>
                </div>
                <!-- END:: FROM TIME INPUT -->

                <!-- START:: TO TIME INPUT -->
                <div class="col-lg-5">
                  <div class="input_group">
                    <label>
                      <img
                        src="../../../assets/media/icons/clock.svg"
                        alt="icon"
                        width="25"
                        height="25"
                      />
                    </label>
                    <a-time-picker
                      class="w-100"
                      use12-hours
                      format="h:mm a"
                      :placeholder="$t('placeholders.to')"
                      valueFormat="hh:mm a"
                      v-model="time.to"
                    />
                  </div>
                </div>
                <!-- END:: TO TIME INPUT -->

                <!-- START:: DELETE TIMES ROW BUTTON -->
                <div
                  class="col-lg-1 d-flex justify-content-center align-center"
                >
                  <button
                    class="delete_times_row_btn"
                    @click="deleteBusinessHours(time.id)"
                  >
                    <img
                      src="../../../assets/media/icons/trash.svg"
                      width="20"
                      height="20"
                      alt="Icon"
                    />
                  </button>
                </div>
                <!-- END:: DELETE TIMES ROW BUTTON -->
              </div>
            </div>
            <!-- END:: WORKING HOURS INPUTS -->

            <!-- START:: FROM BUTTONS -->
            <div class="btns_wrapper">
              <!-- START:: ADD WORKING TIMES BUTTON -->
              <button class="add_working_times_btn" @click="addWorkingHours">
                <i class="fas fa-plus"></i>
                <span> Add More Working Times </span>
              </button>
              <!-- END:: ADD WORKING TIMES BUTTON -->

              <!-- START:: ADD WORKING TIMES BUTTON -->
              <button class="save_btn">
                <span> Save </span>
              </button>
              <!-- END:: ADD WORKING TIMES BUTTON -->
            </div>
            <!-- END:: FROM BUTTONS -->
          </div>
        </transition>
        <!-- END:: BUSINESS HOURS ADDING INPUTS -->
      </form>
    </div>
  </div>
</template>

<script>
// START:: IMPORT VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORT VUEX HELPERS

export default {
  name: "BusinessHours",

  data() {
    return {
      isWaitingRequest: false,

      // START:: HANDLING WORKING TIMES FORM VISIBILITY
      workingHoursFormIsOpen: false,
      // END:: HANDLING WORKING TIMES FORM VISIBILITY

      // START:: DAYS DATA
      weekDays: [
        {
          id: "sta",
          name: "Saturday",
          business_hours: [],
        },
        {
          id: "sun",
          name: "Sunday",
          business_hours: [],
        },
        {
          id: "mon",
          name: "Monday",
          business_hours: [],
        },
        {
          id: "tue",
          name: "Tuesday",
          business_hours: [],
        },
        {
          id: "wed",
          name: "Wednesday",
          business_hours: [],
        },
        {
          id: "thu",
          name: "Thursday",
          business_hours: [],
        },
        {
          id: "fri",
          name: "Friday",
          business_hours: [],
        },
      ],
      // END:: DAYS DATA

      // START:: SELECTED DAY TO ADD WORKING TIMES
      selectedDay: null,
      // END:: SELECTED DAY TO ADD WORKING TIMES
    };
  },

  computed: {
    ...mapGetters("apisGetsModule", ["preferencesList", "mainPreferencesList"]),
  },

  methods: {
    // START:: TOGGLE WORKING HOURS FORM
    toggleWorkingHoursForm() {
      this.workingHoursFormIsOpen = !this.workingHoursFormIsOpen;
    },
    // END:: TOGGLE WORKING HOURS FORM

    // START:: ADD BUSINESS HOURS
    selectDay(day_id) {
      // =========== OPEN WORKING HOURS FORM
      this.toggleWorkingHoursForm();

      let targetDay = this.weekDays.find((day) => day.id == day_id);
      this.selectedDay = targetDay;

      setTimeout(() => {
        let targetElement = document.getElementById(
          "business_hours_adding_inputs"
        );
        targetElement.scrollIntoView();
      }, 50);
    },

    addWorkingHours() {
      this.selectedDay.business_hours.push({
        id: Date.now(),
        from: null,
        to: null,
      });
    },
    // END:: ADD BUSINESS HOURS

    // START:: DELETE BUSINESS HOURS
    deleteBusinessHours(hours_id) {
      let targetHoursToDelete = this.selectedDay.business_hours.find(
        (element) => element.id == hours_id
      );
      let indexOfTargetHoursToDelete =
        this.selectedDay.business_hours.indexOf(targetHoursToDelete);
      this.selectedDay.business_hours.splice(indexOfTargetHoursToDelete, 1);
    },
    // END:: DELETE BUSINESS HOURS

    // START:: VALIDATE SECOND STEP FORM
    validateBusinessHoursForm() {
      this.isWaitingRequest = true;

      this.submitBusinessHoursForm();
    },
    // END:: VALIDATE SECOND STEP FORM

    // START:: SUBMIT SECOND STEP FORM
    submitBusinessHoursForm() {
      setTimeout(() => {
        this.isWaitingRequest = false;
        // this.$router.push("/business-hours");
      }, 2000);
    },
    // END:: SUBMIT SECOND STEP FORM
  },
};
</script>
