<template>
    <div class="restaurant_contact_info_form_wrapper">
        <!-- START:: LOGO WRAPPER -->
        <router-link to="/" class="logo_wrapper">
            <img
                src="../../../assets/media/logo/logo.png"
                alt="Logo"
                width="120"
                height="80"
            />
        </router-link>
        <!-- END:: LOGO WRAPPER -->

        <!-- START:: TITLE -->
        <div class="title_wrapper">
            <h3>{{ $t("titles.new_restaurant_register") }}</h3>
            <h5>{{ $t("titles.contact_info") }}</h5>
        </div>
        <!-- END:: TITLE -->

        <!-- START:: FORM -->
        <div class="form_wrapper">
            <form @submit.prevent="validateContactInfoForm">
                <!-- START:: RESTAURANT PHONE INPUT -->
                <div class="input_group">
                    <label for="business_phone">
                        <img
                            src="../../../assets/media/icons/phone.svg"
                            alt="icon"
                            width="25"
                            height="25"
                        />
                    </label>
                    <input
                        id="business_phone"
                        class="form-control custom_input"
                        type="tel"
                        :placeholder="$t('placeholders.business_phone')"
                        v-model.trim="contactInfoForm.businessPhone"
                    />
                </div>
                <!-- END:: RESTAURANT PHONE INPUT -->

                <!-- START:: RESTAURANT WEBSITE INPUT -->
                <div class="input_group">
                    <label for="restaurant_website">
                        <img
                            src="../../../assets/media/icons/chrome.svg"
                            alt="icon"
                            width="25"
                            height="25"
                        />
                    </label>
                    <input
                        id="restaurant_website"
                        class="form-control custom_input"
                        type="text"
                        :placeholder="$t('placeholders.website')"
                        v-model.trim="contactInfoForm.website"
                    />
                </div>
                <!-- END:: RESTAURANT WEBSITE INPUT -->

                <!-- START:: RESTAURANT EMAIL INPUT -->
                <div class="input_group">
                    <label for="restaurant_email">
                        <img
                            src="../../../assets/media/icons/at.svg"
                            alt="icon"
                            width="25"
                            height="25"
                        />
                    </label>
                    <input
                        id="restaurant_email"
                        class="form-control custom_input"
                        type="email"
                        :placeholder="$t('placeholders.email')"
                        v-model.trim="contactInfoForm.email"
                    />
                </div>
                <!-- END:: RESTAURANT EMAIL INPUT -->

                <!-- START:: REGION INPUT -->
                <div class="input_group">
                    <label for="region_select">
                        <img
                            src="../../../assets/media/icons/location.svg"
                            alt="icon"
                            width="25"
                            height="25"
                        />
                    </label>
                    <multiselect
                        id="region_select"
                        v-model="contactInfoForm.region"
                        track-by="name"
                        label="name"
                        :placeholder="$t('placeholders.region')"
                        :options="regionsList"
                        :searchable="true"
                        :allow-empty="true"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <!-- END:: REGION INPUT -->

                <!-- START:: SUBMIT BTN WRAPPER -->
                <div class="btn_wrapper">
                    <button class="main_btn">
                        {{ $t("buttons.continue") }}
                        <span class="btn_loader" v-if="isWaitingRequest"></span>
                    </button>
                </div>
                <!-- END:: SUBMIT BTN WRAPPER -->
            </form>
        </div>
        <!-- END:: FORM -->
    </div>
</template>

<script>
// START:: IMPORT VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORT VUEX HELPERS

export default {
    name: "ContactInfo",

    data() {
        return {
            isWaitingRequest: false,

            contactInfoForm: {
                businessPhone: null,
                website: null,
                email: null,
                region: null,
            },
        };
    },

    computed: {
        ...mapGetters("apisGetsModule", ["regionsList"]),
    },

    methods: {
        // START:: VALIDATE SECOND STEP FORM
        validateContactInfoForm() {
            this.isWaitingRequest = true;

            if (!this.contactInfoForm.businessPhone) {
                this.$message.error(
                    this.$t("validation.restaurant_phone_validation")
                );
                this.isWaitingRequest = false;
                return;
            } else if (!this.contactInfoForm.email) {
                this.$message.error(
                    this.$t("validation.restaurant_email_validation")
                );
                this.isWaitingRequest = false;
                return;
            } else if (!this.contactInfoForm.region) {
                this.$message.error(
                    this.$t("validation.select_region_validation")
                );
                this.isWaitingRequest = false;
                return;
            } else {
                this.submitContactInfoForm();
            }
        },
        // END:: VALIDATE SECOND STEP FORM

        // START:: SUBMIT SECOND STEP FORM
        submitContactInfoForm() {
            setTimeout(() => {
                this.isWaitingRequest = false;
                this.$router.push("/restaurant-category-and-price");
            }, 2000);
        },
        // END:: SUBMIT SECOND STEP FORM
    },
};
</script>
