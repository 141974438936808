<template>
    <div class="restaurant_contact_info_form_wrapper">
        <!-- START:: LOGO WRAPPER -->
        <router-link to="/" class="logo_wrapper">
            <img
                src="../../../assets/media/logo/logo.png"
                alt="Logo"
                width="120"
                height="80"
            />
        </router-link>
        <!-- END:: LOGO WRAPPER -->

        <!-- START:: TITLE -->
        <div class="title_wrapper">
            <h3>{{ $t("titles.new_restaurant_register") }}</h3>
            <h5>{{ $t("titles.category_and_price") }}</h5>
        </div>
        <!-- END:: TITLE -->

        <!-- START:: TITLE NOTE -->
        <div class="title_note_wrapper">
            <h5>Note: If You Are Not A Traditional Restaurant, It's OK!</h5>
            <p>
                We Have Many Different Types Of Business, Pop-up Restaurant,
                Event Venues, NightClubs, Comedy And Games Clubs, Country Clubs,
                Schools, Universities, Non-profits, And Even Retirement Homes
                Using Our Reservation System.
            </p>

            <p>
                Some Of The Settings May Not Apply To Your Type Of Business.
                Just Do Your Best And Complete The Setup Forms As If You Were A
                Restaurant. Also, In Some Sections Of Your Reservation System We
                Do Refer To Your Business As "Restaurant". We Are Aware Of That,
                And We Are Working On It.
            </p>
        </div>
        <!-- END:: TITLE NOTE -->

        <!-- START:: FORM -->
        <div class="form_wrapper">
            <form @submit.prevent="validateCategoryAndPriceForm">
                <!-- START:: BUSINESS CATEGORY INPUT -->
                <div class="inner_wrapper">
                    <div class="input_group">
                        <label for="category_select">
                            <img
                                src="../../../assets/media/icons/forkAndKnife.svg"
                                alt="icon"
                                width="25"
                                height="25"
                            />
                        </label>
                        <multiselect
                            id="category_select"
                            v-model="categoryAndPriceFormData.businessCategory"
                            track-by="name"
                            label="name"
                            :placeholder="$t('placeholders.business_category')"
                            :options="categoriesList"
                            :searchable="true"
                            :allow-empty="true"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="input_caption">
                        If You Are Not Sure, Please Select Casual Dining.
                    </div>
                </div>
                <!-- END:: BUSINESS CATEGORY INPUT -->

                <!-- START:: BUSINESS CATEGORY INPUT -->
                <div class="inner_wrapper">
                    <div class="input_group">
                        <label for="dresscode_select">
                            <img
                                src="../../../assets/media/icons/dress.svg"
                                alt="icon"
                                width="30"
                                height="30"
                            />
                        </label>
                        <multiselect
                            id="dresscode_select"
                            v-model="categoryAndPriceFormData.dressCode"
                            track-by="name"
                            label="name"
                            :placeholder="$t('placeholders.dress_code')"
                            :options="dressCodesList"
                            :searchable="true"
                            :allow-empty="true"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="input_caption">
                        If You Don't Have Any Specific Dress Code, Please Select
                        "Casual".
                    </div>
                </div>
                <!-- END:: BUSINESS CATEGORY INPUT -->

                <!-- START:: BUSINESS CURRENCY & PRICE INPUT -->
                <div class="inner_wrapper">
                    <div class="input_group">
                        <label>
                            <img
                                src="../../../assets/media/icons/dollar.svg"
                                alt="icon"
                                width="25"
                                height="25"
                            />
                        </label>
                        <!-- START:: CURRENCY SELECT -->
                        <multiselect
                            class="currency_select"
                            v-model="categoryAndPriceFormData.currency"
                            track-by="name"
                            label="name"
                            :placeholder="$t('placeholders.currency')"
                            :options="currenciesList"
                            :searchable="true"
                            :allow-empty="true"
                            :show-labels="false"
                        >
                        </multiselect>
                        <!-- END:: CURRENCY SELECT -->

                        <!-- START:: PRICE SELECT -->
                        <multiselect
                            v-model="categoryAndPriceFormData.price"
                            track-by="name"
                            label="name"
                            :placeholder="$t('placeholders.price')"
                            :options="pricesList"
                            :searchable="true"
                            :allow-empty="true"
                            :show-labels="false"
                        >
                        </multiselect>
                        <!-- END:: PRICE SELECT -->
                    </div>
                    <div class="input_caption">
                        What Is The Average Price For A 3 Course Meal.
                        (Appetizer, Main Course, Dessert). If Your Country
                        Currency Is Not Listed, Please Select "USD" (Or Euro)
                        And Select A Price Point Reflecting The Cost Of A Three
                        Course Meal In USD.
                    </div>
                </div>
                <!-- END:: BUSINESS CURRENCY & PRICE INPUT -->

                <!-- START:: TITLE -->
                <div class="title_wrapper my-5 py-5">
                    <h3>{{ $t("titles.payment_options") }}</h3>
                    <h5>{{ $t("titles.payment_options_subtitles") }}</h5>
                </div>
                <!-- END:: TITLE -->

                <!-- START:: CHECK BOXES WRAPPER -->
                <div class="row my-5">
                    <!-- START:: CHECKBOX INPUT GROUP -->
                    <div
                        class="col-6 col-lg-3 my-3"
                        v-for="paymentMethod in paymentMethodsList"
                        :key="paymentMethod.id"
                    >
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                :value="paymentMethod.id"
                                :id="'method_' + paymentMethod.id"
                                v-model="
                                    categoryAndPriceFormData.paymentMethods
                                "
                            />
                            <label
                                class="form-check-label"
                                :for="'method_' + paymentMethod.id"
                            >
                                {{ paymentMethod.name }}
                            </label>
                        </div>
                    </div>
                    <!-- END:: CHECKBOX INPUT GROUP -->
                </div>
                <!-- END:: CHECK BOXES WRAPPER -->

                <!-- START:: TITLE -->
                <div class="title_wrapper my-5 py-5">
                    <h3>{{ $t("titles.meal_types") }}</h3>
                    <h5>{{ $t("titles.meal_types_subtitles") }}</h5>
                </div>
                <!-- END:: TITLE -->

                <!-- START:: CHECK BOXES WRAPPER -->
                <div class="row my-5">
                    <!-- START:: CHECKBOX INPUT GROUP -->
                    <div
                        class="col-6 col-lg-3 my-3"
                        v-for="mealType in mealTypesList"
                        :key="mealType.id"
                    >
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                :value="mealType.id"
                                :id="'meal_' + mealType.id"
                                v-model="categoryAndPriceFormData.mealTypes"
                            />
                            <label
                                class="form-check-label"
                                :for="'meal_' + mealType.id"
                            >
                                {{ mealType.name }}
                            </label>
                        </div>
                    </div>
                    <!-- END:: CHECKBOX INPUT GROUP -->
                </div>
                <!-- END:: CHECK BOXES WRAPPER -->

                <!-- START:: SUBMIT BTN WRAPPER -->
                <div class="btn_wrapper">
                    <button class="main_btn">
                        {{ $t("buttons.continue") }}
                        <span class="btn_loader" v-if="isWaitingRequest"></span>
                    </button>
                </div>
                <!-- END:: SUBMIT BTN WRAPPER -->
            </form>
        </div>
        <!-- END:: FORM -->
    </div>
</template>

<script>
// START:: IMPORT VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORT VUEX HELPERS

export default {
    name: "CategoryAndPrice",

    data() {
        return {
            isWaitingRequest: false,

            categoryAndPriceFormData: {
                businessCategory: null,
                dressCode: null,
                currency: null,
                price: null,
                paymentMethods: [],
                mealTypes: [],
            },
        };
    },

    computed: {
        ...mapGetters("apisGetsModule", [
            "categoriesList",
            "dressCodesList",
            "currenciesList",
            "pricesList",
            "paymentMethodsList",
            "mealTypesList",
        ]),
    },

    methods: {
        // START:: VALIDATE SECOND STEP FORM
        validateCategoryAndPriceForm() {
            this.isWaitingRequest = true;

            if (!this.categoryAndPriceFormData.businessCategory) {
                this.$message.error(
                    this.$t("validation.business_category_validation")
                );
                this.isWaitingRequest = false;
                return;
            } else if (!this.categoryAndPriceFormData.dressCode) {
                this.$message.error(
                    this.$t("validation.dress_code_validation")
                );
                this.isWaitingRequest = false;
                return;
            } else if (!this.categoryAndPriceFormData.currency) {
                this.$message.error(this.$t("validation.currency_validation"));
                this.isWaitingRequest = false;
                return;
            } else if (!this.categoryAndPriceFormData.price) {
                this.$message.error(this.$t("validation.price_validation"));
                this.isWaitingRequest = false;
                return;
            } else if (
                this.categoryAndPriceFormData.paymentMethods.length == 0
            ) {
                this.$message.error(
                    this.$t("validation.payment_methods_validation")
                );
                this.isWaitingRequest = false;
                return;
            } else if (this.categoryAndPriceFormData.mealTypes.length == 0) {
                this.$message.error(
                    this.$t("validation.meal_types_validation")
                );
                this.isWaitingRequest = false;
                return;
            } else {
                this.submitCategoryAndPriceForm();
            }
        },
        // END:: VALIDATE SECOND STEP FORM

        // START:: SUBMIT SECOND STEP FORM
        submitCategoryAndPriceForm() {
            setTimeout(() => {
                this.isWaitingRequest = false;
                this.$router.push("/cuisines");
            }, 2000);
        },
        // END:: SUBMIT SECOND STEP FORM
    },
};
</script>
