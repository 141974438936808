<template>
    <div class="mySlider">
        <!-- Controls -->
        <div class="controls" v-if="arrows">
            <span @click="show_prev" class="arrow prev"
                ><i class="fas fa-chevron-left"></i
            ></span>
            <span @click="show_next" class="arrow next"
                ><i class="fas fa-chevron-right"></i
            ></span>
        </div>

        <vue-slick
            @beforeChange="beforeChange"
            ref="carousel"
            v-bind="settings"
        >
            <div v-for="slide in slides" :key="slide.id">
                <div class="text" v-if="renderComponent">
                    <div class="container">
                        <h2 class="h_2">{{ slide.info.heading_1 }}</h2>
                        <h2 class="h_2 thin">{{ slide.info.heading_2 }}</h2>
                        <p class="para">
                            {{ slide.info.para }}
                        </p>
                        <button class="main_button">Read More</button>
                    </div>
                </div>
                <img :src="slide.img" />

                <!-- Overlay If Nedded -->
                <div class="overlay"></div>
            </div>
        </vue-slick>
    </div>
</template>

<script>
export default {
    props: {
        arrows: {
            default: true,
            type: Boolean,
        },
    },

    data() {
        return {
            settings: {
                arrows: false,
                dots: true,
                fade: true,
                speed: 1500,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
            },
            renderComponent: true,

            // Data
            slides: [
                {
                    id: 1,
                    img: require("../../assets/media/slider/slider1.webp"),
                    info: {
                        heading_1: "Fresh Tomatoes_1",
                        heading_2: "Natural Farm Products",
                        para: "Natural organic tomatoes make your health stronger. Put your information here",
                    },
                },
                {
                    id: 2,
                    img: require("../../assets/media/slider/slider2.webp"),
                    info: {
                        heading_1: "Fresh Tomatoes_2",
                        heading_2: "Natural Farm Products",
                        para: "Natural organic tomatoes make your health stronger. Put your information here",
                    },
                },
                {
                    id: 3,
                    img: require("../../assets/media/slider/slider3.webp"),
                    info: {
                        heading_1: "Fresh Tomatoes_3",
                        heading_2: "Natural Farm Products",
                        para: "Natural organic tomatoes make your health stronger. Put your information here",
                    },
                },
            ],
        };
    },

    methods: {
        show_next() {
            this.$refs.carousel.next();
        },
        show_prev() {
            this.$refs.carousel.prev();
        },

        beforeChange() {
            this.renderComponent = false;

            this.$nextTick(() => {
                // Add the component back in
                this.renderComponent = true;
            });
        },
    },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap");

@keyframes toRight {
    0% {
        transform: translateX(-400px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.mySlider {
    font-family: "Work Sans", sans-serif;
    position: relative;

    // Your Controls
    .controls {
        @media (max-width: 992px) {
            display: none;
        }

        & > span.arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 35px;
            z-index: 5;
            transition: all 0.1s linear;
            color: #40a94457;
            cursor: pointer;

            &:hover {
                color: #40a944;
            }

            &.prev {
                left: 15px;
            }
            &.next {
                right: 15px;
            }

            i {
            }
        }
    }

    .slick-slider {
        // Default Controls
        .slick-arrow {
            &.slick-prev {
            }
            &.slick-next {
            }
        }
        ul.slick-dots {
            bottom: 15px;

            li {
                width: 15px;
                height: 15px;
                border: 2px solid #fff;
                border-radius: 50%;
                transition: all 0.1s linear;

                &:hover {
                    background-color: #40a944;
                    border: 2px solid #40a944;
                }

                &.slick-active {
                    background-color: #40a944;
                    border: 2px solid #40a944;
                }

                button {
                    &::before {
                        display: none;
                    }
                }
            }
        }

        .slick-list {
            .slick-track {
                .slick-slide {
                    // Active Slide
                    &.slick-active {
                    }

                    // Div The Carousel Create It
                    & > div {
                        // Your Code Goes Here
                        & > div {
                            height: 70vh;

                            .text {
                                color: #222222;
                                height: 100%;
                                position: relative;
                                z-index: 4;
                                display: flex;
                                align-items: center;
                                animation: toRight 1s ease-in-out;

                                .container {
                                    h2.h_2 {
                                        font-size: 38px;
                                        line-height: 40px;
                                        font-weight: 600;
                                        margin-bottom: 8px;

                                        &.thin {
                                            font-size: 30px;
                                            line-height: 30px;
                                            margin-bottom: 0;
                                            font-weight: 300;
                                            letter-spacing: 0.02em;
                                        }
                                    }

                                    p {
                                        width: 90%;
                                        line-height: 20px;
                                        font-size: 16px;
                                        padding: 18px 0 39px;
                                        margin-bottom: 0;
                                    }

                                    .main_button {
                                        font-size: 15px;
                                        font-weight: 400;
                                        padding: 0 25px;
                                        display: inline-block;
                                        text-transform: uppercase;
                                        border-radius: 10px;
                                        height: 44px;
                                        line-height: 44px;
                                        background: inherit;
                                        color: #fff;
                                        background: #40a944;
                                    }
                                }
                            }

                            img {
                                position: absolute;
                                inset: 0;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                z-index: 2;
                            }

                            .overlay {
                                position: absolute;
                                inset: 0;
                                z-index: 3;
                                background: linear-gradient(
                                    180deg,
                                    rgba(255, 255, 255, 0.356) 0%,
                                    rgba(0, 0, 0, 0.3) 99%
                                );
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
