<template>
  <header>
    <!-- START:: LOGO -->
    <router-link class="logo_wrapper" to="/">
      <img
        src="../../assets/media/logo/logo.png"
        alt="Logo"
        width="100"
        height="100"
      />
    </router-link>
    <!-- END:: LOGO -->

    <div class="header_action_btns_wrapper">
      <!-- START:: NAVBAR -->
      <ul class="navbar_wrapper">
        <li class="navbar_item">
          <router-link to="/"> {{ $t("navbar.home") }} </router-link>
        </li>

        <template v-if="!user">
          <li class="navbar_item" v-if="$route.name == 'CreateAccount'">
            <router-link to="/login">
              {{ $t("navbar.login") }}
            </router-link>
          </li>
          <li class="navbar_item" v-else>
            <router-link to="/register">
              {{ $t("navbar.create_account") }}
            </router-link>
          </li>
        </template>
        <template v-if="user">
          <li class="navbar_item not_active_link">
            <router-link
              to="/user-account"
              class="logo_wrapper user_avatar_wrapper not_active_link"
              style=""
            >
              <img
                :src="user.image"
                style="
                  background: none;
                  border: 1px solid #e2e2e2;
                  border-radius: 50%;
                "
                class="not_active_link"
                alt="Avatar"
                width="120"
                height="120"
              />
            </router-link>
          </li>
          <li class="navbar_item">
            <a href="javascript:;" @click="handleLogout">{{
              $t("navbar.logout")
            }}</a>
          </li>
        </template>
      </ul>
      <!-- END:: NAVBAR -->

      <!-- START:: LANGUAGE BUTTON -->
      <div class="language_btn_wrapper" @click="changeAppLanguage">
        <button class="language_btn">
          <img
            src="../../assets/media/icons/langs/ar.png"
            alt="Arabic Flag"
            width="35"
            height="35"
            v-if="localeGetter == 'en'"
          />

          <img
            src="../../assets/media/icons/langs/en.png"
            alt="Arabic Flag"
            width="35"
            height="35"
            v-else
          />
        </button>
      </div>
      <!-- END:: LANGUAGE BUTTON -->
    </div>
  </header>
</template>

<script>
// START:: IMPORT VUEX HELPERS
import { mapGetters, mapActions } from "vuex";
// END:: IMPORT VUEX HELPERS

export default {
  name: "TheHeader",

  computed: {
    ...mapGetters({ user: "auth/user" }),
    ...mapGetters(["localeGetter"]),
  },

  methods: {
    ...mapActions(["changeAppLanguage"]),
    handleLogout() {
      this.$store.dispatch("auth/logout", {
        message: this.$i18n.t("messages.logout"),
      });
      this.$message.success(this.$i18n.t("messages.logout"));
    },
  },
};
</script>
<style scoped>
.not_active_link::after {
  background-color: #fff !important;
}
</style>
