export default {
  all(state) {
    return state.branches;
  },
  details(state) {
    return state.details;
  },
  images(state) {
    return state.images;
  },
};
