<template>
  <div class="restaurant_setup_form_wrapper">
    <!-- START:: TITLE -->
    <div class="title_wrapper mb-3">
      <h3>{{ $t("titles.new_restaurant_setup") }}</h3>
    </div>
    <!-- END:: TITLE -->

    <!-- START:: FORM -->
    <div class="form_wrapper">
      <form @submit.prevent="validateRestaurantSetupForm">
        <!-- START:: LANGUAGE INPUT -->
        <div class="input_group">
          <label for="language_select">
            <img
              src="../../assets/media/icons/globe.svg"
              alt="icon"
              width="25"
              height="25"
            />
          </label>
          <multiselect
            id="language_select"
            v-model="restaurantSetupData.language"
            track-by="name"
            label="name"
            :placeholder="$t('placeholders.select_language')"
            :options="languagesList"
            :searchable="true"
            :allow-empty="true"
            :show-labels="false"
          >
          </multiselect>
        </div>
        <!-- END:: LANGUAGE INPUT -->

        <!-- START:: COUNTRY INPUT -->
        <div class="input_group">
          <label for="countries_select">
            <img
              src="../../assets/media/icons/flag.svg"
              alt="icon"
              width="25"
              height="25"
            />
          </label>
          <multiselect
            id="countries_select"
            v-model="restaurantSetupData.country"
            track-by="name"
            label="name"
            :placeholder="$t('placeholders.select_country')"
            :options="countriesList"
            :searchable="true"
            :allow-empty="true"
            :show-labels="false"
          >
          </multiselect>
        </div>
        <!-- END:: COUNTRY INPUT -->

        <!-- START:: TIME ZONE INPUT -->
        <div class="input_group">
          <label for="time_zone_select">
            <img
              src="../../assets/media/icons/clock.svg"
              alt="icon"
              width="25"
              height="25"
            />
          </label>
          <multiselect
            id="time_zone_select"
            v-model="restaurantSetupData.timeZone"
            track-by="name"
            label="name"
            :placeholder="$t('placeholders.time_zone')"
            :options="timeZonesList"
            :searchable="true"
            :allow-empty="true"
            :show-labels="false"
          >
          </multiselect>
        </div>
        <!-- END:: TIME ZONE INPUT -->

        <!-- START:: ROLE INPUT -->
        <div class="input_group">
          <label for="role_select">
            <img
              src="../../assets/media/icons/user.svg"
              alt="icon"
              width="25"
              height="25"
            />
          </label>
          <multiselect
            id="role_select"
            v-model="restaurantSetupData.role"
            track-by="name"
            label="name"
            :placeholder="$t('placeholders.role')"
            :options="rolesList"
            :searchable="true"
            :allow-empty="true"
            :show-labels="false"
          >
          </multiselect>
        </div>
        <!-- END:: ROLE INPUT -->

        <!-- START:: SUBMIT BTN WRAPPER -->
        <div class="btn_wrapper">
          <button class="main_btn">
            {{ $t("buttons.edit") }}
            <span class="btn_loader" v-if="isWaitingRequest"></span>
          </button>
        </div>
        <!-- END:: SUBMIT BTN WRAPPER -->
      </form>
    </div>
    <!-- END:: FORM -->
  </div>
</template>

<script>
// START:: IMPORT VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORT VUEX HELPERS

export default {
  name: "UpdateSetupDataForm",

  computed: {
    ...mapGetters("apisGetsModule", [
      "languagesList",
      "countriesList",
      "timeZonesList",
      "rolesList",
    ]),
  },

  data() {
    return {
      isWaitingRequest: false,

      // START:: RESTAURANT  SETUP DATA
      restaurantSetupData: {
        language: {
          id: 2,
          name: "English",
        },
        country: {
          id: 1,
          name: "Country 1",
        },
        timeZone: {
          id: 2,
          name: "Time Zone 2",
        },
        role: {
          id: 1,
          name: "Role 1",
        },
      },
      // END:: RESTAURANT  SETUP DATA
    };
  },

  methods: {
    // START:: VALIDATE FIRST STEP FORM
    validateRestaurantSetupForm() {
      this.isWaitingRequest = true;

      if (!this.restaurantSetupData.language) {
        this.$message.error(this.$t("validation.select_language_validation"));
        this.isWaitingRequest = false;
        return;
      } else if (!this.restaurantSetupData.country) {
        this.$message.error(this.$t("validation.select_country_validation"));
        this.isWaitingRequest = false;
        return;
      } else if (!this.restaurantSetupData.timeZone) {
        this.$message.error(this.$t("validation.select_time_zone_validation"));
        this.isWaitingRequest = false;
        return;
      } else if (!this.restaurantSetupData.role) {
        this.$message.error(this.$t("validation.select_role_validation"));
        this.isWaitingRequest = false;
        return;
      } else {
        this.submitRestaurantSetupForm();
      }
    },
    // END:: VALIDATE FIRST STEP FORM

    // START:: SUBMIT FIRST STEP FORM
    submitRestaurantSetupForm() {
      setTimeout(() => {
        this.isWaitingRequest = false;
      }, 2000);
    },
    // END:: SUBMIT FIRST STEP FORM
  },
};
</script>
