<template>
  <div class="scale mt-2">
    <!-- START:: ONE PEPPER -->
    <span
      class="d-flex justify-content-start align-items-center"
      v-if="scale == '1'"
    >
      <i class="fas fa-pepper-hot"></i>
      <i class="fal fa-pepper-hot"></i>
      <i class="fal fa-pepper-hot"></i>
      <i class="fal fa-pepper-hot"></i>
      <i class="fal fa-pepper-hot"></i>
    </span>
    <!-- END:: ONE PEPPER -->

    <!-- START:: TWO PEPPERS -->
    <span
      class="d-flex justify-content-start align-items-center"
      v-if="scale == '2'"
    >
      <i class="fas fa-pepper-hot"></i>
      <i class="fas fa-pepper-hot"></i>
      <i class="fal fa-pepper-hot"></i>
      <i class="fal fa-pepper-hot"></i>
      <i class="fal fa-pepper-hot"></i>
    </span>
    <!-- END:: TWO PEPPERS -->

    <!-- START:: THREE PEPPERS -->
    <span
      class="d-flex justify-content-start align-items-center"
      v-if="scale == '3'"
    >
      <i class="fas fa-pepper-hot"></i>
      <i class="fas fa-pepper-hot"></i>
      <i class="fas fa-pepper-hot"></i>
      <i class="fal fa-pepper-hot"></i>
      <i class="fal fa-pepper-hot"></i>
    </span>
    <!-- END:: THREE PEPPERS -->

    <!-- START:: FOUR PEPPERS -->
    <span
      class="d-flex justify-content-start align-items-center"
      v-if="scale == '4'"
    >
      <i class="fas fa-pepper-hot"></i>
      <i class="fas fa-pepper-hot"></i>
      <i class="fas fa-pepper-hot"></i>
      <i class="fas fa-pepper-hot"></i>
      <i class="fal fa-pepper-hot"></i>
    </span>
    <!-- END:: FOUR PEPPERS -->

    <!-- START:: FIVE PEPPERS -->
    <span
      class="d-flex justify-content-start align-items-center"
      v-if="scale == '5'"
    >
      <i class="fas fa-pepper-hot"></i>
      <i class="fas fa-pepper-hot"></i>
      <i class="fas fa-pepper-hot"></i>
      <i class="fas fa-pepper-hot"></i>
      <i class="fas fa-pepper-hot"></i>
    </span>
    <!-- END:: FIVE PEPPERS -->
  </div>
</template>

<script>
export default {
  props: ["scale"],
};
</script>

<style lang="scss" scoped>
.scale {
  svg,
  i {
    margin-inline-end: 5px;
    color: #db1d27;
    font-size: 20px;
  }
}
</style>
