<template>
  <div class="branch_info_tab_wrapper">
    <VerticalTabs :VerticalTabsContent="verticalTabsContent">
      <!-- START:: SETUP DATA TAB -->
      <template v-slot:setup_data>
        <div class="vertical_tab_content_wrapper auth_forms_wrapper">
          <UpdateSetupDataForm />
        </div>
      </template>
      <!-- END:: SETUP DATA TAB -->

      <!-- START:: NAME AND ADDRESS TAB -->
      <template v-slot:name_and_address>
        <div class="vertical_tab_content_wrapper auth_forms_wrapper">
          <UpdateNameAndAddressForm />
        </div>
      </template>
      <!-- END:: NAME AND ADDRESS TAB -->

      <!-- START:: CONTACT INFO TAB -->
      <template v-slot:contact_info>
        <div class="vertical_tab_content_wrapper auth_forms_wrapper">
          <UpdateContactInfoForm />
        </div>
      </template>
      <!-- END:: CONTACT INFO TAB -->

      <!-- START:: CATEGORY AND PRICE TAB -->
      <template v-slot:category_and_price>
        <div class="vertical_tab_content_wrapper auth_forms_wrapper">
          <UpdateCategoryAndPriceForm />
        </div>
      </template>
      <!-- END:: CATEGORY AND PRICE TAB -->

      <!-- START:: CUISINES TAB -->
      <template v-slot:cuisines>
        <div class="vertical_tab_content_wrapper auth_forms_wrapper">
          <UpdateCuisinesForm />
        </div>
      </template>
      <!-- END:: CUISINES TAB -->

      <!-- START:: FEATURES AND OCCASIONS TAB -->
      <template v-slot:features_and_occasion>
        <div class="vertical_tab_content_wrapper auth_forms_wrapper">
          <UpdateFeaturesAndOccasionsForm />
        </div>
      </template>
      <!-- END:: FEATURES AND OCCASIONS TAB -->

      <!-- START:: BOOKING SYSTEM ENGINE TAB -->
      <template v-slot:booking_system_engine>
        <div class="vertical_tab_content_wrapper auth_forms_wrapper">
          <UpdateBookingEngineSystemForm />
        </div>
      </template>
      <!-- END:: BOOKING SYSTEM ENGINE TAB -->

      <!-- START:: DASHBOARD SETTINGS TAB -->
      <template v-slot:dashboard_settings>
        <div class="vertical_tab_content_wrapper auth_forms_wrapper">
          <UpdateDashboardSettingsForm />
        </div>
      </template>
      <!-- END:: DASHBOARD SETTINGS TAB -->

      <!-- START:: NOTIFICATIONS SETTINGS TAB -->
      <template v-slot:notifications_settings>
        <div class="vertical_tab_content_wrapper auth_forms_wrapper">
          <UpdatePhoneAndEmailNotificationsForm />
        </div>
      </template>
      <!-- END:: NOTIFICATIONS SETTINGS TAB -->

      <!-- START:: DASHBOARD PREFERENCES TAB -->
      <template v-slot:dashboard_preferences>
        <div class="vertical_tab_content_wrapper auth_forms_wrapper">
          <UpdateDashboardPreferencesForm />
        </div>
      </template>
      <!-- END:: DASHBOARD PREFERENCES TAB -->

      <!-- START:: BUSINESS HOURS TAB -->
      <template v-slot:business_hours>
        <div class="vertical_tab_content_wrapper auth_forms_wrapper">
          <UpdateBusinessHoursForm />
        </div>
      </template>
      <!-- END:: BUSINESS HOURS TAB -->
    </VerticalTabs>
  </div>
</template>

<script>
// START:: IMPORTING CUSTOM COMPS
import VerticalTabs from "../Global/VerticalTabs.vue";
// END:: IMPORTING TABS COMP

// START:: IMPORTING PROFILE DATA FORMS
import UpdateSetupDataForm from "../branchProfileUpdateForms/UpdateSetupDataForm.vue";
import UpdateNameAndAddressForm from "../branchProfileUpdateForms/UpdateNameAndAddressForm.vue";
import UpdateContactInfoForm from "../branchProfileUpdateForms/UpdateContactInfoForm.vue";
import UpdateCategoryAndPriceForm from "../branchProfileUpdateForms/UpdateCategoryAndPriceForm.vue";
import UpdateCuisinesForm from "../branchProfileUpdateForms/UpdateCuisinesForm.vue";
import UpdateFeaturesAndOccasionsForm from "../branchProfileUpdateForms/UpdateFeaturesAndOccasionsForm.vue";
import UpdateBookingEngineSystemForm from "../branchProfileUpdateForms/UpdateBookingEngineSystemForm.vue";
import UpdateDashboardSettingsForm from "../branchProfileUpdateForms/UpdateDashboardSettingsForm.vue";
import UpdatePhoneAndEmailNotificationsForm from "../branchProfileUpdateForms/UpdatePhoneAndEmailNotificationsForm.vue";
import UpdateDashboardPreferencesForm from "../branchProfileUpdateForms/UpdateDashboardPreferencesForm.vue";
import UpdateBusinessHoursForm from "../branchProfileUpdateForms/UpdateBusinessHoursForm.vue";
// END:: IMPORTING PROFILE DATA FORMS

export default {
  name: "BranchInfoTab",

  components: {
    VerticalTabs,
    UpdateSetupDataForm,
    UpdateNameAndAddressForm,
    UpdateContactInfoForm,
    UpdateCategoryAndPriceForm,
    UpdateCuisinesForm,
    UpdateFeaturesAndOccasionsForm,
    UpdateBookingEngineSystemForm,
    UpdateDashboardSettingsForm,
    UpdatePhoneAndEmailNotificationsForm,
    UpdateDashboardPreferencesForm,
    UpdateBusinessHoursForm,
  },

  data() {
    return {
      // START:: TABS DATA
      verticalTabsContent: [
        {
          tab: "Setup Data",
          key_name: "setup_data",
        },
        {
          tab: "Name And Address",
          key_name: "name_and_address",
        },
        {
          tab: "Contact Info",
          key_name: "contact_info",
        },
        {
          tab: "Category And Price",
          key_name: "category_and_price",
        },
        {
          tab: "Cuisines",
          key_name: "cuisines",
        },
        {
          tab: "Features & Occasion",
          key_name: "features_and_occasion",
        },
        {
          tab: "Booking System Engine",
          key_name: "booking_system_engine",
        },
        {
          tab: "Dashboard Settings",
          key_name: "dashboard_settings",
        },
        {
          tab: "Notifications Settings",
          key_name: "notifications_settings",
        },
        {
          tab: "Dashboard Preferences",
          key_name: "dashboard_preferences",
        },
        {
          tab: "Business Hours",
          key_name: "business_hours",
        },
      ],
      // END:: TABS DATA
    };
  },
};
</script>
