import mutations from "./mutations.js";
import * as actions from "./actions";
// import getters from "./getters.js";

export default {
  namespaced: true,
  state: {
    user: localStorage.getItem("lime_user")
      ? JSON.parse(localStorage.getItem("lime_user"))
      : null,
    token: localStorage.getItem("lime_token"),
  },
  mutations: mutations,
  actions: {
    ...actions,
  },
  getters: {
    user(state) {
      return state.user;
    },
    token(state) {
      return state.token;
    },
  },
};
