<template>
  <div class="branch_profile_wrapper">
    <!-- START:: BRANCH NAME WRAPPER -->
    <div class="branch_name_wrapper">
      <h2>{{ details.name }}</h2>
      <span class="title_backdrop"> {{ details.name }} </span>
    </div>
    <!-- END:: BRANCH NAME WRAPPER -->

    <div class="container">
      <!-- START:: TABS -->
      <Tabs :tabsContent="tabsContent">
        <!-- START:: CHARTS TAB -->
        <template v-slot:branch_charts>
          <BranchImageTab />
        </template>
        <!-- END:: CHARTS TAB -->

        <!-- START:: BRANCH INFO TAB -->
        <template v-slot:branch_info>
          <BranchInfoTabContent />
        </template>
        <!-- END:: BRANCH INFO TAB -->

        <!-- START:: MENU TAB -->
        <template v-slot:menu>
          <MenuTabContent />
        </template>
        <!-- START:: MENU TAB -->

        <!-- START:: ORDERS TAB -->
        <template v-slot:orders>
          <OrdersTabContent />
        </template>
        <!-- END:: ORDERS TAB -->

        <!-- START:: TABLES TAB -->
        <!-- <template v-slot:tables>
          <TablesTabContent />
        </template> -->
        <!-- END:: TABLES TAB -->

        <!-- START:: CHATS TAB -->
        <!-- <template v-slot:chats>
          <ChatsTabContent />
        </template> -->
        <!-- END:: CHATS TAB -->

        <!-- START:: REVIEWS TAB -->
        <template v-slot:reviews>
          <div class="reviews_data_wrapper">
            <ReviewsTabContent />
          </div>
        </template>
        <!-- END:: REVIEWS TAB -->
      </Tabs>
      <!-- END:: TABS -->
    </div>
  </div>
</template>

<script>
// START:: IMPORTING CUSTOM COMPS
import Tabs from "../components/Global/Tabs.vue";
// END:: IMPORTING TABS COMP
import axios from "axios";

// START:: IMPORTING TABS COMPONENTS
import BranchImageTab from "../components/branchProfileTabs/BranchImageTab.vue";
import BranchInfoTabContent from "../components/branchProfileTabs/BranchInfoTab.vue";
import MenuTabContent from "../components/branchProfileTabs/MenuTab.vue";
import OrdersTabContent from "../components/branchProfileTabs/OrdersTab.vue";
// import TablesTabContent from "../components/branchProfileTabs/TablesTab.vue";
import ReviewsTabContent from "../components/branchProfileTabs/ReviewsTab.vue";
// import ChatsTabContent from "../components/branchProfileTabs/ChatsTab.vue";
// END:: IMPORTING TABS COMPONENTS
import { mapGetters } from "vuex";

export default {
  name: "BranchProfile",

  components: {
    Tabs,
    BranchImageTab,
    BranchInfoTabContent,
    MenuTabContent,
    OrdersTabContent,
    // TablesTabContent,
    // ChatsTabContent,
    ReviewsTabContent,
  },

  data() {
    return {
      isWaitingRequest: false,

      // START:: TABS DATA
      tabsContent: [
        {
          tab: this.$t("tabs.branch_images"),
          // tabImage: tabImage,
          key_name: "branch_charts",
        },
        {
          tab: this.$t("tabs.branch_info"),
          key_name: "branch_info",
        },
        {
          tab: this.$t("tabs.menu"),
          key_name: "menu",
        },
        {
          tab: this.$t("tabs.orders"),
          key_name: "orders",
        },
        // {
        //   tab: this.$t("tabs.tables"),
        //   key_name: "tables",
        // },
        // {
        //   tab: this.$t("tabs.chats"),
        //   key_name: "chats",
        // },
        {
          tab: this.$t("tabs.reviews"),
          key_name: "reviews",
        },
      ],
      // END:: TABS DATA
    };
  },
  computed: {
    ...mapGetters({
      details: "branch/details",
    }),
  },
  methods: {
    async getBranch() {
      try {
        const response = await axios.get("branch/" + this.$route.params.id);
        console.log(response.data.data);
        this.$store.dispatch("branch/branchDetail", response);
      } catch (error) {
        console.log(error.response);
      }
    },
  },
  mounted() {
    this.getBranch();
  },
};
</script>
