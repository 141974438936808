<template>
  <div class="create_account_form_wrapper">
    <!-- START:: LOGO WRAPPER -->
    <div class="row" style="min-height: 50px"></div>
    <router-link to="/" class="logo_wrapper">
      <img
        src="../../../assets/media/logo/logo.png"
        alt="Logo"
        width="120"
        height="150"
      />
    </router-link>
    <!-- END:: LOGO WRAPPER -->
    <div class="row" style="min-height: 100px"></div>
    <!-- START:: TITLE -->
    <div class="title_wrapper">
      <h3>{{ $t("titles.account_login") }}</h3>
      <!-- <h5>{{ $t("titles.new_restaurant_setup") }}</h5> -->
    </div>
    <!-- END:: TITLE -->
    <div class="row" style="min-height: 50px"></div>
    <!-- START:: FORM -->
    <div class="form_wrapper">
      <form @submit.prevent="validateCreateAccFormInputs">
        <!-- START:: EMAIL INPUT -->
        <div class="input_group">
          <label for="email">
            <img
              src="../../../assets/media/icons/at.svg"
              alt="icon"
              width="25"
              height="25"
            />
          </label>
          <input
            id="email"
            class="form-control custom_input"
            type="email"
            :placeholder="$t('placeholders.email')"
            v-model.trim="createAccountData.email"
          />
        </div>
        <!-- END:: EMAIL INPUT -->

        <!-- START:: PASSWORD INPUT -->
        <div class="input_group">
          <label for="password">
            <img
              src="../../../assets/media/icons/lock.svg"
              alt="icon"
              width="25"
              height="25"
            />
          </label>
          <input
            id="password"
            class="form-control custom_input"
            :type="passwordInputIsVisible ? 'text' : 'password'"
            :placeholder="$t('placeholders.password')"
            v-model.trim="createAccountData.password"
          />
          <button
            type="button"
            class="pass_visibility_btn"
            @click="changePassVisibility('password')"
          >
            <img
              src="../../../assets/media/icons/eye.svg"
              alt="icon"
              width="25"
              height="25"
              v-if="!passwordInputIsVisible"
            />
            <img
              src="../../../assets/media/icons/eyeOff.svg"
              alt="icon"
              width="25"
              height="25"
              v-else
            />
          </button>
        </div>
        <!-- END:: PASSWORD INPUT -->

        <!-- START:: SUBMIT BTN WRAPPER -->
        <div class="btn_wrapper">
          <button class="main_btn">
            {{ $t("buttons.submit") }}
            <span class="btn_loader" v-if="isWaitingRequest"></span>
          </button>
        </div>
        <!-- END:: SUBMIT BTN WRAPPER -->
      </form>
    </div>
    <!-- END:: FORM -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateAccount",

  data() {
    return {
      // START:: BUTTON LOADER HANDLING DATA
      isWaitingRequest: false,
      // END:: BUTTON LOADER HANDLING DATA

      // START:: PASSWORD INPUTS VISIBILITY  HANDLING DATA
      passwordInputIsVisible: false,
      passwordConfirmationInputIsVisible: false,
      // END:: PASSWORD INPUTS VISIBILITY  HANDLING DATA

      // START:: CREATE ACCOUNT DATA
      createAccountData: {
        email: null,
        password: null,
      },
      // END:: CREATE ACCOUNT DATA
    };
  },

  methods: {
    // START:: CHANGE PASSWORDS INPUT VISIBILITY
    changePassVisibility(id) {
      if (id == "password") {
        this.passwordInputIsVisible = !this.passwordInputIsVisible;
      } else if (id == "confirm_password") {
        this.passwordConfirmationInputIsVisible =
          !this.passwordConfirmationInputIsVisible;
      }
    },
    // END:: CHANGE PASSWORDS INPUT VISIBILITY

    // START:: SELECT UPLOADED IMAGE
    selectUploadedImage(e) {
      console.log(e.target);
      this.createAccountData.avatar.bath = URL.createObjectURL(
        e.target.files[0]
      );
      this.createAccountData.avatar.file = e.target.files[0];
    },
    // END:: SELECT UPLOADED IMAGE

    // START:: VALIDATE CREATE ACCOUNT FORM INPUTS
    validateCreateAccFormInputs() {
      this.isWaitingRequest = false;

      if (!this.createAccountData.email) {
        this.$message.error(this.$t("validation.email_validation"));
        this.isWaitingRequest = false;
        return;
      } else if (!this.createAccountData.password) {
        this.$message.error(this.$t("validation.password_validation"));
        this.isWaitingRequest = false;
        return;
      } else if (this.createAccountData.password.length < 6) {
        this.$message.error(this.$t("validation.password_length_validation"));
        this.isWaitingRequest = false;
        return;
      } else {
        this.validateCreateAccForm();
      }
    },
    // END:: VALIDATE CREATE ACCOUNT FORM INPUTS

    // START:: SUBMIT CREATE ACCOUNT FORM
    async validateCreateAccForm() {
      try {
        this.isWaitingRequest = true;
        let data = new FormData();
        data.append("email", this.createAccountData.email);
        data.append("password", this.createAccountData.password);
        const response = await axios.post("login", data);
        this.$store.dispatch("auth/login", response);
        console.log(response);
        this.$router.push("/user-account");
      } catch (error) {
        console.log(error.response);
        this.$message.error(error.response.data.message);
        this.isWaitingRequest = false;
      }
    },
    // END:: SUBMIT CREATE ACCOUNT FORM
  },
};
</script>
