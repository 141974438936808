export default {
    // START:: LANGUAGE LIST GETTER
    languagesList(state) {
        return state.languages;
    },
    // END:: LANGUAGE LIST GETTER

    // START:: COUNTRIES LIST GETTER
    countriesList(state) {
        return state.countries;
    },
    // END:: COUNTRIES LIST GETTER

    // START:: IME ZONES LIST GETTER
    timeZonesList(state) {
        return state.time_zones;
    },
    // END:: IME ZONES LIST GETTER

    // START:: ROLES LIST GETTER
    rolesList(state) {
        return state.roles;
    },
    // END:: ROLES LIST GETTER

    // START:: CITES LIST GETTER
    citiesList(state) {
        return state.cities;
    },
    // END:: CITES LIST GETTER

    // START:: REGIONS LIST GETTER
    regionsList(state) {
        return state.region;
    },
    // END:: REGIONS LIST GETTER

    // START:: CATEGORIES LIST GETTER
    categoriesList(state) {
        return state.categories;
    },
    // END:: CATEGORIES LIST GETTER

    // START:: CATEGORIES LIST GETTER
    dressCodesList(state) {
        return state.dressCodes;
    },
    // END:: CATEGORIES LIST GETTER

    // START:: CURRENCIES LIST GETTER
    currenciesList(state) {
        return state.currencies;
    },
    // END:: CURRENCIES LIST GETTER

    // START:: PRICES LIST GETTER
    pricesList(state) {
        return state.prices;
    },
    // END:: PRICES LIST GETTER

    // START:: PAYMENT METHODS LIST GETTER
    paymentMethodsList(state) {
        return state.paymentMethods;
    },
    // END:: PAYMENT METHODS LIST GETTER

    // START:: MEAL TYPES LIST GETTER
    mealTypesList(state) {
        return state.mealTypes;
    },
    // END:: MEAL TYPES LIST GETTER

    // START:: CUISINES LIST GETTER
    cuisinesList(state) {
        return state.cuisines;
    },
    // END:: CUISINES LIST GETTER

    // START:: ADDITIONAL CUISINES LIST GETTER
    additionalCuisinesList(state) {
        return state.additionalCuisines;
    },
    // END:: ADDITIONAL CUISINES LIST GETTER

    // START:: FEATURES LIST GETTER
    featuresList(state) {
        return state.features;
    },
    // END:: FEATURES LIST GETTER

    // START:: OCCASIONS LIST GETTER
    occasionsList(state) {
        return state.occasions;
    },
    // END:: OCCASIONS LIST GETTER

    // START:: PARKING OPTIONS LIST GETTER
    parkingOptionsList(state) {
        return state.parkingOptions;
    },
    // END:: PARKING OPTIONS LIST GETTER

    // START:: ADVANCES DAYS LIST GETTER
    advancesDaysList(state) {
        return state.advancesDays;
    },
    // END:: ADVANCES DAYS LIST GETTER

    // START:: ADVANCES TIMES LIST GETTER
    advancesTimesList(state) {
        return state.advancesTimes;
    },
    // END:: ADVANCES TIMES LIST GETTER

    // START:: MINIMUM GUESTS LIST GETTER
    minimumGuestsList(state) {
        return state.minimumGuests;
    },
    // END:: MINIMUM GUESTS LIST GETTER

    // START:: MAXIMUM GUESTS LIST GETTER
    maximumGuestsList(state) {
        return state.maximumGuests;
    },
    // END:: MAXIMUM GUESTS LIST GETTER

    // START:: DASHBOARD VIEW GETTER
    dashboardViewsList(state) {
        return state.dashboardViews;
    },
    // END:: DASHBOARD VIEW GETTER

    // START:: MAIN PREFERENCES GETTER
    mainPreferencesList(state) {
        return state.mainPreferences;
    },
    // END:: MAIN PREFERENCES GETTER

    // START:: PREFERENCES GETTER
    preferencesList(state) {
        return state.preferences;
    },
    // END:: PREFERENCES GETTER
};
