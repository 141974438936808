<template>
  <v-app class="my_app">
    <!-- START:: HEADER -->
    <!-- <TheNavbar v-if="currentRoutePath != '/'" /> -->
    <TheHeader v-if="currentRoutePath != '/'" />
    <!-- END:: HEADER -->

    <!-- START:: ROUTER VIEW  -->
    <transition name="fade" mode="out-in">
      <router-view :key="$router.path" />
    </transition>
    <!-- START:: ROUTER VIEW  -->

    <!-- START:: FOOTER -->
    <!-- <TheFooter v-if="currentRoutePath != '/'" /> -->
    <!-- END:: FOOTER -->
  </v-app>
</template>

<script>
// START:: IMPORTING LAYOUTS
import TheHeader from "./components/layouts/TheHeader.vue";

// import TheNavbar from "./components/layouts/Navbar/TheNav.vue";
// import TheFooter from "./components/layouts/TheFooter.vue";
// END:: IMPORTING LAYOUTS

// START:: IMPORT VUEX HELPERS
import { mapActions } from "vuex";
// END:: IMPORT VUEX HELPERS

export default {
  name: "App",

  // START:: VUE-META
  metaInfo: {
    title: "Lime",
    meta: [
      {
        name: "description",
        content: "Lime App Description Will Go Here",
      },
    ],
  },
  // END:: VUE-META

  components: {
    TheHeader,
    // TheNavbar,
    // TheFooter,
  },

  data() {
    return {
      // START:: CURRENT ROUTE PATH DATA
      // currentRoutePath: this.$route.path,
      // END:: CURRENT ROUTE PATH DATA
    };
  },

  computed: {
    currentRoutePath() {
      return this.$route.path;
    },
  },

  methods: {
    ...mapActions(["handelAppDefaultLanguage"]),
  },

  created() {
    this.handelAppDefaultLanguage();
  },

  mounted() {},
};
</script>
