<template>
  <div class="landing_page_wrapper">
    <!-- START:: LANDING NAVBAR -->
    <LandingNav :logo="landing_data.logo" />
    <!-- END:: LANDING NAVBAR -->

    <!-- START:: LANDING CONTENT -->
    <main class="landing_content">
      <!-- START:: HERO SECTION -->
      <LandingHero
        :main_image="landing_data.main_image"
        :home_msg="landing_data.home_msg"
        :title="landing_data.title"
        :title_desc="landing_data.title_desc"
      />
      <!-- END:: HERO SECTION -->

      <!-- START:: ABOUT SECTION -->
      <LandingAbout
        :about_title="landing_data.about_title"
        :about_desc="landing_data.about_desc"
        :about_image="landing_data.about_image"
      />
      <!-- END:: ABOUT SECTION -->

      <!-- START:: LANDING APP SCREENS -->
      <LandingAppScreens :screens="landing_data.screens" />
      <!-- END:: LANDING APP SCREENS -->

      <!-- START:: DOWNLOAD APP -->
      <LandingDownloadApp
        :app_image="landing_data.app_image"
        :app_store_app="landing_data.app_store_app"
        :g_play_app="landing_data.g_play_app"
        :downaload_title="landing_data.downaload_title"
      />
      <!-- END:: DOWNLOAD APP -->

      <!-- START:: LANDING TESTIMONIALS -->
      <!-- <LandingTestimonials /> -->
      <!-- END:: LANDING TESTIMONIALS -->

      <!-- START:: LANDING CUSTOMERS -->
      <LandingCustomers :customers="landing_data.customers" />
      <!-- END:: LANDING CUSTOMERS -->
    </main>
    <!-- END:: LANDING CONTENT -->

    <!-- START:: LANDING FOOTER -->
    <LandingFooter
      :logo="landing_data.logo"
      :facebook="landing_data.facebook"
      :instagram="landing_data.instagram"
      :twitter="landing_data.twitter"
      :youtube="landing_data.youtube"
    />
    <!-- END:: LANDING FOOTER -->
  </div>
</template>

<script>
import axios from "axios";

// START:: IMPORTING LANDING LAYOUTS
import LandingNav from "../../components/layouts/Landing/LandingHeader.vue";
import LandingFooter from "../../components/layouts/Landing/LandingFooter.vue";
// END:: IMPORTING LANDING LAYOUTS

// START:: IMPORTING LANDING COMPONENTS
import LandingHero from "../../components/Landing/LandingHero.vue";
import LandingAbout from "../../components/Landing/LandingAbout.vue";
import LandingAppScreens from "../../components/Landing/LandingAppScreens.vue";
import LandingDownloadApp from "../../components/Landing/LandingDownloadApp.vue";
// import LandingTestimonials from "../../components/Landing/LandingTestimonials.vue";
import LandingCustomers from "../../components/Landing/LandingCustomers.vue";
// END:: IMPORTING LANDING COMPONENTS

export default {
  name: "TheLanding",
  data() {
    return {
      landing_data: null,
    };
  },
  components: {
    LandingNav,
    LandingFooter,
    LandingHero,
    LandingAbout,
    LandingAppScreens,
    LandingDownloadApp,
    // LandingTestimonials,
    LandingCustomers,
  },

  methods: {
    // START:: HANDLING LANDING NAVBAR
    handelLandingNavPosition() {
      window.addEventListener("scroll", function () {
        const NAV_ELEMENT = document.querySelector(".landing_nav");
        NAV_ELEMENT.classList.toggle("test_class", window.scrollY > 0);
        console.log(NAV_ELEMENT);
      });
    },
    // END:: HANDLING LANDING NAVBAR
    async getData() {
      try {
        const response = await axios.get("landing_data");
        console.log(response);
        this.landing_data = response.data.data;
      } catch (error) {
        console.log(error.response);
      }
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
