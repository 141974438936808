<template>
  <div class="orders_data_wrapper">
    <h3>Branch Orders</h3>

    <!-- START:: ORDERS TABLE -->
    <OrdersTable
      :search="orderSearch"
      :headers="orderTableHeaders"
      :items="orders"
    />
    <!-- END:: ORDERS TABLE -->
  </div>
</template>

<script>
// START:: IMPORTING DATA TABLE
import OrdersTable from "../Global/DataTable.vue";
// END:: IMPORTING DATA TABLE
import axios from "axios";

// import orderImage from "../../assets/media/imgs/hero_img.png";

export default {
  name: "OrdersTab",

  components: {
    OrdersTable,
  },

  data() {
    return {
      // START:: ORDER TABLE DATA
      orderSearch: "",
      orderTableHeaders: [
        { text: "#", align: "center", value: "sort" },
        {
          text: "رقم الحجز",
          align: "center",
          sortable: false,
          value: "order_number",
        },
        { text: "صاحب الحجز", value: "client", align: "center" },
        { text: "عدد الأشخاص", value: "quantity", align: "center" },
        { text: "التاريخ", value: "date", align: "center" },
        { text: "الوقت", value: "time", align: "center" },
        {
          text: this.$t("placeholders.actions"),
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      orders: [
        {
          sort: 1,
          order_number: 44,
          client: "Mahmoud Siliman",
          quantity: 5,
          date: "15/1/2022",
          time: "01 : 15 PM",
        },
        {
          sort: 2,
          order_number: 55,
          client: "Mahmoud Siliman",
          quantity: 2,
          date: "14/1/2022",
          time: "01 : 15 PM",
        },
        {
          sort: 3,
          order_number: 221,
          client: "Mahmoud Siliman",
          quantity: 1,
          date: "10/1/2022",
          time: "01 : 15 PM",
        },
        {
          sort: 4,
          order_number: 33,
          client: "Mahmoud Siliman",
          quantity: 3,
          date: "5/1/2022",
          time: "01 : 15 PM",
        },
        {
          sort: 5,
          order_number: 12,
          client: "Mahmoud Siliman",
          quantity: 5,
          date: "4/1/2022",
          time: "01 : 15 PM",
        },
        {
          sort: 6,
          order_number: 213,
          client: "Mahmoud Siliman",
          quantity: 6,
          date: "1/1/2022",
          time: "01 : 15 PM",
        },
        {
          sort: 7,
          order_number: 123,
          client: "Mahmoud Siliman",
          quantity: 1,
          date: "27/12/2021",
          time: "01 : 15 PM",
        },
        {
          sort: 8,
          order_number: 31,
          client: "Mahmoud Siliman",
          quantity: 2,
          date: "29/12/2021",
          time: "01 : 15 PM",
        },
        {
          sort: 9,
          image: 331,
          client: "Mahmoud Siliman",
          quantity: 5,
          date: "20/12/2021",
          time: "01 : 15 PM",
        },
        {
          sort: 10,
          image: 13,
          client: "Mahmoud Siliman",
          quantity: 8,
          date: "15/12/2021",
          time: "01 : 15 PM",
        },
      ],
      // END:: ORDER TABLE DATA
    };
  },
  methods: {
    async getImages() {
      try {
        const response = await axios.get("image", {
          params: {
            provider_id: this.$route.params.id,
          },
        });
        this.$store.dispatch("branch/branchImages", response);
      } catch (error) {
        console.log(error.response);
      }
    },
    // START:: TOGGLE SUCCESS MODAL METHOD
    toggleAddImageModal() {
      this.addImageModalIsOpen = !this.addImageModalIsOpen;
    },
    // END:: TOGGLE SUCCESS MODAL METHOD
    showItem(item) {
      console.log(item);
    },
    editItem(item) {
      console.log(item);
    },
    selectDeleteItem(item) {
      console.log(item);
    },
  },
};
</script>
