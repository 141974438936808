import apisGetsMutations from "./mutations.js";
import * as actions from "./actions";
import apisGetsGetters from "./getters.js";

export default {
  namespaced: true,
  state: {
    // START:: LANGUAGES LIST DATA
    languages: [
      {
        id: 1,
        name: "Arabic",
      },
      {
        id: 2,
        name: "English",
      },
    ],
    // END:: LANGUAGES LIST DATA

    // START:: LANGUAGES LIST DATA
    countries: [
      {
        id: 1,
        name: "Country 1",
      },
      {
        id: 2,
        name: "Country 2",
      },
    ],
    // END:: LANGUAGES LIST DATA

    // START:: TIME ZONES LIST DATA
    time_zones: [
      {
        id: 1,
        name: "Time Zone 1",
      },
      {
        id: 2,
        name: "Time Zone 2",
      },
    ],
    // END:: TIME ZONES LIST DATA

    // START:: TIME ZONES LIST DATA
    roles: [
      {
        id: 1,
        name: "Role 1",
      },
      {
        id: 2,
        name: "Role 2",
      },
    ],
    // END:: TIME ZONES LIST DATA

    // START:: CITY LIST DATA
    cities: [
      {
        id: 1,
        name: "City 1",
      },
      {
        id: 2,
        name: "City 2",
      },
    ],
    // END:: CITY LIST DATA

    // START:: REGIONS LIST DATA
    region: [
      {
        id: 1,
        name: "Region 1",
      },
      {
        id: 2,
        name: "Region 2",
      },
    ],
    // END:: REGIONS LIST DATA

    // START:: CATEGORIES LIST DATA
    categories: [
      {
        id: 1,
        name: "Category 1",
      },
      {
        id: 2,
        name: "Category 2",
      },
    ],
    // END:: CATEGORIES LIST DATA

    // START:: CATEGORIES LIST DATA
    dressCodes: [
      {
        id: 1,
        name: "Dress Code 1",
      },
      {
        id: 2,
        name: "Dress Code 2",
      },
    ],
    // END:: CATEGORIES LIST DATA

    // START:: CURRENCIES LIST DATA
    currencies: [
      {
        id: 1,
        name: "Currency 1",
      },
      {
        id: 2,
        name: "Currency 2",
      },
    ],
    // END:: CURRENCIES LIST DATA

    // START:: PRICES LIST DATA
    prices: [
      {
        id: 1,
        name: "Price 1",
      },
      {
        id: 2,
        name: "Price 2",
      },
    ],
    // END:: PRICES LIST DATA

    // START:: PAYMENT METHODS LIST DATA
    paymentMethods: [
      {
        id: 1,
        name: "Visa",
      },
      {
        id: 2,
        name: "Amex",
      },
      {
        id: 3,
        name: "MasterCard",
      },
      {
        id: 4,
        name: "Discover",
      },
      {
        id: 5,
        name: "PayPal",
      },
      {
        id: 6,
        name: "UnionPay",
      },
      {
        id: 7,
        name: "JCB",
      },
      {
        id: 8,
        name: "Cash",
      },
    ],
    // END:: PAYMENT METHODS LIST DATA

    // START:: MEAL TYPES LIST DATA
    mealTypes: [
      {
        id: 1,
        name: "Breakfast",
      },
      {
        id: 2,
        name: "Brunch",
      },
      {
        id: 3,
        name: "Lunch",
      },
      {
        id: 4,
        name: "Dinner",
      },
      {
        id: 5,
        name: "Late",
      },
      {
        id: 6,
        name: "Other",
      },
    ],
    // END:: MEAL TYPES LIST DATA

    // START:: CUISINES LIST DATA
    cuisines: [
      {
        id: 1,
        name: "Cuisine 1",
      },
      {
        id: 2,
        name: "Cuisine 2",
      },
    ],
    // END:: CUISINES LIST DATA

    // START:: ADDITIONAL CUISINES LIST DATA
    additionalCuisines: [
      {
        id: 1,
        name: "Additional Cuisine 1",
      },
      {
        id: 2,
        name: "Additional Cuisine 2",
      },
      {
        id: 3,
        name: "Additional Cuisine 3",
      },
      {
        id: 4,
        name: "Additional Cuisine 4",
      },
      {
        id: 5,
        name: "Additional Cuisine 5",
      },
      {
        id: 6,
        name: "Additional Cuisine 6",
      },
    ],
    // END:: ADDITIONAL CUISINES LIST DATA

    // START:: FEATURES LIST DATA
    features: [
      {
        id: 1,
        name: "Feature 1",
      },
      {
        id: 2,
        name: "Feature 2",
      },
      {
        id: 3,
        name: "Feature 3",
      },
      {
        id: 4,
        name: "Feature 4",
      },
      {
        id: 5,
        name: "Feature 5",
      },
      {
        id: 6,
        name: "Feature 6",
      },
    ],
    // END:: FEATURES LIST DATA

    // START:: OCCASION LIST DATA
    occasions: [
      {
        id: 1,
        name: "Occasion 1",
      },
      {
        id: 2,
        name: "Occasion 2",
      },
      {
        id: 3,
        name: "Occasion 3",
      },
      {
        id: 4,
        name: "Occasion 4",
      },
      {
        id: 5,
        name: "Occasion 5",
      },
      {
        id: 6,
        name: "Occasion 6",
      },
    ],
    // END:: OCCASION LIST DATA

    // START:: PARKING OPTIONS LIST DATA
    parkingOptions: [
      {
        id: 1,
        name: "Parking Option 1",
      },
      {
        id: 2,
        name: "Parking Option 2",
      },
      {
        id: 3,
        name: "Parking Option 3",
      },
      {
        id: 4,
        name: "Parking Option 4",
      },
      {
        id: 5,
        name: "Parking Option 5",
      },
      {
        id: 6,
        name: "Parking Option 6",
      },
    ],
    // END:: PARKING OPTIONS LIST DATA

    // START:: ADVANCES DAYS LIST DATA
    advancesDays: [
      {
        id: 1,
        name: "Same Day Only",
      },
      {
        id: 2,
        name: "7 Days (Default)",
      },
      {
        id: 3,
        name: "14 Days",
      },
      {
        id: 4,
        name: "30 Days",
      },
      {
        id: 5,
        name: "45 Days",
      },
      {
        id: 6,
        name: "365 Days",
      },
    ],
    // END:: ADVANCES DAYS LIST DATA

    // START:: ADVANCES TIMES LIST DATA
    advancesTimes: [
      {
        id: 1,
        name: "20 Minutes (Default)",
      },
      {
        id: 2,
        name: "45 Minutes",
      },
      {
        id: 3,
        name: "1 Hours",
      },
      {
        id: 4,
        name: "2 Hours",
      },
    ],
    // END:: ADVANCES TIMES LIST DATA

    // START:: MINIMUM GUESTS SIZE LIST DATA
    minimumGuests: [
      {
        id: 1,
        name: "1",
      },
      {
        id: 2,
        name: "2",
      },
      {
        id: 3,
        name: "3",
      },
      {
        id: 4,
        name: "4",
      },
      {
        id: 5,
        name: "5",
      },
    ],
    // END:: MINIMUM GUESTS SIZE LIST DATA

    // START:: MAXIMUM GUESTS SIZE LIST DATA
    maximumGuests: [
      {
        id: 1,
        name: "10",
      },
      {
        id: 2,
        name: "11",
      },
      {
        id: 3,
        name: "12",
      },
      {
        id: 4,
        name: "13",
      },
      {
        id: 5,
        name: "14",
      },
      {
        id: 6,
        name: "15",
      },
    ],
    // END:: MAXIMUM GUESTS SIZE LIST DATA

    // START:: DASHBOARD VIEW LIST DATA
    dashboardViews: [
      {
        id: 1,
        name: "Reservation Book Mode (Default)",
      },
      {
        id: 2,
        name: "Table Status Mode",
      },
    ],
    // END:: DASHBOARD VIEW LIST DATA

    // START:: MAIN PREFERENCES LIST DATA
    mainPreferences: [
      {
        id: 1,
        icon: "preference 1",
      },
      {
        id: 2,
        icon: "preference 2",
      },
      {
        id: 3,
        icon: "preference 3",
      },
      {
        id: 4,
        icon: "preference 4",
      },
      {
        id: 5,
        icon: "preference 5",
      },
      {
        id: 6,
        icon: "preference 6",
      },
      {
        id: 7,
        icon: "preference 7",
      },
      {
        id: 8,
        icon: "preference 8",
      },
    ],
    // START:: MAIN PREFERENCES LIST DATA

    // START:: PREFERENCES LIST DATA
    preferences: [
      {
        id: 1,
        name: "preference 1",
      },
      {
        id: 2,
        name: "preference 2",
      },
      {
        id: 3,
        name: "preference 3",
      },
      {
        id: 4,
        name: "preference 4",
      },
      {
        id: 5,
        name: "preference 5",
      },
      {
        id: 6,
        name: "preference 6",
      },
      {
        id: 7,
        name: "preference 7",
      },
      {
        id: 8,
        name: "preference 8",
      },
    ],
    // START:: PREFERENCES LIST DATA
  },
  mutations: apisGetsMutations,
  actions: {
    ...actions,
  },
  getters: apisGetsGetters,
};
