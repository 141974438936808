<template>
  <div class="features_occasion_form_wrapper">
    <!-- START:: TITLE -->
    <div class="title_wrapper mb-3">
      <h3>{{ $t("titles.features_and_occasion") }}</h3>
    </div>
    <!-- END:: TITLE -->

    <!-- START:: FORM -->
    <div class="form_wrapper">
      <form @submit.prevent="validateFeaturesAndOccasionForm">
        <!-- START:: TITLE -->
        <div class="title_wrapper py-5">
          <h5>{{ $t("titles.features") }}</h5>
        </div>
        <!-- END:: TITLE -->

        <!-- START:: CHECK BOXES WRAPPER -->
        <div class="row my-5">
          <!-- START:: CHECKBOX INPUT GROUP -->
          <div
            class="col-6 col-lg-4 my-3"
            v-for="feature in featuresList"
            :key="feature.id"
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :value="feature.id"
                :id="'feature' + feature.id"
                v-model="featuresAndOccasionFormData.features"
              />
              <label class="form-check-label" :for="'feature' + feature.id">
                {{ feature.name }}
              </label>
            </div>
          </div>
          <!-- END:: CHECKBOX INPUT GROUP -->
        </div>
        <!-- END:: CHECK BOXES WRAPPER -->

        <!-- START:: TITLE -->
        <div class="title_wrapper py-5">
          <h5>{{ $t("titles.occasions") }}</h5>
        </div>
        <!-- END:: TITLE -->

        <!-- START:: CHECK BOXES WRAPPER -->
        <div class="row my-5">
          <!-- START:: CHECKBOX INPUT GROUP -->
          <div
            class="col-6 col-lg-4 my-3"
            v-for="occasion in occasionsList"
            :key="occasion.id"
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :value="occasion.id"
                :id="'occasion' + occasion.id"
                v-model="featuresAndOccasionFormData.occasions"
              />
              <label class="form-check-label" :for="'occasion' + occasion.id">
                {{ occasion.name }}
              </label>
            </div>
          </div>
          <!-- END:: CHECKBOX INPUT GROUP -->
        </div>
        <!-- END:: CHECK BOXES WRAPPER -->

        <!-- START:: TITLE -->
        <div class="title_wrapper py-5">
          <h5>{{ $t("titles.parking_options") }}</h5>
        </div>
        <!-- END:: TITLE -->

        <!-- START:: CHECK BOXES WRAPPER -->
        <div class="row my-5">
          <!-- START:: CHECKBOX INPUT GROUP -->
          <div
            class="col-6 col-lg-4 my-3"
            v-for="parkingOption in parkingOptionsList"
            :key="parkingOption.id"
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :value="parkingOption.id"
                :id="'parkingOption' + parkingOption.id"
                v-model="featuresAndOccasionFormData.parkingOptions"
              />
              <label
                class="form-check-label"
                :for="'parkingOption' + parkingOption.id"
              >
                {{ parkingOption.name }}
              </label>
            </div>
          </div>
          <!-- END:: CHECKBOX INPUT GROUP -->
        </div>
        <!-- END:: CHECK BOXES WRAPPER -->

        <!-- START:: SUBMIT BTN WRAPPER -->
        <div class="btn_wrapper">
          <button class="main_btn">
            {{ $t("buttons.edit") }}
            <span class="btn_loader" v-if="isWaitingRequest"></span>
          </button>
        </div>
        <!-- END:: SUBMIT BTN WRAPPER -->
      </form>
    </div>
    <!-- END:: FORM -->
  </div>
</template>

<script>
// START:: IMPORT VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORT VUEX HELPERS

export default {
  name: "UpdateFeaturesAndOccasionsForm",

  data() {
    return {
      isWaitingRequest: false,

      featuresAndOccasionFormData: {
        features: [1, 2, 5],
        occasions: [1, 5, 6],
        parkingOptions: [1, 2, 5, 3],
      },
    };
  },

  computed: {
    ...mapGetters("apisGetsModule", [
      "featuresList",
      "occasionsList",
      "parkingOptionsList",
    ]),
  },

  methods: {
    // START:: VALIDATE SECOND STEP FORM
    validateFeaturesAndOccasionForm() {
      this.isWaitingRequest = true;

      if (this.featuresAndOccasionFormData.features.length == 0) {
        this.$message.error(this.$t("validation.features_validation"));
        this.isWaitingRequest = false;
        return;
      } else if (this.featuresAndOccasionFormData.occasions.length == 0) {
        this.$message.error(this.$t("validation.occasions_validation"));
        this.isWaitingRequest = false;
        return;
      } else if (this.featuresAndOccasionFormData.parkingOptions.length == 0) {
        this.$message.error(this.$t("validation.parking_options_validation"));
        this.isWaitingRequest = false;
        return;
      } else {
        this.submitFeaturesAndOccasionForm();
      }
    },
    // END:: VALIDATE SECOND STEP FORM

    // START:: SUBMIT SECOND STEP FORM
    submitFeaturesAndOccasionForm() {
      setTimeout(() => {
        this.isWaitingRequest = false;
      }, 2000);
    },
    // END:: SUBMIT SECOND STEP FORM
  },
};
</script>
