<template>
  <div class="data_table_wrapper">
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          :label="$t('placeholders.search')"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :search="search"
        :headers="headers"
        :items="items"
        :itemsPerPage="5"
        :footerProps="{
          showFirstLastPage: false,
          prevIcon: 'fas fa-chevron-left',
          nextIcon: 'fas fa-chevron-right',
          itemsPerPageOptions: [5, 10, 20, 30, -1],
          itemsPerPageText: $t('dataTable.footer.items_per_page_text'),
          itemsPerPageAllText: $t('dataTable.footer.items_per_page_all_text'),
        }"
      >
        <!--  eslint-disable-next-line  -->
          <template v-slot:item.image="{ item }">
          <img :src="item.image" />
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions">
            <button
              class="btn_show btn btn-info"
              style="margin: 5px"
              @click="showItem(item)"
            >
              <i class="fa fa-eye" style="color: white"></i>
            </button>

            <button
              class="btn_edit btn btn-primary"
              style="margin: 5px"
              @click="editItem(item)"
            >
              <i class="fa fa-edit" style="color: white"></i>
            </button>

            <button
              class="btn_delete btn btn-danger"
              style="margin: 5px"
              @click="selectDeleteItem(item)"
            >
              <i class="fa fa-trash-alt" style="color: white"></i>
            </button>
          </div>
        </template>

        <template v-slot:[`footer.page-text`]="items">
          {{ items.pageStart }} - {{ items.pageStop }}
          <span class="mx-2">{{ $t("dataTable.footer.of") }}</span>
          {{ items.itemsLength }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "TheDataTable",

  props: [
    "search",
    "headers",
    "items",
    "showItem",
    "editItem",
    "selectDeleteItem",
  ],
};
</script>
